import { Component, computed, inject, Input, signal, WritableSignal } from "@angular/core";
import { AppActionItem, AppActionsStore, AppLocation } from "../../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../../lib/service/app-state/app-state";

@Component({
  selector: "top-card-actions",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class TopCardActionsComponent {

  appState = inject(AppState) as AppState;

  appActions = inject(AppActionsStore);

  @Input("location") location: AppLocation = AppLocation.CARD_ACTION;

  get action$()  {
    if(this.location == AppLocation.CARD_ACTION) {
      return this.appActions.cardActions as WritableSignal<AppActionItem[]>;
    } else if(this.location == AppLocation.APPBAR)  {
      return this.appActions.appBarActions as WritableSignal<AppActionItem[]>;
    }
    return signal([] as AppActionItem[])  as WritableSignal<AppActionItem[]>;
  }

  buttons$ = computed( () =>
    this.appState.appHeader$().buttons?.() || []
  )

  menuItems$ = computed( () =>
    this.appState.appHeader$().menuItems?.() || []
  )

  onClick(buttonId: string) {
    this.appState.setAction(buttonId);
  }

  toggleSearch() {
    this.appState.setSearchVisible(!this.appState.state.appHeader().searchExpanded)
  }
}
