@if (source.loadingStatus$() === SourceStates.LOADING) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
  <div class="progressbar-spacer"></div>
}

<mat-card>

  <ng-container
    sourceFilterRoot [filterId]="'user-list-filter'" [source]="source"
  >
    @if (!appState.isMobile$()) {
      <div class="card-header">
        <mat-card-header class="card-title-block">
          <mat-card-title>
            Gebruikers
          </mat-card-title>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <div class="card-actions-top-block">
          <div>
            <mat-search-input id="search" [source]="source"></mat-search-input>
          </div>
        </div>

        <div class="list-actions">
          <button mat-icon-button (click)="onToggleFilters()">
            <mat-icon>
              @if (showFilters()) {
                filter_list_off
              } @else {
                filter_list
              }
            </mat-icon>
          </button>
        </div>
      </div>
    }

    <mat-card-content>

      @if (showFilters()) {
        <div class="list-filters">
          <form [formGroup]="form">

            @for( filter of (visibleFilters$ | async); track filter) {
              @if(filter.id === "companyId") {
                <autocomplete
                    [source]="companySource"
                              [filterField]="'companyId'"
                              [placeholder]="'Bedrijf'"

                ></autocomplete>
              }
            }
          </form>
        </div>
      }

      @if (!appState.isMobile$()) {
        <mat-table
        mat-table
        class="user-list"
        [source]="source"
        matSort
        matSortActive="surname"
        matSortDirection="asc"
        matSortDisableClear
      >

        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let model">
            <span class="align-middle">{{ model.id }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="surname">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let model">{{ model.surname }}</td>

        </ng-container>

        <ng-container matColumnDef="infix">
          <th mat-header-cell *matHeaderCellDef>Infix</th>
          <td mat-cell *matCellDef="let model">{{ model.infix }}</td>

        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>firstName</th>
          <td mat-cell *matCellDef="let model">{{ model.firstName }}</td>

        </ng-container>

        <ng-container matColumnDef="company.name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let model">
            <span>
            {{ model.company?.name ?? model.companyName}}
            </span>
            @if(model.company == null && model.companyName != null) {
              <span>
                (!)
              </span>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="city.name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>City</th>
          <td mat-cell *matCellDef="let model">{{ model.company?.city?.name }}</td>

        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Actief</th>
          <td mat-cell *matCellDef="let model">
            @if (model.active) {
              <mat-icon class="material-symbols-outlined">check_box</mat-icon>
            } @else {
              <mat-icon>check_box_outline_blank</mat-icon>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="authRole.name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Rol</th>
          <td mat-cell *matCellDef="let model">{{ model.authRole?.name }}</td>

        </ng-container>

        <!--	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columns;"
          [ngClass]="{ 'placeholder': row.rev < 0}"
          (click)="onClick(row.id)"
        ></tr>

      </mat-table>
      } @else {
          <mat-list>
            @for (model of (source.dataSourceAsArray$ | async); track model; let index = $index) {
              <mat-list-item (click)="onClick(model.id)">
                <span matListItemTitle>{{ model?.surnameFirst }}</span>
                <span matListItemLine>{{ model?.company?.name }}</span>
              </mat-list-item>
            }
          </mat-list>
        }

      <mat-paginator
        [dataSource]="source"
        [pageSizeOptions]="[5, 15, 10, 20, 50]"
        [length]="source.count$()"
        showFirstLastButtons
      ></mat-paginator>

    </mat-card-content>

    <mat-card-actions>

      <div class="left"></div>

      <div class="right">
        <button
          hasPermission [permission]="USER_CREATE_PERMISSION"
          mat-flat-button color="primary" (click)="onClick(0)"
        >Nieuw
        </button>
      </div>

    </mat-card-actions>
  </ng-container>
</mat-card>
