import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData
} from "../../../../lib/store/model/dataModel";

export type RegionStore = DataModelMap<RegionModel>;

export const initialRegionStore: RegionStore = new Map<DataModelId, RegionModel>();

export interface RegionData extends ModelData {
  name: string;
}

export interface RegionModel extends RegionData, DataModel {
}

export class Region extends BaseModel implements RegionModel {

  readonly name: string;

  constructor(data: RegionData) {
    super(data.id, data.rev);
    this.name = data.name;
  }

  static placeholder(): Region {
    return new Region({
      id: 0,
      rev: -1,
      name: ""
    });
  }


  /**
   * Clones a region and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): Region {
    data = data ? data : {};

    return new Region({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      name: Object.hasOwn(data, "name") ? data.name : this.name, //
    } as RegionModel);
  }

  get displayName(): string {
    return this.name ? this.name : "";
  };
}
