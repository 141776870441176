import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { City, CityModel, initialCityStore } from "./model";

export const CITY_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CityReducer");

export function cityReducerProvider() {
	return reducerFactory<CityModel>("city", initialCityStore, (data: CityModel) => {
		return new City(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("city", CITY_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("city"),

	], providers: [
		{
			provide: CITY_REDUCER_TOKEN,
			useFactory: cityReducerProvider
		}
	]
})
export class CityCoreModule {

}
