import { Injectable } from "@angular/core";
import { patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { DataModelId } from "../../store/model/dataModel";
import { Sort } from "../source";

const initialSourcePageState = {
  sources: [] as SourcePageState[]
}

export type SourcePageState = {
  name: string;
  offset: number,
  limit: number,
  pageNo: number;
  pageSize: number;
  search: string | undefined;
  sort: Sort[];
  filter: object | null;
  expandedRows: Set<DataModelId> | null
};

export const SourcePageStateStore = signalStore(
  { providedIn: 'root' },
  withState(initialSourcePageState),
  withMethods((store) => ({
    getState(name: string, partialState?: Partial<SourcePageState>): SourcePageState {
      let item = store.sources().find(item => item.name === name);

      console.log("STATES ITEMS: "+JSON.stringify(item, null, 4));

      // No previous state found, return default values
      if(item == null) {
        let limit = partialState?.limit ?? partialState?.pageSize;
        if(limit == null || limit === 0) {
            // No limit and no pageSize given, default to 20
            limit = 20;
        }

        return {
          ...{
            name: name,
            offset: 0,
            limit: limit,
            pageNo: 0,
            pageSize: limit,
            search: undefined,
            sort: [],
            filter: null,
            expandedRows: null
          },
          ...partialState
        } as SourcePageState;
      }
      return item;
    },
    setState(name: string, newState: SourcePageState): void {
      // Remove the old state entry if it exists
      patchState(store, (state) => {
        const states = state.sources;
        const index = states.findIndex( item => item.name === newState.name );

        if(index >= 0) {
          const currentState = states[index];
          states[index] = {
            ...currentState,
            ...newState
          };

        } else {
          newState.name = name;
          states.push(newState);
        }

        const list = [...states];
        console.log("STATES PATCH: "+JSON.stringify(list, null, 4));
        return { sources: [...states] };
      });
      // 👇 Updating state using the `patchState` function.
    },
    resetState(name: string): void {
      // Remove the old state entry if it exists
      patchState(store, (state) => {
        const states = state.sources;
        const index = states.findIndex( item => item.name === name );

        if(index >= 0) {
          const currentState = states[index];
          states[index] = {
            ...currentState,
            ...{
              name: name,
              offset: 0,
              limit: 20,
              pageNo: 0,
              pageSize: 20,
              search: undefined,
              sort: [],
              filter: null,
              filterSettings: [],
              displayFilters: false,
              expandedRows: null
            }
          };
        }

        const list = [...states];
        console.log("STATES RESET: "+JSON.stringify(list, null, 4));
        return { sources: [...states] };
      });
      // 👇 Updating state using the `patchState` function.
    }
  }))
);


@Injectable({
  providedIn: 'root',
})
export class SourcePageStateService {

}
