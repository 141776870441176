import { Directive, input, OnDestroy, OnInit } from "@angular/core";
import { SourceFilterComponentState, SourceFilterState } from "../../source-filter/source-filter-state";
import { DataModelId } from "../../store/model/dataModel";
import { Source } from "../source";
import { SourcePageState } from "./source-page-state-store";

export type SourceExpandedState = SourcePageState & {
  expandedRows: Set<DataModelId> | null;
}

@Directive({
  selector: 'mat-table[expandedRows]',
  standalone: true
})
export class ExpandedRowsDirective implements OnInit, OnDestroy {

  source = input<Source<any>>();
  private expandedIds : Set<DataModelId> | null = null;

  isExpanded(id: DataModelId): boolean {
    return this.expandedIds?.has(id) ?? false;
  }

  toggle(id: DataModelId) {
    if(this.expandedIds == null) {
      this.expandedIds = new Set<DataModelId>();
    }
    if(this.expandedIds.has(id)) {
      this.expandedIds.delete(id);
      return;
    }
    this.expandedIds.add(id);
  }

  ngOnInit() {
    const state = this.source()?.getState();
    this.expandedIds = (state as SourceExpandedState).expandedRows ?? new Set<DataModelId>();
  }

  ngOnDestroy() {
    this.source()?.setState({
      expandedRows: this.expandedIds,
    } as SourceFilterState);
  }
}
