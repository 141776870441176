import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef,
  inject,
  Input,
  model,
  OnInit,
  signal,
  ViewChild
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { RouterModule } from "@angular/router";
import { PropeldFormDisplayModule } from "../../../lib/component/form-display/module";
import { PropeldAutocompleteInputModule } from "../../../lib/component/form-select/module";
import { PropeldFormTextAreaInputModule } from "../../../lib/component/form-textarea-input/module";
import { PropeldSearchInputModule } from "../../../lib/component/mat-search-input/module";
import { PropeldComponentModule } from "../../../lib/component/module";
import { CrudAction } from "../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../lib/core/event-bus/event-bus";
import { PropeldMatTableSortModule } from "../../../lib/directive/mat-table-source/module";
import { PropeldResizeDirectiveModule } from "../../../lib/directive/resize-observable/module";
import { SnackbarService } from "../../../lib/service/snackbar/service/snackbar.service";
import { AccountService } from "../../service/account-service/account-service";

type PageDisplayType = 'info'|'form'|'submitting'|'form-filled';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "candidate-contact-form",
  templateUrl: "./component.html",
  styleUrls: [
    "component.scss"
  ],
  standalone: true,
  imports: [
    CommonModule,
    // BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,

    PropeldComponentModule,
    PropeldSearchInputModule,
    PropeldMatTableSortModule,
    PropeldFormDisplayModule,

    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    PropeldAutocompleteInputModule,
    PropeldFormTextAreaInputModule,
    PropeldResizeDirectiveModule
  ]
})
export class CandidateContactForm implements OnInit, AfterViewInit  {

  private snackbar = inject(SnackbarService);
  private eventBus = inject(EventBus);
  model$ = signal<any>({});
  accountService = inject(AccountService);

  pageDisplayType = model<PageDisplayType>( 'info');
  additionalDataDisclosed = model<boolean>( false);

  // pageDisplayType

  sourceControl = new FormControl("");
  uuidControl = new FormControl("");
  tokenControl = new FormControl("");

  form = new FormGroup({
    source: this.sourceControl,
    uuid: this.uuidControl,
    token: this.tokenControl,
    name: new FormControl(""),
    phone: new FormControl(""),
    email: new FormControl(""),
    remark: new FormControl("")
  });


  @Input() set token(token: string) {
    this.tokenControl.patchValue(token);
    this.tokenControl.markAsDirty();
  }

  @Input() set source(token: string) {
    this.sourceControl.patchValue(token);
    this.sourceControl.markAsDirty();
  }

  @Input() set uuid(uuid: string) {
    this.uuidControl.patchValue(uuid);
    this.uuidControl.markAsDirty();
  }

  @Input() set isFormOpen(v: PageDisplayType) {
    this.pageDisplayType.set(v);
  }

  @ViewChild('inp', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if(element) {
      element.nativeElement.focus()
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const account = this.accountService.get();
    if(account.authRoleId > 10) {
      this.patch("name", account.displayName);
      this.patch("phone", account.phone);
      this.patch("email", account.email);
    }
  }

  patch(field: string, value: any) {
    const ctrl = this.form.get(field);
    if(ctrl == null) return;
    ctrl.patchValue(value);
    ctrl.markAsDirty();
  }


  togglePageDisplayType(type: PageDisplayType) {
    this.pageDisplayType.set(type);
  }

  onOk() {
    if (!this.form.dirty) {
      this.onCancel();
      return;
    }

    this.togglePageDisplayType('submitting');
    const changes = this.getDirtyValues(this.form);

    let type = "candidateContact/create";
    const action = new CrudAction(type, -1, 1, changes);
    this.eventBus.request(action.type, action).subscribe({
      next: (v) => {
        this.togglePageDisplayType('form-filled');
        this.snackbar.info("Dank, we nemen zo snel mogelijk contact op.");
        window.scrollTo(0, 0);
      },
      error: (error) => {
        this.snackbar.error("Er is iets mis gegaan. Probeer het opnieuw");
      }
    });
  }

  onCancel() {
    this.togglePageDisplayType('info');
    window.scrollTo(0, 0);
  }

  close() {

  }

  getDirtyValues(form: any) {
    let dirtyValues = {} as any;
    Object.keys(form.controls)
      .forEach(key => {
        let currentControl = form.controls[key];
        if (currentControl.dirty) {
          if (currentControl.controls) {
            dirtyValues[key] = this.getDirtyValues(currentControl);
          } else {
            dirtyValues[key] = currentControl.value;
          }
        }
      });

    return dirtyValues;
  }
}
