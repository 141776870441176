import { forwardRef, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Operator } from "./operator";


export type FilterSetting = any | null;

@Injectable() export class SourceFilterContainer {}
@Injectable() export class SourceFilterComponent implements SourceFilter {

  get id(): string {
    return "xxx";
  }

  get filterChanges(): Observable<any> {
    return of(null);
  }

  get filterSetting(): FilterSetting {
    return null;
  }

  set filterSetting(setting: FilterSetting)  {
  }
}

export const SourceFilterComponentProvider = <T extends SourceFilter>(component: new () => T) => {
  return {provide: SourceFilterComponent, useExisting: forwardRef(() => component)};
};


export interface SourceFilter {

  id: string;

  filterChanges: Observable<Operator>;

  filterSetting: FilterSetting;
}

