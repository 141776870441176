import { ACTION_TOKEN_COMPONENT_CONFIGS } from "../../../lib/token/model";

export function componentLoader() {
	return import("./component").then(mod => mod.ConfirmPasswordResetPage);
}

export const CONFIRM_PASSWORD_RESET_PROVIDER = {
	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
	multi: true,
	useValue: {
		tokenType: "user/reset-password",
		onSuccess: {
			navigateTo: ["/login"],
			toastMessage: "Wachtwoord bijgewerkt"
		},
		loader: componentLoader
	}
};
