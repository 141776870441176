import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, computed, effect, inject, Input, signal, ViewChild } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatSliderModule } from "@angular/material/slider";
import { RouterLink, RouterModule } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { PropeldChipsInputModule } from "../../../../lib/component/chips-input/module";
import { PropeldFormDisplayModule } from "../../../../lib/component/form-display/module";
import { PropeldAutocompleteInputModule } from "../../../../lib/component/form-select/module";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { Source } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { SafePipe } from "../../../../lib/util/safe.pipe";
import { WrapCommaPipe } from "../../../../lib/util/wrap-on-comma";
import { Candidate } from "../../../core/model/candidate/model";
import {
  CandidateStatus,
  DiscStyleOptions,
  getCandidateAvailabilityName,
  getCandidateFunctionLevelName,
  getCandidateWorkLevelName
} from "../../../core/model/constants";
import { AccountService } from "../../../service/account-service/account-service";
import { CandidateContactForm } from "../../candidate-contact-form/component";

@Component({
  selector: "public-candidate-detail-component",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/styles.scss"],
  standalone: true,
  imports: [
    RouterLink,
    WrapCommaPipe,
    SafePipe,
    CandidateContactForm,
    CommonModule,
    ReactiveFormsModule,

    MatIconModule,
    MatButtonModule,
    MatSliderModule,
    RouterModule,
    MatDividerModule,
    PropeldChipsInputModule,
    PropeldFormDisplayModule,

    CandidateContactForm,
    WrapCommaPipe,
    SafePipe,
    PropeldAutocompleteInputModule
  ]
})
export class PublicCandidateDetailComponent implements AfterViewInit {

  cardId = "candidate/public-detail-component";

  // @Input("uuid") set uuid(uuid: string) {
  //   this.source.setRequiredFilter(new EqualOperator("uuid", uuid).build());
  // };

  displayMode = signal<string>("loading");
  model$ = signal<Candidate>(Candidate.placeholder());
  profilePictureUrl$ = signal<string>("");
  accountService = inject(AccountService);
  private sourceBuilder = inject(SourceBuilder);

  discStyle= new FormControl<string | null>(null, []);

  additionalDataExpanded = signal<Boolean>(false);

  additionalDataDisclosed = computed( () =>
    this.additionalDataExpanded() && this.model$().info != null && this.model$().info !== ""
  );

  scroll = effect( () => {
    if(this.additionalDataExpanded()) {
      document.getElementById("additionalDataEl")?.scrollIntoView({
        behavior: "smooth"
      });
    }
  });

  @Input() formSource: string = "X";
  @Input() token: string = "";

  @Input() set model(model:Candidate) {
    this.model$.set(model);
    if (!this.model$().isPlaceholder) {
      this.displayMode.set("display");
      this.profilePictureUrl$.set(environment.httpEndpoint + "/file/" + this.model$().ppId);

      const discStyle = this.model$().discStyle;
      if(discStyle != null) {
        this.discStyle.setValue(discStyle);
      }
    }
  }

  @ViewChild(CandidateContactForm) candidateContactForm!: CandidateContactForm;

  /**
   *  Housekeeping variables
   */
  appState = inject(AppState);
  isMobile = computed( () => this.appState.state.isMobile());

  discStyleSource: Source<any> = this.sourceBuilder
    .builder("*discStyle")
    .setName("discStyleSource")
    .setItems(DiscStyleOptions)
    .build();

  ngAfterViewInit() {
    document.querySelector("app-root")?.scrollIntoView({
      behavior: "smooth"
    });
  }

  setFormOpen() {
    if(!this.additionalDataExpanded()) {
      this.toggleAdditionalData();
      return;
    }
    this.candidateContactForm.pageDisplayType.set('form');
  }

  toggleAdditionalData() {
    this.additionalDataExpanded.set(!this.additionalDataExpanded());
  }

  protected readonly CandidateStatus = CandidateStatus;
  protected readonly getCandidateAvailabilityName = getCandidateAvailabilityName;
  protected readonly getCandidateWorkLevelName = getCandidateWorkLevelName;
  protected readonly getCandidateFunctionLevelName = getCandidateFunctionLevelName;
}
