import { NgModule } from "@angular/core";
import { SourceFilterRootDirective } from "./root-directive";
import { SourceFilterDirective } from "./source-filter-state";

@NgModule({
  declarations:[
    SourceFilterRootDirective, SourceFilterDirective
  ],
  exports: [
    SourceFilterRootDirective, SourceFilterDirective
  ]
})
export class SourceFilterModule {

}
