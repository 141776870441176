import { DataSource } from "@angular/cdk/collections";
import { Signal, WritableSignal } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { MachineEvent } from "../statemachine/statemachine";
import { Filter } from "./source-builder";
import { SourcePageState } from "./state/source-page-state-store";

export enum SourceLoadingStates { INIT = "INIT", READY = "READY", LOADING = "LOADING", IDLE = "IDLE", DESTROYED = "DESTROYED"}

export enum SortDirection {
	DEFAULT = "",
	ASC = "ASC",
	DESC = "DESC"
}
export interface Sort {
	field: string;
	direction: SortDirection;
}

export interface FilterActionBody {
	search: string;
	limit: number;
	offset: number;
	sort: Sort[];
	// filter: Operator;
	compound?: string[];
	params?: unknown;
}
export declare type FilterCleanableProperty = 'search' | 'limit' | 'offset' | 'sort' | 'operators' | 'params';

export type SourceData<T> = {
  count: number,
  entity: string,
  items: T[],
  progress: string[],
  related: Map<string, string>
}

export interface Source<T> extends DataSource<T> {

  get pageFilterState$(): Signal<SourcePageState>;

  getState(): SourcePageState;
  setState(filterState?: SourcePageState): this;

  get name(): string;
  setName(name?: string): this;

  get dataSource$(): Observable<SourceData<T>>;
  get dataSourceAsArray$(): Observable<T[]>;

  get singleModel$(): WritableSignal<T>;

  get loadingEvent$(): Signal<MachineEvent|undefined>;
  get loadingStatus$(): Signal<SourceLoadingStates>;
  // get status$(): Observable<SourceLoadingStates>;

  set paginator(paginator: MatPaginator);

	getSearch(): Readonly<string>;
	setSearch(query: string | null): this;

  getFilter(): any;
  setFilter(filter: any): this;

  setRequiredFilter(filter: any): this;

  getPageSize(): Readonly<number>;
  setPageSize(pageSize: number): this;

	get count(): Readonly<number>;
	get count$(): Signal<number>;

	getLimit(): Readonly<number>;
	setLimit(limit: number): this;

  getOffset(): Readonly<number>;
	setOffset(offset: number): this;

	getSort(): Readonly<Sort[]>;
	setSort(sort: Sort[]): this;

	addSort(sort: Sort): this;
	removeOperator(fieldName: string): this;
	adjustSort(fieldName: string, direction: SortDirection): this;
	// getOperators(): Readonly<Map<string, Operator>>;
	// setOperators(operators: Map<string, Operator>): this;
	// addOperator(identifier: string, operator: Operator): this;
	// removeOperator(identifier: string): this;
	setParams(params: unknown): this;
	getParams(): Readonly<unknown>;
	/**
	 * Method which relays the changes to the total count.
	 * This updates with every status update or refresh.
	 */
	countChanged(): Observable<number>;
	/**
	 * Method which relays the changes to the source itself.
	 * Any adjustments made to it trigger this as a notifier method.
	 */
	sourceUpdated(): Observable<void>;
	/**
	 * Method which relays the changes to the status of the source.
	 * This is where the loading state comes from.
	 */
	// statusChanged(): Observable<SourceStatus>;
	// /**
	//  * Method which relays the data changes, this is where the output comes from it contains the data in
	//  * the configured format.
	//  */
	// get dataSource$(): Observable<T>;
	refresh(filter?: Filter): void;
	clone(filterHandlerAddress?: string): Source<T>;
	// clean(propertiesToClean?: FilterCleanableProperties): this;

  getIds(ids: (string|number)[]): Observable<T[]>;
}
