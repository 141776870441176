import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$gte: <<value>>}
 * }
 */
export class GreaterThanOrEqualOperator implements Operator {
	constructor(public readonly  field: string, private value: any) {
	}

  definition(): EncodedOperator {
    return {
      o: "$gte", f: this.field, v: this.value
    }
  }


  build(): object {
		return {
      [this.field]: {"$gte": this.value}
    }
	}
}
