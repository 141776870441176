import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Action } from "../../core/event-bus/action";
import { EventBus } from "../../core/event-bus/event-bus";
import { MessageConsumer } from "../../core/event-bus/message-consumer";

@Injectable({
	providedIn: "root"
})
export class StoreCrudHandler {

	private consumers = new Map<string, MessageConsumer<any>>();

	private readonly eventBus: EventBus = inject(EventBus);
	private readonly store: Store<unknown> = inject(Store);
	private entity: string = "";

	public initDefaultHandlers(entity: string) {
		this.entity = entity;
		this.addCreatedHandler();
		this.addUpdatedHandler();
		this.addDeletedHandler();
    this.addReceivedHandler();
	}

	protected addCreatedHandler() {
		this.addToStoreHandler(`${this.entity}/created`);
	}

	protected addUpdatedHandler() {
		this.addToStoreHandler(`${this.entity}/updated`);
	}

	protected addDeletedHandler() {
		this.addToStoreHandler(`${this.entity}/deleted`);
	}

	protected addReceivedHandler() {
		this.addToStoreHandler(`${this.entity}/received`);
	}

	protected addToStoreHandler(address: string) {
		const consumer = this.eventBus.consumer(address);
		this.consumers.set(address, consumer);

		consumer.subscribe({
			next: (message) => {
				this.store.dispatch(message.body as Action<any>);
			}, error: (error) => {
				throw new Error(error);
			}
		});
	}

	public removeHandler(address: string) {
		const consumer = this.consumers.get(address);
		if (consumer) {
			consumer.unregister();
			this.consumers.delete(address);
		}
	}

	public removeHandlers(addresses?: string[]) {
		if(addresses) {
			addresses.forEach(address => this.removeHandler(address));
			return;
		}
		this.consumers.forEach( consumer => consumer.unregister() )
	}
}
