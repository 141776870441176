import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTableSourceDirective } from "./directive";

@NgModule({
	declarations: [
    MatTableSourceDirective
	],
	exports: [
    MatTableSourceDirective
	],

	imports: [
    CommonModule,
		MatTableModule,
    MatSortModule,
    ReactiveFormsModule
	]
})
export class PropeldMatTableSortModule {
}
