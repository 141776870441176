import { forwardRef, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Operator } from "./operator";
import { SourceFilterComponentState } from "./source-filter-state";



@Injectable() export class SourceFilterContainer {}

export interface SourceFilterState {
  filterComponentId: string;
  filterChanges: Observable<Operator>;
  getSourceFilterComponentState(): SourceFilterComponentState;
  setSourceFilterComponentState(state: SourceFilterComponentState): void;
}


@Injectable() export class SourceFilterStateComponent implements SourceFilterState {

  get filterComponentId(): string {
    throw Error("Override me");
  }

  get filterChanges(): Observable<any> {
    return of(null);
  }

  getSourceFilterComponentState(): SourceFilterComponentState {
    throw Error("Override me");
  }

  setSourceFilterComponentState(state: SourceFilterComponentState) {
    throw Error("Override me");
  }
}

export const SourceFilterStateComponentProvider = <T extends SourceFilterState>(component: new () => T) => {
  return {provide: SourceFilterStateComponent, useExisting: forwardRef(() => component)};
};



