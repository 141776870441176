import { Component, computed, effect, EventEmitter, inject, Input, Output, signal } from "@angular/core";
import { AppActionsStore, AppLocation } from "../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../lib/service/app-state/app-state";

@Component({
  selector: "app-header",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class AppHeaderComponent {

  appState = inject(AppState);

  appActions = inject(AppActionsStore);

  appHeader$ = this.appState.appHeader$;
  isMenuOpen$ = this.appState.isMenuOpen$;

  // @Output() opened = new EventEmitter<boolean>();
  @Output() back = new EventEmitter<boolean>();

  menuItems$ = computed( () =>
    this.appHeader$().menuItems?.() || []
  )

  readonly appBarLocation: AppLocation = AppLocation.APPBAR.valueOf();

  constructor() {
    // Close the search in the bar if the source for searching is nullified
    // (ie the page has changed)
    // effect(() => {
    //   if(this.appState.searchSource$() == null) {
    //     this.searchVisible$.set(false);
    //   }
    // }, {allowSignalWrites: true});
  }

  onClick(buttonId: string) {
    this.appState.setAction(buttonId);
  }

  toggleMenu() {
    this.appState.setMenuOpen(!this.appState.isMenuOpen$());
  }

  closeMenu() {
    this.appState.setMenuOpen(false);
    // this.opened.emit(true);
  }

  navigateBack() {
    this.back.emit(true);
  }

  toggleSearch() {
    this.appState.setSearchVisible(!this.appState.state.appHeader().searchExpanded)
  }
}
