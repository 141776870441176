import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { GUEST_USER_ID } from "../../core/model/account/model";
import { AuthRole } from "../../core/model/authRole/model";

export const authorizationGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);
  const stateSnapshot = state;

  return store.select(state => state["account"])
    .pipe(// first(),
      map(state => {
        if (state == null) return null;
        const [account] = state.values();
        return account;
      }),
      filter(account => account != null && account.rev !== -1),
      tap(account => {

        const canActivate = account.authRoleId >= 20;
        let path = stateSnapshot.url;
        if (!canActivate) {
          if (path == null || path == "" || path == "/") {
            router.navigate(["/login"]);
          } else {
            router.navigate(["/login", {url: path}]);
          }
          return;
        }

        // Redirect to /candidates when account is user
        if(account.authRoleId === 20) {
          if(path == null || !path.startsWith("/candidates")) {
            router.navigate(["/candidates"]);
          }
          return;
        }

        // Redirect to /dashboard when path is empty
        if (path == null || path == "" || path == "/") {
          router.navigate(["/dashboard"]);
          return;
        }
      }));
};
