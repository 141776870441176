import { BaseModel, DataModel, DataModelId, DataModelRevision } from "../../../../lib/store/model/dataModel";

export interface PlaceholderModel extends DataModel {
}

export class Placeholder extends BaseModel implements PlaceholderModel {

	constructor(id: DataModelId, rev: DataModelRevision) {
    super(id, rev);
	}

	/**
	 * Clones a placeholder and applies given properties in the data parameter
	 *
	 * @param data
	 */
	override clone(data?: any): Placeholder {
		return new Placeholder(
			this.id, //
			data.hasOwnProperty("rev") ? data.rev : this.rev //
		);
	}

  override get displayName(): string {
		return this.id ? this.id.toString() : "";
	};
}
