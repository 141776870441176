<h1 mat-dialog-title>Deel kandidaat</h1>
<form id="disclose-additional-data-form" [formGroup]="form" class="detail-form" (ngSubmit)="onConfirm()">
  <mat-dialog-content>

    <p>Je gaat extra informatie over deze kandidaat delen.</p>
    <p>Hoe wil je de geïnteresseerde persoon vertellen dat er meer informatie beschikbaar is?</p>

    <mat-form-field [appearance]="'outline'" >
      <mat-label>Informeer via</mat-label>
      <mat-select formControlName="communicationType">
        <mat-option value="email">Via email</mat-option>
        <mat-option value="phone">Via telefoon</mat-option>
        <mat-option value="other">Anders</mat-option>
      </mat-select>
    </mat-form-field>

    @switch (communicationTypeControl.value) {
      @case ("email") {

          <mat-form-field class="email-input" [appearance]="'outline'">
            <mat-label>Email*</mat-label>
            <input id="email" matInput formControlName="email">
          </mat-form-field>

          <mat-form-field class="firstName-input" [appearance]="'outline'">
            <mat-label>Persoonlijke boodschap (optioneel)</mat-label>
            <textarea id="personalMessage" matInput formControlName="personalMessage"></textarea>
          </mat-form-field>
      }

<!--          <mat-checkbox formControlName="sendMeACopy">Stuur mij een kopie</mat-checkbox>-->

      @case ("phone") {
        <p>Ter info: Na vrijgeven kan geïnteresseerde dezelfde gedeelde link gebruiken.</p>

        <mat-form-field class="phone-input" [appearance]="'outline'">
          <mat-label>Telefoonnummer*</mat-label>
          <input id="phone" matInput formControlName="phone">
        </mat-form-field>
      }
      @case ("other") {
        <p>Ter info: Na vrijgeven kan geïnteresseerde dezelfde gedeelde link gebruiken.</p>
      }
    }

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-flat-button (click)="onCancel()">Annuleer</button>
    <button mat-flat-button color="primary" type="submit">Ok</button>
  </mat-dialog-actions>
</form>
