import { HttpClient } from "@angular/common/http";
import { Inject, inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, of } from "rxjs";
import { first } from "rxjs/operators";
import { Action } from "../../event-bus/action";
import { CrudAction } from "../../event-bus/crud-action";
import { Message } from "../../event-bus/message/message";
import { AbstractEventBusBridge, EventBusBridgeOptions } from "../abstract-event-bus-bridge";

export const HTTP_EVENT_BUS_BRIDGE_OPTIONS =
	new InjectionToken<EventBusBridgeOptions>("HttpEventBusBridgeOptions");

export const HTTP_EVENT_BUS_BRIDGE_OPTIONS_PROVIDER = {
	provide: HTTP_EVENT_BUS_BRIDGE_OPTIONS,
	useValue: {
		allowedInbound: [],
		allowedOutbound: []
	}
};

export const HTTP_EVENT_BUS_BRIDGE_ENDPOINT =
	new InjectionToken<string>("HttpEventBusBridgeEndPoint");

export const HTTP_EVENT_BUS_BRIDGE_ENDPOINT_PROVIDER = {
	provide: HTTP_EVENT_BUS_BRIDGE_ENDPOINT,
	useValue:  "https://unknown.com"
};


class OutboundConsumer {
}

@Injectable({
	providedIn: "root"
})
export class HttpEventBusBridge extends AbstractEventBusBridge {

	protected httpClient = inject(HttpClient);
	private httpEndpoint = inject(HTTP_EVENT_BUS_BRIDGE_ENDPOINT);

	constructor(
		@Inject(HTTP_EVENT_BUS_BRIDGE_OPTIONS) options: EventBusBridgeOptions
	) {
		super();
		this.initAllowedInbound(options.allowedInbound);
		this.initAllowedOutbound(options.allowedOutbound);
		this.listenToInbound();
	}

	override inboundMessageObserver(): Observable<any> {
		return of();
	}

	send(message: Message<Action<any>>): void {
    let address = message.address;
    if(address.startsWith("/")) {
      address = address.substring(1);
    }
		this.httpClient.post<{}>(this.httpEndpoint + address,
			message.body.toJson(),
			{
				headers: message.headers,
				withCredentials: true
			}
		).pipe(
			first())
			.subscribe({
				next: (serverReply: any) => {
          let action: Action<any>;
          if(serverReply.id !== undefined) {
            action = new CrudAction(serverReply.type, serverReply.id, serverReply.rev, serverReply.data)
          } else {
            action = new Action(serverReply.type, serverReply.data)
          }
					message.reply(action);
				}, error: (error) => {
					message.fail(error.status, error.statusText);
				}
			});
	}
}
