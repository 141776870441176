import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData
} from "../../../../lib/store/model/dataModel";

export type EducationLevelStore = DataModelMap<EducationLevelModel>;

export const initialEducationLevelStore: EducationLevelStore = new Map<DataModelId, EducationLevelModel>();

export interface EducationLevelData extends ModelData {
  name: string;
  orderNo: number;
}

export interface EducationLevelModel extends EducationLevelData, DataModel {
  clone(data?: any): EducationLevel;
}

export class EducationLevel extends BaseModel implements EducationLevelModel {

  readonly name: string;
  readonly orderNo: number;

  constructor(data: EducationLevelData) {
    super(data.id, data.rev);
    this.name = data.name;
    this.orderNo = data.orderNo;
  }

  static placeholder(): EducationLevel {
    return new EducationLevel({
      id: 0,
      rev: -1,
      name: "",
      orderNo: 1
    });
  }


  /**
   * Clones a educationLevel and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): EducationLevel {
    data = data ? data : {};

    return new EducationLevel({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      name: Object.hasOwn(data, "name") ? data.name : this.name, //
      orderNo: Object.hasOwn(data, "orderNo") ? data.orderNo : this.orderNo //
    } as EducationLevelModel);
  }

  get displayName(): string {
    return this.name ? this.name : "";
  };
}
