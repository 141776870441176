import { Component, DestroyRef, forwardRef, inject, Input, OnInit, signal } from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { debounceTime, distinctUntilChanged, Observable, shareReplay, startWith } from "rxjs";
import { filter, map } from "rxjs/operators";
import { EqualOperator } from "../../source-filter/operator";
import {
  FilterSetting, SourceFilter, SourceFilterComponentProvider
} from "../../source-filter/source-filter";
import { SourceFilterStateComponent } from "../../source-filter/source-filter-component";
import { SourceFilterComponentState } from "../../source-filter/source-filter-state";
import { Source } from "../../source/source";
import { DataModel } from "../../store/model/dataModel";

@Component({
  selector: "autocomplete-input",
  templateUrl: "component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponentLib),
      multi: true
    },
    SourceFilterComponentProvider(AutocompleteInputComponentLib)
  ]
})
export class AutocompleteInputComponentLib implements OnInit, SourceFilter, ControlValueAccessor, SourceFilterStateComponent {

  private destroyedRef = inject(DestroyRef);

  @Input({required: true}) source: Source<any> | null = null;
  @Input() placeholder: string = "";
  @Input() className: string = "";
  @Input() displayOnly: boolean = false;
  @Input({required: true}) filterField: string = "";
  @Input() displayFn: ((dataModel: DataModel|null) => string) = (dataModel: DataModel|null) => {
    return (dataModel as any)?.displayName ?? "";
  }

  @Input() set displayField(displayField: string) {
    const v = displayField;
    this.displayFn = (dataModel: DataModel|null) => {
      if(dataModel == null) return "";
      return (dataModel as any)[v];
    }
  }

  control = new FormControl<DataModel|string|null>(null);
  model$ = signal<DataModel | null>(null);
  givenValues$ = signal<any>(null);
  selectedIcon = signal<string|undefined>(undefined);
  selectedId = signal<string|undefined>(undefined);

  private onChangeFn?: (_: any) => void = undefined;

  get id(): string {
    return this.source?.name ?? "form-select";
  }

  get filterSetting(): FilterSetting {
    return null;
  }

  set filterSetting(setting: FilterSetting)  {
  }

  constructor() {
    this.listenToGivenValues(toObservable(this.givenValues$));
  }

  onClear() {
    this.setSelectedValue(null);
  }

  ngOnInit() {
    this.listenToKeyboard();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.setSelectedValue(event.option.value);
  }

  setSelectedValue(value: DataModel | null, emitEvent: boolean = true) {
      this.control.setValue(value,{emitEvent: emitEvent});
      this.model$.set(value);

      const v = value as any;
      if(v?.icon != undefined) {
        this.selectedIcon.set(v.icon);
      } else {
        this.selectedIcon.set(undefined);
      }

      if(v?.class != undefined) {
        this.selectedId.set(v.id);
      } else {
        this.selectedId.set(undefined);
      }

      if(emitEvent) {
        this.onChangeFn?.(value?.id);
      }
  }

  /**
   * listens to given values and loads appropriate values from source.
   * This is an array with either numbers or strings.
   * @private
   */
  private listenToGivenValues(observable: Observable<number>) {
    observable.pipe(takeUntilDestroyed(this.destroyedRef),distinctUntilChanged()).subscribe({
      next: (value) => {
        if(value == null || value == 0) {
          this.setSelectedValue(null, false);
          return;
        }

        this.source!.getIds([value]).subscribe({
          next: (models) => {
            this.setSelectedValue(models[0], false);
          },
          error: (error) => {
            console.error(error.message);
          }
        });
      }
    });
  }

  listenToKeyboard() {
    if(this.source == null) return;
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef), debounceTime(300)).subscribe({
      next: (value) => {
        this.source?.setSearch(value as string);
      },
      error: (error) => {
        console.error(error.message);
      }
    })
  }

  get filterComponentId(): string {
    return [this.source?.name ?? "autocomplete", this.filterField].join("/");
  }
  getSourceFilterComponentState(): SourceFilterComponentState {
    return {
      name: this.filterComponentId,
      value: this.control.value
    }
  }
  setSourceFilterComponentState(state: SourceFilterComponentState): void {
    this.control.setValue(state.value);
  }

  get filterChanges(): Observable<any> {
    debugger;
    return this.control.valueChanges.pipe(
      startWith(this.control.value),
      takeUntilDestroyed(this.destroyedRef),
      debounceTime(300),
      filter(value => {
        return typeof value !== "string";
      }),
      map(value => {
        if(value == null ) return null;
        return new EqualOperator(this.filterField, (value as DataModel).id).build();
      }),
      shareReplay(1)
    );
  }

  // displayFn(dataModel: DataModel|string|null): string {
  //   if(dataModel == null) return ""; else
  //   if(typeof dataModel==='string') return dataModel;
  //   else return dataModel.displayName;
  // }

  /**
   * @description
   * Writes a new value to the element.
   *
   * This method is called by the forms API to write to the view when programmatic
   * changes from model to view are requested.
   *
   * @usageNotes
   * ### Write a value to the element
   *
   * The following example writes a value to the native DOM element.
   *
   * ```ts
   * writeValue(value: any): void {
   *   this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
   * }
   * ```
   *
   * @param obj The new value for the element
   */
  writeValue(obj: DataModel | null): void {
      this.givenValues$.set(obj);
  }

  /**
   * @description
   * Registers a callback function that is called when the control's value
   * changes in the UI.
   *
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   *
   * When implementing the `registerOnChange` method in your own value accessor,
   * save the given function so your class calls it at the appropriate time.
   *
   * @usageNotes
   * ### Store the change function
   *
   * The following example stores the provided function as an internal method.
   *
   * ```ts
   * registerOnChange(fn: (_: any) => void): void {
   *   this._onChange = fn;
   * }
   * ```
   *
   * When the value changes in the UI, call the registered
   * function to allow the forms API to update itself:
   *
   * ```ts
   * host: {
   *    '(change)': '_onChange($event.target.value)'
   * }
   * ```
   *
   * @param fn The callback function to register
   */
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  /**
   * @description
   * Registers a callback function that is called by the forms API on initialization
   * to update the form model on blur.
   *
   * When implementing `registerOnTouched` in your own value accessor, save the given
   * function so your class calls it when the control should be considered
   * blurred or "touched".
   *
   * @usageNotes
   * ### Store the callback function
   *
   * The following example stores the provided function as an internal method.
   *
   * ```ts
   * registerOnTouched(fn: any): void {
   *   this._onTouched = fn;
   * }
   * ```
   *
   * On blur (or equivalent), your class should call the registered function to allow
   * the forms API to update itself:
   *
   * ```ts
   * host: {
   *    '(blur)': '_onTouched()'
   * }
   * ```
   *
   * @param fn The callback function to register
   */
  registerOnTouched(fn: any): void {
    // debugger;
  }

  /**
   * @description
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   *
   * @usageNotes
   * The following is an example of writing the disabled property to a native DOM element:
   *
   * ```ts
   * setDisabledState(isDisabled: boolean): void {
   *   this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
   * }
   * ```
   *
   * @param isDisabled The disabled status to set on the element
   */
  setDisabledState?(isDisabled: boolean): void {
    // debugger;
  }
}
