import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { initialIndustryStore, Industry, IndustryModel } from "./model";

export const FUNCTION_AREA_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("IndustryReducer");

export function industryReducerProvider() {
	return reducerFactory<IndustryModel>("industry", initialIndustryStore, (data: IndustryModel) => {
		return new Industry(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("industry", FUNCTION_AREA_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("industry"),

	], providers: [
		{
			provide: FUNCTION_AREA_REDUCER_TOKEN,
			useFactory: industryReducerProvider
		}
	]
})
export class IndustryCoreModule {

}
