@if (displayMode() === 'loading') {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<mat-card class="mat-typography">

  <div class="card-header">
    <mat-card-header class="card-title-block">
      <mat-card-title>
        Bedrijf
        @if (displayMode() === 'loading') {
          ...
        } @else {
          {{ model$().displayName }}
        }

      </mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    @if(!appState.isMobile$()) {
      <div class="card-actions-top-block">
        <top-card-actions></top-card-actions>
      </div>
    }
  </div>

  <mat-card-content class="mat-body-1">

    <form id="form" [formGroup]="form" class="detail-form" (ngSubmit)="onOk()">

      <div style="display:none;">
        <input id="id" formControlName="id">
        <input id="rev" formControlName="rev">
      </div>

      <div class="form-row">
        <label class="label-column" for="name">
          <p class="body-1">Naam</p>
        </label>

        <!-- name -->
        <div class="input-column">
          <form-display-mode [displayMode]="displayMode()">
            <p class="display">{{ model$().displayName }}</p>

            <mat-form-field class="name-input">
              <mat-label>Bedrijfsnaam</mat-label>
              <input id="name" matInput formControlName="name">
            </mat-form-field>
          </form-display-mode>
        </div>
      </div>

      <div class="form-row">
        <label class="label-column" for="visitAddress">
          <p class="body-1">Adres</p>
        </label>

        <!-- name -->
        <div class="input-column">
          <form-display-mode [displayMode]="displayMode()">
            <div class="display">
              <div>
                {{ model$().v_street }}&nbsp;{{ model$().v_houseNumber }}
              </div>
              <div>
                {{ model$().v_zipCode }}&nbsp;&nbsp;{{ model$().v_city }}
              </div>

            </div>

            <address-input id="visitAddress" formControlName="visitAddress"></address-input>
          </form-display-mode>
        </div>
      </div>

    </form>
  </mat-card-content>

  <mat-card-actions>

    <div class="left">
<!--      <button mat-flat-button>Reset wachtwoord</button>-->
<!--      <button mat-flat-button>Verander email</button>-->
    </div>

    <div class="right">
      @if ((displayMode() == 'display' && form.dirty) || (displayMode() == 'edit' && form.dirty)) {
        <button mat-flat-button color="primary" type="submit" form="form" autofocus>Ok</button>
        <button mat-flat-button (click)="onCancel()">Annuleer</button>
      } @else {
        <button mat-flat-button color="primary" (click)="close()">Sluit</button>
      }
    </div>

  </mat-card-actions>
</mat-card>
