import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$e: <<value>>}
 * }
 */
export class EqualOperator implements Operator {

	constructor(public readonly field: string, public readonly value: any) {
	}

  definition(): EncodedOperator {
    return {
      o: "$e", f: this.field, v: this.value
    }
  }

	build(): object {
		return {
      [this.field]: {"$e": this.value}
    };
	}
}
