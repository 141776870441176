
// Revisited

export const CANDIDATE_VIEW_ALL_PUBLISHED = "candidate/view-all-published"
export const CANDIDATE_VIEW_ALL_DRAFT = "candidate/view-all-draft"
export const CANDIDATE_VIEW_ALL_INACTIVE = "candidate/view-all-inactive"
export const CANDIDATE_VIEW_OWN_DRAFT = "candidate/view-own-draft"
export const CANDIDATE_VIEW_OWN_INACTIVE = "candidate/view-own-inactive"

export const CANDIDATE_VIEW_ALL_ADDITIONAL_INFO = "candidate/view-all-additional-info"
export const CANDIDATE_VIEW_ADDITIONAL_INFO = "candidate/view-additional-info"

//export const CANDIDATE_VIEW_PERSONAL_INFO_PERMISSION = "candidate/view-personal-info";
export const CANDIDATE_VIEW_ALL_PERSONAL_INFO = "candidate/view-all-personal-info"
export const CANDIDATE_VIEW_OWN_PERSONAL_INFO = "candidate/view-own-personal-info"

// Old
export const CANDIDATE_CREATE_PERMISSION = "candidate/create";
export const CANDIDATE_UPDATE_PERMISSION = "candidate/update";



export const CANDIDATE_SET_PUBLISHED_PERMISSION = "candidate/set-published"
export const CANDIDATE_SET_DRAFT_PERMISSION = "candidate/set-draft"
export const CANDIDATE_SET_INACTIVE_PERMISSION = "candidate/set-inactive"



export const DASHBOARD_VIEW_PERMISSION = "dashboard/view";

export const USER_VIEW_PERMISSION ="user/view";
export const USER_CREATE_PERMISSION ="user/create";
export const USER_UPDATE_PERMISSION ="user/update";
export const USER_DELETE_PERMISSION ="user/delete";


export const COMPANY_VIEW_PERMISSION ="company/view";
export const COMPANY_CREATE_PERMISSION ="company/create";
export const COMPANY_UPDATE_PERMISSION ="company/update";
export const COMPANY_DELETE_PERMISSION ="company/delete";


export const EDUCATIONLEVEL_VIEW_PERMISSION ="educationLevel/view";
export const EDUCATIONLEVEL_CREATE_PERMISSION ="educationLevel/create";
export const EDUCATIONLEVEL_UPDATE_PERMISSION ="educationLevel/update";
export const EDUCATIONLEVEL_DELETE_PERMISSION ="educationLevel/delete";


export const JOBTITLE_VIEW_PERMISSION = "jobTitle/view";
export const JOBTITLE_CREATE_PERMISSION = "jobTitle/create";
export const JOBTITLE_UPDATE_PERMISSION = "jobTitle/update";
