import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule, Routes } from "@angular/router";
import { PendingChangesGuard } from "../../../lib/component/pending-changes-guard/component";
import { PropeldAppStateModule } from "../../../lib/service/app-state/module";
import { CompanyComponentModule } from "../../component/company/module";
import { UserComponentModule } from "../../component/user/module";
import { CompanyDetailPageComponent } from "./detail-page/component";
import { CompanyListPageComponent } from "./list-page/component";

const routes: Routes = [
  {
    path: "",
    component: CompanyListPageComponent
  }, {
    path: ":id",
    component: CompanyDetailPageComponent,
    canDeactivate: [PendingChangesGuard]
  }
];

@NgModule({
	declarations: [
    CompanyListPageComponent, CompanyDetailPageComponent
	],

	exports: [
	],

	imports: [
		RouterModule.forChild(routes),
		CommonModule,
		ReactiveFormsModule,

		MatIconModule,
		RouterModule,
		MatMenuModule,
		MatButtonModule,
		MatListModule,
		MatCheckboxModule,
		MatInputModule,
		CompanyComponentModule,
		UserComponentModule,
		PropeldAppStateModule
	]
})
export class CompanyModule {
}

