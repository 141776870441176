<div class="page-content">


  <div class="form section mat-typography">
    <div class="inner">

      <div class="left">
      </div>
      <div class="column">

        <div class="title">
          <div class="inner">
            <h1>Registreren</h1>
          </div>
        </div>

        <form [formGroup]="form" (ngSubmit)="save()">
          <div class="pre-text">
            <p class="mat-body-1">Vul je gegevens in. En je ontvangt een bevestiging per mail om de
              registratie te voltooien.</p>
          </div>

          <div class="invalid-credentials">
          </div>

          <div class="row">

            <mat-form-field class="password" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" placeholder="Email adres">

              <mat-error *ngIf="emailControl.hasError('email')">
                Geef een geldig email adres.
              </mat-error>

              <mat-error *ngIf="emailControl.hasError('required')">
                Email is verplicht.
              </mat-error>

            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field class="password" appearance="outline">
              <mat-label>Wachtwoord</mat-label>
              <input matInput formControlName="password" type="password" placeholder="Controle">

              <mat-error *ngIf="passwordControl.hasError('required')">
                Nieuw wachtwoord is verplicht.
              </mat-error>
              <mat-error *ngIf="passwordControl.hasError('minlength')">
                Minimum wachtwoord lengte is 14 tekens.
              </mat-error>

            </mat-form-field>
          </div>

          <div class="row action">
            <button mat-flat-button [routerLink]="['/login']">Annuleren</button>
            <button mat-raised-button color="accent" type="submit">Ok</button>
          </div>
        </form>

      </div>

      <div class="column right">
        <img src="assets/images/register-img.jpg" alt="register image"/>
      </div>
    </div>
  </div>
</div>


