import { NgModule } from "@angular/core";
import { SOCKET_EVENT_BUS_BRIDGE_OPTIONS_PROVIDER } from "./event-bus-bridge/socket/socket-event-bus-bridge";
import { SOCKET_OPTIONS_PROVIDER } from "./socket/options";

@NgModule({
  declarations: [

  ],
  providers: [
    SOCKET_OPTIONS_PROVIDER,
    SOCKET_EVENT_BUS_BRIDGE_OPTIONS_PROVIDER
  ]
})
export class PropeldCoreModule {
}
