import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, NgModule } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { Router, RouterModule } from "@angular/router";
import { CrudAction } from "../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../lib/core/event-bus/event-bus";
import { SnackbarService } from "../../../lib/service/snackbar/service/snackbar.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "register",
  templateUrl: "component.html",
  styleUrls: [
    "component.scss"
  ]
})
export class RegisterPage {

  private snackbar = inject(SnackbarService);
  private destroyRef = inject(DestroyRef);
  private eventBus = inject(EventBus);
  private router = inject(Router);

  emailControl = new FormControl<string>("", [Validators.required, Validators.email]);
  passwordControl = new FormControl<string>(
    "",
    [Validators.required, Validators.minLength(14), Validators.maxLength(64)]
  );

  form = new FormGroup({
    email: this.emailControl,
    password: this.passwordControl
  }, {updateOn: "submit"});

  save() {
    if (this.form.invalid) {
      return;
    }

    const action = new CrudAction("user/register", 0, 0, this.form.value);
    this.eventBus.request(action.type, action).subscribe({
      next: (value) => {
        this.snackbar.info("Registratie is gelukt. Zie mail.");
        this.router.navigate(["/login"], {replaceUrl: true})
          .catch(error => console.error(error));
      },
      error: (error) => {
        this.snackbar.info("Er is een fout opgetreden. Probeer het opnieuw.");
        console.error("Unable to reset password", {
          value: this.form.value,
          error
        });
      }
    });
  }
}

@NgModule({
  declarations: [RegisterPage],
  imports: [
    CommonModule,
    MatIconModule,
    MatSliderModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule
  ]
})
export class RegisterPagePageModule {
}
