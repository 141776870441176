import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { PropeldFormDisplayModule } from "../../../lib/component/form-display/module";
import { PropeldAutocompleteInputModule } from "../../../lib/component/form-select/module";
import { PropeldSearchInputModule } from "../../../lib/component/mat-search-input/module";
import { PropeldComponentModule } from "../../../lib/component/module";
import { PropeldMatTableSortModule } from "../../../lib/directive/mat-table-source/module";
import { PropeldAppStateModule } from "../../../lib/service/app-state/module";
import { CardActionsModule } from "../card-actions/module";
import { AccountDetailComponent } from "./account-detail/component";
import { AccountMenuItemComponent } from "./account-menu-item/component";

@NgModule({
	declarations: [
    AccountMenuItemComponent, AccountDetailComponent
	],

	exports: [
    AccountMenuItemComponent,AccountDetailComponent
	],

  imports: [
    RouterModule,
    CommonModule,
    // BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,

    PropeldComponentModule,
    PropeldSearchInputModule,
    PropeldMatTableSortModule,

    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    PropeldFormDisplayModule,
    PropeldAutocompleteInputModule,
    CardActionsModule,
    PropeldAppStateModule
  ]
})
export class AccountComponentModule {
}
