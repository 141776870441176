<mat-form-field class="search" [appearance]="appearance" [subscriptSizing]="subscriptSizing"
>
  @if (iconPrefix) {
    <mat-icon class="prefix-icon" matIconPrefix>search</mat-icon>
  }

  <input #search matInput type="text"
         [placeholder]="placeholder"
         [formControl]="searchInput"
         [readonly]="readonly"
  >

  @if (searchInput.value) {
    <button class="button-clear" matSuffix mat-icon-button aria-label="Clear"
            (click)="this.searchInput.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
  }
</mat-form-field>
