import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	$null: <<field>>
 * }
 */
export class NullOperator implements Operator {
	constructor(public readonly  field: string) {
	}

  definition(): EncodedOperator {
    return {
      o: "$null", f: this.field
    }
  }

  build(): object {
		return {
			"$null": this.field
		};
	}
}
