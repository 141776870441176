import { computed, effect, Injectable, signal, Signal } from "@angular/core";
import { patchState, signalState } from "@ngrx/signals";
import { Source } from "../../source/source";
import { BreakpointDefinition } from "./breakpoints";
import { Resizer } from "./resizer";

export interface AppStateSetting {
  screenSize: BreakpointDefinition,
  isMobile: boolean,
  appHeader: AppHeaderSetting,
  actionId: string | null,
  isMenuOpen: boolean
}

export interface AppHeaderSetting {
  title?: string;
  subTitle?: string;
  isSubPage?: boolean;
  searchSource?: Source<any>|null,
  searchExpanded?: boolean,
  searchEnabled?: boolean,
  buttons?: AppHeaderButtonItem[]
  menuItems?: AppHeaderMenuItem[]
}

export interface AppHeaderButtonItem {
  id: string,
  text?: string,
  icon: string
}

export interface AppHeaderMenuItem {
  id: string,
  text: string,
  icon?: string
}

@Injectable({
  providedIn: "root"
})
export class AppState {

  private resizer = new Resizer();

  readonly state = signalState<AppStateSetting>({
    screenSize: {
      name: "init"
    },
    isMobile: false,
    appHeader: {
      title: "",
      subTitle: undefined,
      isSubPage: false,
      searchSource: null,
      searchEnabled: false,
      searchExpanded: false,
      buttons: [] as AppHeaderButtonItem[],
      menuItems: [] as AppHeaderMenuItem[]
    },
    actionId: null,
    isMenuOpen: true
  });

  screenSize$ = computed( () => this.state.screenSize);
  isMobile$:Signal<boolean> = computed( () => this.state.isMobile());
  appHeader$ = computed( () => this.state.appHeader);
  action$ = computed( () =>  this.state.actionId());
  isMenuOpen$ = computed( () =>  this.state.isMenuOpen());

  setAppHeader(appHeader: AppHeaderSetting) {
    if(appHeader.searchSource === null) {
      appHeader.searchExpanded = false;
    }

    patchState(this.state, state => ({
        appHeader: Object.assign({}, this.state.appHeader(), appHeader)
      })
    )
  }

  constructor() {
    effect(() => {
      patchState(this.state, {
        screenSize: this.resizer.screenSize$(),
        isMobile: this.resizer.screenSize$()?.name === "XSmall" || this.resizer.screenSize$()?.name === "Small"
      });
    }, {allowSignalWrites: true});
  }

  setAction(actionId: string | null) {
    patchState(this.state, state => ({
        actionId: actionId
      })
    );
  }

  searchSource$ = signal<Source<any> | null>(null);

  setSearchSource(source: Source<any>) {
    this.searchSource$.set(source);
    this.setAppHeader({
      searchSource: source,
      searchEnabled: true
    });
  }

  setMenuOpen(isOpen: boolean) {
    patchState(this.state, {
      isMenuOpen: isOpen
    });
  }
  clearSearchSource() {
    this.setAppHeader({
      searchSource: null,
      searchEnabled: false
    });
  }

  setSearchVisible(visible: boolean) {
    this.setAppHeader({
      searchExpanded: visible
    });
  }
}
