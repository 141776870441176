<div
  appHeaderReset
  appState
  [title]="model$().displayName"
  [isSubPage]="true">

  <user-detail-card #userDetailCard

    [modelId]="(modelId$ | async)!"
               (modelChange)="onModelLoaded($event)"
              [requiredFields]="(required$ | async)!">

  </user-detail-card>
</div>
