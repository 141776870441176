import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$nin: <<value[]>>}
 * }
 */
export class NotInOperator implements Operator {
	constructor(public readonly  field: string, private value: any[]) {
	}

  definition(): EncodedOperator {
    return {
      o: "$nin", f: this.field, v: this.value
    }
  }

  build(): object | null {
		if (this.value.length <= 0) return null;
		return {
      [this.field]: {"$nin": this.value}
    };
	}
}
