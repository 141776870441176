import { Component, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Action } from "../../../../lib/core/event-bus/action";
import { CrudAction } from "../../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../../lib/core/event-bus/event-bus";
import { Message } from "../../../../lib/core/event-bus/message/message";
import { fatalError } from "../../../../lib/service/snackbar/service/error-filter";
import { SnackbarService } from "../../../../lib/service/snackbar/service/snackbar.service";

@Component({
	selector: "company-list-page",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/styles.scss"]
})
export class CompanyListPageComponent {

	private store = inject(Store);
	private eventBus = inject(EventBus);
  private snackbar = inject(SnackbarService);

	constructor() {
		this.store.subscribe({
			next: (store) => {
				console.log("Store updated: ",store);
			}
		});
	}

	delete() {
		this.store.dispatch(new CrudAction("project/deleted", 1, 2, {

		}));
	}

	filter() {
		const action = new Action("company/filter", { });

		this.eventBus.request("store/filter", action)
			.subscribe({
				next: (message: Message<Action<any>>) => {
					console.log("store/filtered: "+message);
				},
        		error: (error: Error) => fatalError(this.snackbar, error)
			});
	}
}
