import { Directive, inject, Input } from "@angular/core";
import { AppHeaderButtonItem, AppHeaderMenuItem, AppState } from "./app-state";

@Directive({
  selector: '[appState]',
})
export class AppStateDirective {
  private appState = inject(AppState);

  @Input()
  set title(title: string) {
    this.appState.setAppHeader({
      title: title
    });
  }

  @Input()
  set isSubPage(isSubPage: boolean) {
    this.appState.setAppHeader({
      isSubPage: isSubPage
    });
  }

  @Input()//{transform: (value: any) => value as AppHeaderButtonItem[]})
  set buttons(buttons: AppHeaderButtonItem[]) {
    this.appState.setAppHeader({
      buttons: buttons
    });
  }

  @Input()
  set menuItems(menuItems: AppHeaderMenuItem[]) {
    this.appState.setAppHeader({
      menuItems: menuItems
    });
  }
}

@Directive({
  selector: '[appHeaderReset]',
})
export class AppHeaderResetDirective {
  private appState = inject(AppState);

  constructor() {
    this.appState.clearSearchSource();
    this.appState.setAppHeader({
      title: "",
      subTitle: "",
      isSubPage: false,
      searchSource: null,
      searchEnabled: false,
      buttons: [], //[{icon: "edit"},{icon:"close"}],
      menuItems: []
    });
  }
}
