import { DeliveryOptions } from "../delivery-options";
import { EventBus } from "../event-bus";
import { MessageCodec } from "../message-codec";
import { Message, MessageType } from "./message";

export interface MyError extends Error {
  message: string;
}

export class MyErrorMessage<T> extends Message<any> {
  constructor(
    address: string,
    type: MessageType,
    body: T,
    eventBus: EventBus,
    codec: MessageCodec<unknown, T>,
    options?: DeliveryOptions
  ) {
    super(address, type, body, eventBus, codec, options);
  }
}



export interface FailureObject extends Error {
  address: string,
  failureType: string,
  failureCode: number,
  message: string,
  type?: string,
  name: string
}

export class ErrorMessage extends Error {

  readonly address: string;
  readonly type: string;
  readonly failureType: string;
  readonly failureCode: number;

  constructor(errorMessage: FailureObject) {
    super(errorMessage.message);
    this.address = errorMessage.address;
    this.type = errorMessage.type || "err";
    this.failureCode = errorMessage.failureCode;
    this.failureType = errorMessage.failureType;
    this.message = errorMessage.message;
    this.name = errorMessage.name;
  }

  public toJSON(): FailureObject {
    return {
      address: this.address,
      failureType: this.failureType,
      failureCode: this.failureCode,
      message: this.message,
      type: this.type,
      name: this.name
    };
  }
}
