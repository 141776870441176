<div class="header section" [ngClass]="{ 'mobile-page-content': isMobile() }">
  <div class="inner">
    <div class="column left">
      <!--      <div class="logo">-->
      <a href="#">
        <img class="logo" src="/assets/images/logo-talent-portal-hor.svg" alt="talent-portal logo">
      </a>
      <!--      </div>-->
    </div>
    <div class="column center">
      <div>
        <div class="menu">
          <ul [ngClass]="{'open': isMenuOpened()}">
            <li>
              <a class="base-font" href="https://commercieelnederland.nl/talentportal#werkgevers">Voor
                werkgevers
              </a>
            </li>
            <li>
              <a class="base-font" href="https://commercieelnederland.nl/talentportal/kandidaten">Voor
                kandidaten
              </a>
            </li>
            <li>
              <a class="base-font" href="https://commercieelnederland.nl/talentportal#stappen">Werkwijze</a>
            </li>
            <li>
              <a class="base-font" href="https://commercieelnederland.nl/talentportal#selectie">Selectie</a>
            </li>
            @if (this.accountService.get().authRoleId <= 10) {
              <li>
                <a class="base-font" href="/register">Registreren</a>
              </li>
            } @else {
              <li>
                <a class="base-font" href="#" (click)="logout($event)">Logout</a>
              </li>
            }
          </ul>
        </div>
      </div>
      <div class="account-user">
        @if (accountService.get().authRoleId > 10) {
          <p>Ingelogd als {{ accountService.get().displayName }}</p>
          <p>
            <a class="base-font" [routerLink]="['/#']" (click)="logout($event)">Logout</a>
          </p>
        }
      </div>
    </div>
    <div class="column right">
      <div class="account-small">
        @if (accountService.get().authRoleId > 10) {
          <span>{{ accountService.get().displayName }}</span>
        }
      </div>

      <button mat-icon-button aria-label="Menu" (click)="toggleMenu()">
        @if (isMenuOpened()) {
          <mat-icon>close</mat-icon>
        } @else {
          <mat-icon>menu</mat-icon>
        }
      </button>
    </div>
  </div>
</div>

<div class="section page-content" [ngClass]="{ 'mobile-page-content': isMobile() }">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

@if (showFooter()) {
  <div class="contact section" [ngClass]="{ 'mobile-page-content': isMobile() }">
    <div class="inner 2-columns">
      <div class="column left">
        <div class="centered">
          <h2>Contact</h2>
        </div>
        <p class="emphasized">Het Talent Portal is een initiatief van Commercieel Nederland.</p>
        <p>Heb je vragen over het Talent Portal of ben je op zoek naar talent? Neem dan gerust vrijblijvend
          contact met ons op.
        </p>
        <p>Je kunt ons bereiken via 085 – 0163626 of stuur een mail naar
          <a href="email:info@talentportalnederland.nl">
            info&#64;talentportalnederland.nl
          </a>
          .
        </p>
        <div class="centered">
          <a href="https://commercieelnederland.nl/contact/" target="_blank">
            <button mat-flat-button color="accent">Neem contact op</button>
          </a>
        </div>
      </div>
      <div class="column right">
        <img src="/assets/images/logo-plain.png" alt="talent-portal logo">
      </div>
    </div>
  </div>

  <div class="footer section" [ngClass]="{ 'mobile-page-content': isMobile() }">
    <div class="inner 3-columns">
      <div class="column left">
        <a href="https://commercieelnederland.nl">
          <img class="logo" src="/assets/images/logo-talent-portal-horizontal.svg">
        </a>

        <ul>
          <li>
            <a href="tel:085-0163626">
              <mat-icon>phone</mat-icon>
              085-0163626
            </a>
          </li>
          <li>
            <a href="email:info@talentportalnederland.nl">
              <mat-icon>email</mat-icon>
              info&#64;talentportalnederland.nl
            </a>
          </li>
        </ul>

        <a href="https://recruitercode.nl/de-recruitercode">
          <img class="recruiter-code-logo" src="/assets/images/logo-recruiter-code.png">
        </a>
      </div>

      <div class="column center">
        <ul>
          <li>
            <a href="https://commercieelnederland.nl/talentportal/#werkgevers">Voor werkgevers</a>
          </li>
          <li>
            <a href="https://commercieelnederland.nl/talentportal/kandidaten/">Voor kandidaten</a>
          </li>
          <li>
            <a href="https://commercieelnederland.nl/talentportal/#stappen">Werkwijze</a>
          </li>
          <li>
            <a href="https://commercieelnederland.nl/talentportal/#selectie">Selectie</a>
          </li>
          <li>
            <a href="https://commercieelnederland.nl/talentportal/#contact">Contact</a>
          </li>
          <li>
            <a href="/register">Registreren</a>
          </li>
        </ul>
      </div>

      <div class="column right">
        <p class="emphasized">Versterkt commerciële slagkracht en vergroot commercieel succes!</p>

        <p>Meer weten hoe wij jou als partner in sales kunnen ondersteunen?</p>

        <div class="centered">
          <a href="https://commercieelnederland.nl/contact/" target="_blank">
            <button mat-flat-button color="accent">Neem contact op</button>
          </a>
        </div>
      </div>
    </div>
  </div>
}
