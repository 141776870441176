import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 *  <<field>>: {"$s": <<value>>}
 * }
 */
export class SearchOperator implements Operator {
  constructor(public readonly field: string, private value: any) {
  }

  definition(): EncodedOperator {
    return {
      o: "$s", f: this.field, v: this.value
    }
  }

  build(): object {
    return {
      [this.field]: {"$s": this.value}
    };
  }
}
