import { CollectionViewer } from "@angular/cdk/collections";
import { effect, signal, Signal, WritableSignal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Store } from "@ngrx/store";
import { BehaviorSubject, distinctUntilChanged, Observable, of } from "rxjs";
import { first, map } from "rxjs/operators";
import { Action } from "../core/event-bus/action";
import { CrudAction } from "../core/event-bus/crud-action";
import { EventBus } from "../core/event-bus/event-bus";
import { Message } from "../core/event-bus/message/message";
import { MachineEvent, MachineStateDefinition, StateMachine } from "../statemachine/statemachine";
import { ModelData } from "../store/model/dataModel";
import { Sort, SortDirection, Source, SourceData, SourceLoadingStates } from "./source";
import { Filter } from "./source-builder";
import { SourcePageState } from "./state/source-page-state-store";

export enum SourceStateEvents {
  INIT="INIT", READY = "READY", PARAMS_CHANGED = "PARAMS_CHANGED", LOADED = "LOADED", DESTROY = "DESTROY"
}

export const SourceStateDefinition = {
  [SourceLoadingStates.INIT]: {
    [SourceStateEvents.READY]: {
      [SourceLoadingStates.READY]: {}
    }
  },
  [SourceLoadingStates.READY]: {
    [SourceStateEvents.PARAMS_CHANGED]: {
      [SourceLoadingStates.LOADING]: {}
    }
  },
  [SourceLoadingStates.LOADING]: {
    [SourceStateEvents.LOADED]: {
      [SourceLoadingStates.IDLE]: {}
    },
    [SourceStateEvents.DESTROY]: {
      [SourceLoadingStates.DESTROYED]: {}
    }
  },
  [SourceLoadingStates.IDLE]: {
    [SourceStateEvents.PARAMS_CHANGED]: {
      [SourceLoadingStates.LOADING]: {}
    },
    [SourceStateEvents.DESTROY]: {
      [SourceLoadingStates.DESTROYED]: {}
    }
  },
  [SourceLoadingStates.DESTROYED]: {
  }
} as MachineStateDefinition;



export class SourceImpl<T> implements Source<T> {

  private _pageState$ = signal<SourcePageState>({
    name: "initial",
    offset: 0,
    limit: 20,
    pageNo: 0,
    pageSize: 20,
    search: undefined,
    sort: [],
    filter: null,
    expandedRows: null
  });

  public get pageFilterState$(): Signal<SourcePageState> {
    return this._pageState$.asReadonly()
  }

  private patch(partialState: Partial<SourcePageState>) {
    console.log("STATES PATCH 0 "+JSON.stringify(this._pageState$(), null, 4));
    console.log("STATES PATCH 1 "+JSON.stringify(partialState, null, 4));
    this._pageState$.update((state) => ({
      ...state,
      ...partialState,
    }));
    console.log("STATES PATCH 2 "+JSON.stringify(this._pageState$(), null, 4));
  }

  private _filter: any | null = null;
  private _requiredFilter: any | null = null;

  private _matPaginator: MatPaginator | null = null;
  private _count = signal<number>( 0);

  model$ = signal({} as T);

  get dataSource$(): Observable<SourceData<T>> {
    return this._dataSource$;
  }

  get dataSourceAsArray$(): Observable<T[]> {
    return this.dataSource$.pipe(map(items => items.items));
  }

  get singleModel$(): WritableSignal<T> {
    throw Error("Unsupported")
  }

  get loadingStatus$(): Signal<SourceLoadingStates> {
    return this.stateMachine.state;
  }
  get loadingEvent$(): Signal<MachineEvent|undefined> {
    return this.stateMachine.event;
  }

  constructor(
    private mainEntityName: string,
    private store: Store<any>,
    private eventBus: EventBus,
    private trigger$: BehaviorSubject<Filter>,
    private _dataSource$: Observable<SourceData<T>>,
    public stateMachine: StateMachine<SourceLoadingStates, SourceStateEvents>,
    readonly name: string
  ) {
    this.onSourceStateEvent(SourceStateEvents.READY);

    this._dataSource$.pipe(map(data => (data as any).count), distinctUntilChanged())
      .subscribe(length => {
        this.count = length;
      });

    effect(() => {
      const state = this._pageState$();
      console.log(">>> Fetch primary 1 candidate effect: "+ JSON.stringify(state,null,4));

      const offset = state.offset;
      const pageSize = state.pageSize;
      if(this._matPaginator != null) {
        this._matPaginator.pageSize = pageSize;
        this._matPaginator.pageIndex = Math.floor(offset / pageSize);
      }
      this.refresh(this._pageState$());
    });
  }

  private onSourceStateEvent(event: SourceStateEvents) {
    this.stateMachine.on(event);
  }

  get singleModel(): Signal<T> {
    // return toSignal(
    const x: Observable<T> = this.dataSource$.pipe(map((data: SourceData<T>) => {
      return data.items[0] as T;
    }));
    return (toSignal(x) as any) as Signal<T>
  }

  connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    return this._dataSource$.pipe(map(data => (data as any).items));
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSource$;
  }

  set paginator(matPaginator: MatPaginator) {
    this._matPaginator = matPaginator;

    const state = this._pageState$();
    if(state.pageSize != 0) {
        matPaginator.pageSize = state.pageSize;
        matPaginator.pageIndex = Math.floor(state.offset / state.pageSize);
    } else {
      const pageSize = matPaginator.pageSize;
      if (pageSize != null) {
        this.patch({
          pageNo: matPaginator.pageIndex ?? 0,
          pageSize: pageSize,
          offset: matPaginator.pageIndex * pageSize,
          limit: pageSize
        });
      }
    }
    console.log(">>> Fetch primary 1 candidate paginator: "+ JSON.stringify(this._pageState$(),null,4));
    //
    this._matPaginator.page.subscribe({
      next: (pageEvent: PageEvent) => {
        this.patch({
          pageNo:matPaginator.pageIndex,
          pageSize: matPaginator.pageSize,
          offset: matPaginator.pageIndex * matPaginator.pageSize,
          limit: matPaginator.pageSize
        });
      },
      error: (error: Error) => {
        console.log(error.message);
      }
    });
  }

  addSort(sort: Sort): this {
    throw Error("TODO");
  }

  adjustSort(fieldName: string, direction: SortDirection): this {
    throw Error("TODO");
  }

  clone(filterHandlerAddress?: string): Source<T> {
    throw Error("TODO");
  }

  countChanged(): Observable<number> {
    throw Error("TODO");
  }

  dataChanged(): Observable<T> {
    throw Error("TODO");
  }

  getName(): Readonly<string> {
    return this._pageState$().name as Readonly<string>;
  }

  setName(name: string): this {
    this.patch({
      name: name
    });
    // patchState(this.current, (state) => {
    //   state.name = name;
    //   return state;
    // });
    console.log(">>> Fetch primary 1 candidate setName: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  getLimit(): Readonly<number> {
    return this._pageState$().limit as Readonly<number>;
    // return this.current.limit() as Readonly<number>;
  }

  setLimit(pageSize: number): this {
    this.patch({
      pageSize: pageSize,
      limit: pageSize
    });

    // patchState(this.current, (state) => {
    //   state.pageSize = pageSize;
    //   state.limit = pageSize;
    //   return state;
    // });
    console.log(">>> Fetch primary 1 candidate setLimit: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  getOffset(): Readonly<number> {
    return this._pageState$().offset as Readonly<number>;
    // return this.current.offset() as Readonly<number>;
  }

  setOffset(offset: number): this {
    this.patch({
      offset: offset
    });

    // patchState(this.current, (state) => {
    //   state.offset = offset;
    //   return state;
    // });
    return this;
  }

  getPageSize(): number {
    return this._pageState$().pageSize as Readonly<number>;
    // return this.current.pageSize() as Readonly<number>;
  }

  setPageSize(pageSize: number): this {
    this.patch({
      pageNo : 0,
      pageSize : pageSize,
      offset : 0,
      limit : pageSize
    });
    console.log(">>> Fetch primary 1 candidate setPageSize: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  getState() {
    return this._pageState$();
  }

  setState(filterState?: Partial<SourcePageState>): this {
    if(filterState == null) return this;
    this.patch(filterState);

    console.log(">>> Fetch primary 1 candidate setState: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  getFilter(): Readonly<Filter> {
    throw Error("TODO");
  }

  private serializedFilter: string | null = null;
  private serializedRequiredFilter: string | null = null;

  setFilter(filter: any): this {
    // Hack - Only apply the filter if it really has changed to prevent an infinitive loop
    // It might be called with a new instance (AND comparator) but with the same filter conditions
    const serializedFilter = JSON.stringify(filter);
    if(this.serializedFilter === serializedFilter) return this;
    this.serializedFilter = serializedFilter;

    this._filter = filter;
    this.patch({
      filter: this.buildCombinedFilter(this._filter, this._requiredFilter)
    });
    console.log(">>> Fetch primary 1 candidate setFilter: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  setRequiredFilter(filter: any): this {
    // Hack - Only apply the filter if it really has changed to prevent an infinitive loop
    // It might be called with a new instance (AND comparator) but with the same filter conditions
    const serializedRequiredFilter = JSON.stringify(filter);
    if(this.serializedRequiredFilter === serializedRequiredFilter) return this;
    this.serializedRequiredFilter = serializedRequiredFilter;

    this._requiredFilter = filter;
    this.patch({
      filter: this.buildCombinedFilter(this._filter, this._requiredFilter)
    });
    console.log(">>> Fetch primary 1 candidate setRequiredFilter: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  buildCombinedFilter(a: any = null, b: any = null) {
    // const a = this._filter;
    // const b = this._requiredFilter;
    let f: any | null;

    if (a == null) {
      if (b == null) {
        f = null;
      } else {
        f = b;
      }
    } else {
      if (b == null) {
        f = a;
      } else {
        f = {
          $and: [a, b]
        };
      }
    }
    return f;
  }


  get count(): Readonly<number> {
    return this._count();
  }

  private set count(value: number) {
    this._count.set(value);
  }

  get count$() {
    return this._count;
  }

  getParams(): Readonly<unknown> {
    throw Error("TODO");
  }

  getSearch(): Readonly<string> {
    return this._pageState$().search as Readonly<string>;
  }

  setSearch(query: string | null): this {
    this.patch({
      search: query ?? "",
      pageNo: 0,
      offset: 0
    });
    console.log(">>> Fetch primary 1 candidate setSearch: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  private counter = 0;

  refresh(filter?: Filter): void {
    console.log(">>> Fetch primary 1 candidate refresh: "+ JSON.stringify(filter,null,4));
    if (filter) {
      const clone = JSON.parse(JSON.stringify(filter));
      console.log(">>> Fetch primary 1a candidate refresh: "+ JSON.stringify(clone,null,4));
      this.trigger$.next(clone);
      return;
    }
    // this.trigger$.next({});
  }

  removeOperator(fieldName: string): this {
    throw Error("TODO");
  }

  setParams(params: unknown): this {
    throw Error("TODO");
  }

  getSort(): Readonly<Sort[]> {
    throw Error("TODO");
  }

  setSort(sort: Sort[]): this {
    this.patch({
      sort: sort
    });
    console.log(">>> Fetch primary 1 candidate setSort: "+ JSON.stringify(this._pageState$(),null,4));
    return this;
  }

  sourceUpdated(): Observable<void> {
    throw Error("TODO");
  }

  /**
   * Fetch the items with the given ids based on the main entity name.
   * TODO: Check which items are already in the store and only fetch the missing ones.
   *
   * @param ids
   */
  getIds(ids: number[]): Observable<T[]> {

    var missingIds = ids;
    if (missingIds.length === 0) return of([] as T[]);
    const action = new CrudAction(`${this.mainEntityName}/get`, 0, 0, {
      ids: missingIds
    });
    return this.eventBus.request(action.type, action).asObservable
      .pipe(map((message: Message<Action<any>>) => {
        // Update the store with the received items
        this.store.dispatch(message.body);

        // Load models from the store and return them as a list
        const list = [] as T[];
        this.store.select(state => (state as any)[this.mainEntityName]).pipe(first())
          .subscribe(state => {
            return message.body.data.items.forEach((item: ModelData) => {
              list.push(state.get(item.id));
            });
          });
        return list;
      }));
  }
}
