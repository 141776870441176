import { Schema } from "./schema";
import { FieldType } from "./schema-definitions";
import { SchemaField } from "./schema-field";
import { SchemaRelation, SchemaRelationType } from "./schema-relation";

export class SchemaEntity {

	private readonly fields = new Map<string, SchemaField>;
	readonly relations = new Map<string, SchemaRelation>;

	constructor(
    private readonly schema: Schema,
    readonly name: string,
    ) {
	}

	createField(name: string, type: FieldType): SchemaField {
		const field = new SchemaField(this, name, type);
		this.fields.set(name, field);
		return field;
	}

	createRelation(
		name: string,
		type: SchemaRelationType,
		sourceField: SchemaField,
		targetField: SchemaField
	) {
		const relation = new SchemaRelation(this, name, type, sourceField, targetField);
		this.relations.set(name, relation);
		return relation;
	}

	getField(name: string): SchemaField | undefined {
		return this.fields.get(name);
	}

	getRelation(name: string): SchemaRelation | undefined {
		return this.relations.get(name);
	}

}
