import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { MatFormFieldAppearance, SubscriptSizing } from "@angular/material/form-field";
import { debounceTime, Observable, of, shareReplay, startWith } from "rxjs";
import { map } from "rxjs/operators";
import { SearchOperator } from "../../source-filter/operator/search";
import {
  FilterSetting, SourceFilter, SourceFilterComponentProvider
} from "../../source-filter/source-filter";
import { Source } from "../../source/source";

@Component({
  selector: "mat-search-input",
  templateUrl: "component.html",
  styleUrls: ["./component.scss"],
  providers: [SourceFilterComponentProvider(MatSearchInputComponent)],
  encapsulation : ViewEncapsulation.None,
})
export class MatSearchInputComponent implements OnInit, OnDestroy, SourceFilter, AfterViewInit {
  private destroyedRef = inject(DestroyRef);
  private filterChanges$?: Observable<any>;

  searchInput = new FormControl("");

  @Input() appearance: MatFormFieldAppearance = "fill";
  @Input() placeholder: string = "Zoeken";
  @Input() source: Source<any> | null = null;
  @Input() iconPrefix: boolean = true;
  @Input() topBar: boolean = true;
  @Input() subscriptSizing: SubscriptSizing = "dynamic";
  @Input() readonly: boolean = false;

  @ViewChild("search", { static: true }) input: ElementRef | undefined;

  get id(): string {
    return "search";
  }

  get filterSetting(): FilterSetting {
    return this.searchInput?.value;
  }

  set filterSetting(setting: FilterSetting) {
    this.searchInput.setValue(setting ?? "");
  }

  ngOnInit() {
    this.input?.nativeElement.focus();
    if (this.source != null) {
      const v = this.source.getSearch();
      this.searchInput.setValue(v);

      this.searchInput.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef), debounceTime(300)).subscribe({
        next: (value) => {
          this.source?.setSearch(value);
        },
        error: (error) => {
          console.error(error.message);
        }
      });
    }
  }


  ngAfterViewInit() {
    // this.search?.nativeElement.focus();
  }

  ngOnDestroy() {
  }

  get filterChanges(): Observable<any> {
    if (this.source != null) return of(null);

    return this.searchInput.valueChanges.pipe(
      startWith(this.searchInput.value),
      takeUntilDestroyed(this.destroyedRef),
      debounceTime(300),
      map(value => {
        if (value == null) return null;
        return new SearchOperator("search", value).build();
      }),
      shareReplay(1)
    );
  }
}
