import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { initialRegionStore, Region, RegionModel } from "./model";

export const REGION_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("RegionReducer");

export function regionReducerProvider() {
	return reducerFactory<RegionModel>("region", initialRegionStore, (data: RegionModel) => {
		return new Region(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("region", REGION_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("region"),

	], providers: [
		{
			provide: REGION_REDUCER_TOKEN,
			useFactory: regionReducerProvider
		}
	]
})
export class RegionCoreModule {

}
