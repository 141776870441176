import {
  BaseModel, DataModel, DataModelId, DataModelMap, NullableString
} from "../../../../lib/store/model/dataModel";

export type CityStore = DataModelMap<CityModel>;

export const initialCityStore: CityStore = new Map<DataModelId, CityModel>();

export interface CityData extends DataModel {
	name: NullableString;
}

export interface CityModel extends CityData, DataModel {

}


export class City extends BaseModel implements CityModel {

	readonly name: NullableString;

	constructor(data: CityModel) {
		super(data.id, data.rev);
		this.name = data.name;
	}

	/**
	 * Clones a city and applies given properties in the data parameter
	 *
	 * @param data
	 */
	clone(data?: any): City {
      data = data ? data : {};

      return new City({
			id: this.id, //
			rev: Object.hasOwn(data,"rev") ? data.rev : this.rev, //
			name: Object.hasOwn(data,"name") ? data.name : this.name //
		} as CityModel);
	}

	get displayName(): string {
		return this.name ? this.name : "";
	};
}
