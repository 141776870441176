import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData, NullableNumber, NullableString
} from "../../../../lib/store/model/dataModel";
import { EducationLevel } from "../educationLevel/model";

export type CandidateStore = DataModelMap<CandidateModel>;

export const initialCandidateStore: CandidateStore = new Map<DataModelId, Candidate>();

export interface CandidateData extends ModelData {
  uuid: string;
  gender: string;

  jobTitle?: NullableString;
  jobTitleIds: (number|string)[];
  desiredJobTitle?: NullableString;
  desiredJobTitleIds: (number|string)[];
  region?: NullableString;
  regionIds: number[];
  functionArea?: NullableString;
  functionAreaIds: number[];
  industry?: NullableString;
  industryIds: number[];
  educationLevelId?: NullableNumber;
  workLevelId?: NullableNumber;
  info?: NullableString;
  personalInfo?: NullableString;
  typeOfPerson?: NullableString;
  competences?: NullableString;
  motivation?: NullableString;
  discStyle?: NullableString;
  functionLevel?: NullableString;

  salaryIndication?: NullableString;
  availability?: NullableString;
  drivingLicence: boolean;
  age?: NullableNumber;
  ppId: number,

  // Private fields
  firstName?: NullableString;
  infix?: NullableString;
  surname?: NullableString;
  street?: NullableString;
  houseNumber?: NullableString;
  zipCode?: NullableString;
  city?: NullableString;
  phone?: NullableString;
  email?: NullableString;
  vimeoLink?: NullableString;

  timestampModified?: NullableString;

  ownerId: number;
  ownerName: NullableString;
  status: string;

  educationLevel?: EducationLevel | null;

}

export interface CandidateModel extends CandidateData, DataModel {
  clone(data?: any): Candidate;
  fullName: string;
  toJson(): object;
  get surnameFirst(): string;
}

export class Candidate extends BaseModel implements CandidateModel {

  // readonly id: DataModelId;
  // readonly rev: DataModelRevision;

  readonly uuid: string;
  readonly gender: string;
  readonly jobTitle?: NullableString;
  readonly jobTitleIds: (number | string)[];
  readonly desiredJobTitle?: NullableString;
  readonly desiredJobTitleIds: (number | string)[];
  readonly region?: NullableString;
  readonly regionIds: number[];
  readonly functionArea?: NullableString;
  readonly functionAreaIds: number[];
  readonly educationLevelId?: NullableNumber;
  readonly workLevelId?: NullableNumber;
  readonly industry?: NullableString;
  readonly industryIds: number[];
  readonly info?: NullableString;
  readonly personalInfo?: NullableString;
  readonly typeOfPerson?: NullableString;
  readonly competences?: NullableString;
  readonly motivation?: NullableString;
  readonly discStyle?: NullableString;
  readonly functionLevel?: NullableString;

  readonly salaryIndication?: NullableString;
  readonly availability?: NullableString;
  readonly drivingLicence: boolean;
  readonly age?: NullableNumber;
  readonly ppId: number;

  // Private fields
  readonly firstName?: NullableString;
  readonly infix?: NullableString;
  readonly surname?: NullableString;
  readonly street?: NullableString;
  readonly houseNumber?: NullableString;
  readonly zipCode?: NullableString;
  readonly city?: NullableString;
  readonly phone?: NullableString;
  readonly email?: NullableString;
  readonly vimeoLink?: NullableString;
  readonly timestampModified?: NullableString;

  readonly ownerId: number;
  readonly ownerName: NullableString;
  readonly status: string = "D";

  readonly educationLevel?: EducationLevel | null;

  constructor(data: CandidateData) {
    super(data.id, data.rev);

    // this.id = data.id;
    // this.rev = data.rev;
    this.uuid = data.uuid;

    this.gender = data.gender;
    this.jobTitle = data.jobTitle;
    this.jobTitleIds = data.jobTitleIds;
    this.desiredJobTitle = data.desiredJobTitle;
    this.desiredJobTitleIds = data.desiredJobTitleIds;
    this.region = data.region;
    this.regionIds = data.regionIds;
    this.functionArea = data.functionArea;
    this.functionAreaIds = data.functionAreaIds;
    this.educationLevelId = data.educationLevelId;
    this.workLevelId = data.workLevelId;
    this.industry = data.industry;
    this.industryIds = data.industryIds;

    this.info = data.info;
    this.personalInfo = data.personalInfo;
    this.typeOfPerson = data.typeOfPerson;
    this.competences = data.competences;
    this.motivation = data.motivation;
    this.discStyle = data.discStyle;
    this.functionLevel = data.functionLevel;
    this.salaryIndication = data.salaryIndication
    this.availability = data.availability;
    this.drivingLicence = data.drivingLicence;
    this.age = data.age;
    this.ppId = data.ppId;

    // Private fields
    this.firstName = data.firstName;
    this.infix = data.infix;
    this.surname = data.surname;
    this.street = data.street;
    this.houseNumber = data.houseNumber;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.phone = data.phone;
    this.email = data.email;
    this.vimeoLink = data.vimeoLink;
    this.timestampModified = data.timestampModified;

    this.ownerId = data.ownerId;
    this.status = data.status;
    this.ownerName = data.ownerName;

    this.city = data.city;
    this.educationLevel = data.educationLevel;
  }

  static placeholder(): Candidate {
    return new Candidate({
      id: 0,
      rev: -2,

      uuid: "",
      gender: "U",
      jobTitle: null,
      jobTitleIds: [],
      desiredJobTitle: null,
      desiredJobTitleIds: [],
      region: null,
      regionIds: [],
      functionArea: null,
      functionAreaIds: [],
      industry: null,
      industryIds: [],
      educationLevelId: null,
      workLevelId: null,
      info: null,
      personalInfo: null,
      typeOfPerson: null,
      competences: null,
      motivation: null,
      discStyle: null,
      functionLevel: null,
      salaryIndication: null,
      availability: null,
      drivingLicence: true,
      ppId: 0,

      // Private fields
      firstName: null,
      infix: null,
      surname: null,
      street: null,
      houseNumber: null,
      zipCode: null,
      city: null,
      phone: null,
      email: null,
      vimeoLink: null,
      age: null,
      timestampModified: null,

      ownerId: 0,
      ownerName: null,
      status: "D"
    });
  }

  /**
   * Clones a candidate and applies given properties in the data parameter
   *
   * @param data
   */
  override clone(data?: any): Candidate {
    data = data ? data : {};

    return new Candidate({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      uuid: Object.hasOwn(data, "uuid") ? data.uuid : this.uuid,//
      gender: Object.hasOwn(data, "gender") ? data.gender : this.gender,//

      jobTitle: Object.hasOwn(data, "jobTitleIds") ? data.jobTitle : this.jobTitle,//
      jobTitleIds: Object.hasOwn(data, "jobTitleIds") ? data.jobTitleIds : this.jobTitleIds,//

      desiredJobTitle: Object.hasOwn(data, "desiredJobTitle") ? data.desiredJobTitle : this.desiredJobTitle,//
      desiredJobTitleIds: Object.hasOwn(data, "desiredJobTitleIds") ? data.desiredJobTitleIds : this.desiredJobTitleIds,//

      region: Object.hasOwn(data, "region") ? data.region : this.region,//
      regionIds: Object.hasOwn(data, "regionIds") ? data.regionIds : this.regionIds,//
      functionArea: Object.hasOwn(data, "functionArea") ? data.functionArea : this.functionArea,//
      functionAreaIds: Object.hasOwn(data, "functionAreaIds") ? data.functionAreaIds : this.functionAreaIds,//
      educationLevelId: Object.hasOwn(data, "educationLevelId") ? data.educationLevelId : this.educationLevelId,//
      educationLevel: Object.hasOwn(data, "educationLevel") ? data.educationLevel : this.educationLevel,//
      workLevelId: Object.hasOwn(data, "workLevelId") ? data.workLevelId : this.workLevelId,//
      industry: Object.hasOwn(data, "industryIds") ? data.industry : this.industry,//
      industryIds: Object.hasOwn(data, "industryIds") ? data.industryIds : this.industryIds,//
      info: Object.hasOwn(data, "info") ? data.info : this.info,//

      personalInfo: Object.hasOwn(data, "personalInfo") ? data.personalInfo : this.personalInfo,
      typeOfPerson: Object.hasOwn(data, "typeOfPerson") ? data.typeOfPerson : this.typeOfPerson,
      competences: Object.hasOwn(data, "competences") ? data.competences : this.competences,
      motivation: Object.hasOwn(data, "motivation") ? data.motivation : this.motivation,
      discStyle: Object.hasOwn(data, "discStyle") ? data.discStyle : this.discStyle,
      functionLevel: Object.hasOwn(data, "functionLevel") ? data.functionLevel : this.functionLevel,

      salaryIndication: Object.hasOwn(data, "salaryIndication") ? data.salaryIndication : this.salaryIndication,
      availability: Object.hasOwn(data, "availability") ? data.availability : this.availability,
      drivingLicence: Object.hasOwn(data, "drivingLicence") ? data.drivingLicence : this.drivingLicence,
      age: Object.hasOwn(data, "age") ? data.age : this.age,//
      ppId: Object.hasOwn(data, "ppId") ? data.ppId : this.ppId,//

      firstName: Object.hasOwn(data, "firstName") ? data.firstName : this.firstName,//
      infix: Object.hasOwn(data, "infix") ? data.infix : this.infix,//
      surname: Object.hasOwn(data, "surname") ? data.surname : this.surname,//
      street: Object.hasOwn(data, "street") ? data.street : this.street,//
      houseNumber: Object.hasOwn(data, "houseNumber") ? data.houseNumber : this.houseNumber,//
      zipCode: Object.hasOwn(data, "zipCode") ? data.zipCode : this.zipCode,//
      city: Object.hasOwn(data, "city") ? data.city : this.city,//
      phone: Object.hasOwn(data, "phone") ? data.phone : this.phone,//
      email: Object.hasOwn(data, "email") ? data.email : this.email,//
      vimeoLink: Object.hasOwn(data, "vimeoLink") ? data.vimeoLink : this.vimeoLink,//
      timestampModified: Object.hasOwn(data, "timestampModified") ? data.timestampModified : this.timestampModified,//

      ownerId: Object.hasOwn(data, "ownerId") ? data.ownerId : this.ownerId,//
      ownerName: Object.hasOwn(data, "ownerName") ? data.ownerName : this.ownerName,//
      status: Object.hasOwn(data, "status") ? data.status : this.status,//
    } as CandidateData);
  }

  override get displayName(): string {
      const v:string[] = [];
      if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
      if (this.infix != null && this.infix !== "") v.push(this.infix);
      if (this.surname != null) v.push(this.surname);
      return v.join(" ");
  };

  get fullName(): string {
      const v:string[] = [];
      if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
      if (this.infix != null && this.infix !== "") v.push(this.infix);
      if (this.surname != null) v.push(this.surname);
      return v.join(" ");
  };

  get surnameFirst(): string {
      const v:string[] = [];
      if (this.surname != null) v.push(this.surname);
      if (this.firstName != null && this.firstName !== "") {
        v.push(", ");
        v.push(this.firstName);
      }
      if (this.infix != null && this.infix !== "") v.push(this.infix);
      return v.join(" ");
  };

}
