import {
  BaseModel, DataModel, DataModelId, DataModelMap, NullableString
} from "../../../../lib/store/model/dataModel";

export type ProjectStore = DataModelMap<ProjectModel>;

export const initialProjectStore: ProjectStore = new Map<DataModelId, ProjectModel>();

export interface ProjectModel extends DataModel {
	name: NullableString;
}

export class Project extends BaseModel implements ProjectModel {

	readonly name: NullableString;

	constructor(data: ProjectModel) {
		super(data.id, data.rev);
		this.name = data.name;
	}

	/**
	 * Clones a project and applies given properties in the data parameter
	 *
	 * @param data
	 */
	clone(data?: any): Project {
		return new Project({
			id: this.id, //
			rev: data.hasOwnProperty("rev") ? data.rev : this.rev, //
			name: data.hasOwnProperty("name") ? data.name : this.name //
		} as ProjectModel);
	}

	get displayName(): string {
		return this.name ? this.name : "";
	};
}
