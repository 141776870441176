import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { PropeldSearchInputModule } from "../../../lib/component/mat-search-input/module";
import { AccountComponentModule } from "../account/module";
import { CardActionsModule } from "../card-actions/module";
import { AppHeaderComponent } from "./component";

@NgModule({
	declarations: [
		AppHeaderComponent
	],

	exports: [
    AppHeaderComponent
	],

  imports: [
    RouterModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,

    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    AccountComponentModule,
    PropeldSearchInputModule,
    MatInputModule,
    CardActionsModule
  ]
})
export class AppHeaderModule {
}
