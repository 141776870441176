import { Component, HostListener, OnInit, signal, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ComponentCanDeactivate } from "../../../../lib/component/pending-changes-guard/component";
import { DataModelId } from "../../../../lib/store/model/dataModel";
import { CompanyDetailComponent } from "../../../component/company/detail-component/component";
import { Company } from "../../../core/model/company/model";

@Component({
	selector: "company-detail-page", templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/styles.scss"]
})
export class CompanyDetailPageComponent implements OnInit , ComponentCanDeactivate {


  @ViewChild("companyDetailCard") companyDetailCard: CompanyDetailComponent | undefined;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if(this.companyDetailCard == null) return true;
    return !this.companyDetailCard.form.dirty;
  }

  modelId$ = new BehaviorSubject<DataModelId>(0);
  model$ = signal<Company>(Company.placeholder());
  companyId$ = signal<DataModelId>(0);

	constructor(private route: ActivatedRoute) {
	}

  ngOnInit() {
    this.route.paramMap
      .subscribe(params => {
        const id = Number(params.get('id'));
        this.modelId$.next(id);
      });
  }

  onModelLoaded(company: Company) {
    this.model$.set(company);
  }
}
