import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { Action } from "../../../../lib/core/event-bus/action";
import { DataModelMap } from "../../../../lib/store/model/dataModel";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { Reducer } from "../../../../lib/store/reducer/reducer";
import { Account, AccountModel, initialAccountStore } from "./model";

export const ACCOUNT_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("AccountReducer");

export function accountReducerProvider() {
  return new AccountReducer("account", initialAccountStore, (data: AccountModel) => {
    if(data == null) return Account.placeholder();
		return new Account(data);
	}).reducer;
}

export class AccountReducer extends Reducer<Account> {

  constructor(
    entity: string,
    initialValue: DataModelMap<Account>,
    creatorFn: (data: Account) => Account
  ) {
    super(entity, initialValue, creatorFn)
  }

  override onReceived(draft: DataModelMap<Account>, action: Action<{ items: Account[] }>) {
    const items = action.data.items;
    if(items.length > 0) {
      draft.clear();
      const data = items[0] as Account;
      draft.set(items[0].id, this.creatorFn(data));
    }
  }
}

@NgModule({
	imports: [
		StoreModule.forFeature("account", ACCOUNT_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("account"),

	], providers: [
		{
			provide: ACCOUNT_REDUCER_TOKEN,
			useFactory: accountReducerProvider
		}
	]
})
export class AccountCoreModule {

}
