<mat-drawer-container class="layout-container mat-typography" autosize (backdropClick)="toggle(false)">
  <mat-drawer
    #sidenav disableClose [mode]="isMobile() ? 'over' : 'side'" [opened]="appState.isMenuOpen$()"

    [@openCloseSidenav]="appState.isMenuOpen$() ? 'open' : 'closed'"
  >
    <div class="inner-container">

      <mat-toolbar class="sidebar-toolbar">
        @if (appState.isMenuOpen$()) {
<!--          @if (isMobile()) {-->
            <button #sidenavButton mat-icon-button class="close-button" (click)="toggle(undefined)">
              <mat-icon>menu_open</mat-icon>
            </button>
<!--          } @else {-->
            <span class="app-title">Talent Portal Nederland</span>
<!--          }-->
        }
      </mat-toolbar>

      <mat-nav-list>

        <mat-divider></mat-divider>

        <div class="menu-items">
          @if(account$.hasPermission$(DASHBOARD_VIEW_PERMISSION) | async) {
            <mat-list-item [routerLink]="['/dashboard']" [disableRipple]="true"
                           (click)="clear('candidateContactListSource');toggle(false)">
              <mat-icon matListItemIcon>dashboard</mat-icon>
              <h3 matListItemTitle>Dashboard</h3>
            </mat-list-item>
          }

          @if(account$.hasPermission$(CANDIDATE_VIEW_ALL_PUBLISHED) | async) {
            <mat-list-item role="list"
              [routerLink]="['/candidate']" [disableRipple]="true" (click)="clear('candidateListSource');toggle(false)">
              <mat-icon matListItemIcon>face</mat-icon>
              <h3 matListItemTitle>Kandidaten</h3>
            </mat-list-item>
          }
        </div>

        @if(account$.hasAnyPermission$([USER_CREATE_PERMISSION,USER_UPDATE_PERMISSION,COMPANY_UPDATE_PERMISSION,EDUCATIONLEVEL_UPDATE_PERMISSION]) | async) {
          <div>
            <h2>BEHEER</h2>

            @if(account$.hasAnyPermission$([USER_CREATE_PERMISSION, USER_UPDATE_PERMISSION]) | async) {
              <mat-list-item
                [routerLink]="['/user']" [disableRipple]="true" (click)="toggle(false)">
                <mat-icon matListItemIcon class="material-icons-outlined">account_circle</mat-icon>
                <h3 matListItemTitle>Gebruikers</h3>
              </mat-list-item>
            }

            @if(account$.hasPermission$(COMPANY_UPDATE_PERMISSION) | async) {
              <div class="menu-items">
                <mat-list-item [routerLink]="['/company']" [disableRipple]="true" (click)="toggle(false)">
                  <mat-icon matListItemIcon class="material-icons-outlined">domain</mat-icon>
                  <h3 matListItemTitle>Bedrijven</h3>
                </mat-list-item>
              </div>
            }

            @if(account$.hasPermission$(JOBTITLE_UPDATE_PERMISSION) | async) {
              <div class="menu-items">
                <mat-list-item [routerLink]="['/job-title']" [disableRipple]="true" (click)="toggle(false)">
                  <mat-icon matListItemIcon class="material-icons-outlined">badge</mat-icon>
                  <h3 matListItemTitle>Functies</h3>
                </mat-list-item>
              </div>
            }

            @if(account$.hasPermission$(EDUCATIONLEVEL_UPDATE_PERMISSION) | async) {
              <div class="menu-items">
                <mat-list-item [routerLink]="['/education-level']" [disableRipple]="true" (click)="toggle(false)">
                  <mat-icon matListItemIcon class="material-icons-outlined">school</mat-icon>
                  <h3 matListItemTitle>Opleidingsniveaus</h3>
                </mat-list-item>
              </div>
            }
          </div>
        }

        <div class="spacer"></div>

        <account-menu-item (menuOpened)="toggle($event)"></account-menu-item>
      </mat-nav-list>

      <div
        class="sidebar-background" style="background-image: url(assets/images/full-screen-image-3-2.jpeg)"
      ></div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <app-header class="app-mobile-toolbar"

    ></app-header> <!-- (opened)="toggle($event)" (back)="navigateBack()" -->

    <main #content tabindex="-1">
      <div class="page-content mat-typography" [ngClass]="{ 'mobile-page-content': isMobile() }">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </main>

<!--    <div class="page-footer"></div>-->
  </mat-drawer-content>
</mat-drawer-container>
