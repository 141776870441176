
export type DataModelId = number | string;
export type NullableString = string | null;
export type NullableNumber = number | null;
export type DataModelRevision = number;


export interface ModelData {
  id: DataModelId;
  rev: DataModelRevision;
}

export interface DataModel extends ModelData {
  get isNew(): boolean;
  displayName: string;
  clone(data?: any): DataModel;
  get isPlaceholder(): boolean;
}

export interface DataModelMap<T extends DataModel> extends Map<DataModelId, T> {
}

// export function isNew(model: DataModel): boolean {
//   return model.id === 0 || model.id === "";
// }

export abstract class BaseModel implements DataModel {

  abstract clone(data?: any): DataModel;
  abstract displayName: string;

  protected constructor(readonly id: DataModelId, readonly rev: DataModelRevision) {
  }

  get isNew(): boolean {
    return this.id === 0 || this.id === "";
  }

  get isPlaceholder():boolean {
    return this.rev == -1;
  }

  toJson(): object {
    return JSON.parse(JSON.stringify(this));
  }
}
