import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'wrapcomma',
  standalone: true
})
export class WrapCommaPipe implements PipeTransform {

  transform(value: string, limit: number): any {
    if(value && limit && value.length > limit) {
      return value.replaceAll(",", "<br>")
    }
    return value;
  }

}
