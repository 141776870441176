import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { EducationLevel, EducationLevelModel, initialEducationLevelStore } from "./model";

export const EDUCATIONLEVEL_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("EducationLevelReducer");

export function educationLevelReducerProvider() {
	return reducerFactory<EducationLevelModel>("educationLevel", initialEducationLevelStore, (data: EducationLevelModel) => {
		return new EducationLevel(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("educationLevel", EDUCATIONLEVEL_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("educationLevel"),

	], providers: [
		{
			provide: EDUCATIONLEVEL_REDUCER_TOKEN,
			useFactory: educationLevelReducerProvider
		}
	]
})
export class EducationLevelCoreModule {

}
