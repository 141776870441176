import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { PropeldFormDisplayModule } from "../../../lib/component/form-display/module";
import { PropeldSearchInputModule } from "../../../lib/component/mat-search-input/module";
import { PropeldComponentModule } from "../../../lib/component/module";
import { PropeldMatTableSortModule } from "../../../lib/directive/mat-table-source/module";
import { SourceFilterModule } from "../../../lib/source-filter/module";
import { PropeldAddressInputModule } from "../address/module";
import { CardActionsModule } from "../card-actions/module";
import { AutoCompleteComponent } from "../filter/controls/autocomplete/component";
import { CompanyDetailComponent } from "./detail-component/component";
import { CompanyListComponent } from "./list-component/component";

@NgModule({
	declarations: [
		CompanyListComponent,
    CompanyDetailComponent
	],

	exports: [
		CompanyListComponent,
    CompanyDetailComponent
	],

	imports: [
		RouterModule,
		CommonModule,
		// BrowserAnimationsModule,
		RouterModule,
		ReactiveFormsModule,

		PropeldComponentModule,
		PropeldSearchInputModule,
		PropeldMatTableSortModule,

		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatInputModule,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		RouterModule,
		MatMenuModule,
		MatButtonModule,
		MatListModule,
		MatCheckboxModule,
		MatTableModule,
		MatPaginatorModule,
		MatCardModule,
		MatProgressBarModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatAutocompleteModule,
		AutoCompleteComponent,
		SourceFilterModule,
		PropeldFormDisplayModule,
		PropeldAddressInputModule,
		CardActionsModule
	]
})
export class CompanyComponentModule {
}
