import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, debounceTime, Observable, shareReplay, tap } from "rxjs";
import { map } from "rxjs/operators";
import { EventBus } from "../core/event-bus/event-bus";
import { Schema, SCHEMA_PROVIDER } from "../schema/schema";
import { SchemaEntity } from "../schema/schema-entity";
import { SchemaRelation } from "../schema/schema-relation";
import { StateMachine } from "../statemachine/statemachine";
import { DataModel, DataModelId } from "../store/model/dataModel";
import { LinkedSelector } from "./linked-selector";
import { MainSelector } from "./main-selector";
import { SingleMainSelector } from "./single-main-selector";
import { SingleMainSourceImpl } from "./single-main-source-impl";
import { Sort, Source, SourceLoadingStates } from "./source";
import { SourceImpl, SourceStateDefinition, SourceStateEvents } from "./source-impl";
import { SourcePageState } from "./state/source-page-state-store";
import { StaticSourceImpl } from "./static-source-impl";

export type Filter = {
  id?: DataModelId,
  search?: string | null,
  offset?: number,
  limit?: number,
  sort?: Sort[],
  filter?: object | null
}

export interface SourceSelector {
  alias: string;
  setLinked(): void;
  get isLinked(): boolean;
  get level(): number;
  get entityName(): string;
  readonly breadcrumb: string;
  readonly selector: Observable<any>;
}

export class SourceBuilder<T> {

  private schema: Schema = inject(SCHEMA_PROVIDER);
  private eventBus: EventBus = inject(EventBus);
  private store: Store<any> = inject(Store);

  builder(mainEntity: string): SourceBuilderImpl<T> {
    return new SourceBuilderImpl<T>(this.schema, this.eventBus, this.store, mainEntity);
  }
}

class BuildEntity {
  readonly entity: SchemaEntity;
  readonly alias: string;
  readonly relation?: SchemaRelation | undefined;

  constructor(entity: SchemaEntity, alias?: string, relation?: SchemaRelation) {
    if(!alias) {
      this.entity = entity;
      this.alias = this.entity.name;
      this.relation = undefined;
    }
    this.entity = entity;
    this.alias = alias!;
    this.relation = relation;
  }
}

export class SourceBuilderImpl<T> {

  private readonly mainEntity!: SchemaEntity;

  private links: BuildEntity[] = [];

  private linkedEntities: SchemaEntity[] = [];
  private sourceSelectors: SourceSelector[] = [];
  private initialFilterParams: SourcePageState | undefined = undefined;
  private name: string | undefined = undefined;
  private items: T[] = [];
  private modelCreatorFn: (() => DataModel) | null = null;

  constructor(
    private schema: Schema,
    private eventBus: EventBus,
    private store: Store<any>,
    mainEntity: string
  ) {
    if(!mainEntity.startsWith("*")) {
      const schemaEntity = this.schema.getEntity(mainEntity);
      if (schemaEntity == null) throw Error(`Main entity ${mainEntity} not found in schema`);
      this.mainEntity = schemaEntity;
    }
  }

  setName(name: string): SourceBuilderImpl<T> {
    this.name = name;
    return this;
  }

  setInitialFilter(initialFilterParams: SourcePageState) : SourceBuilderImpl<T> {
    this.initialFilterParams = initialFilterParams;
    return this;
  }

  build(): Source<T> {
    const stateMachine = this.createStateMachine();
    const trigger$ = new BehaviorSubject<Filter>(this.createEmptyFilter());
    if(this.items.length > 0) return this.buildStatic(stateMachine, trigger$);

    const observableTrigger$ = this.createObservableTrigger(trigger$, stateMachine);

    const mainSelector = new MainSelector(this.mainEntity.name, this.mainEntity.name, observableTrigger$, this.store, this.eventBus);
    this.sourceSelectors.push(mainSelector);
    this.buildLinkedSelectors();

    const endPoints = this.sourceSelectors.filter(s => !s.isLinked);
    const dataSource$ = this.createDataSource(endPoints, stateMachine);

    if(this.name == null) this.name = "UNKNOWN";
    const source = new SourceImpl(this.mainEntity.name, this.store, this.eventBus, trigger$, dataSource$, stateMachine, this.name );
    if(this.initialFilterParams) {
      source.setState(this.initialFilterParams);
    }
    return source as Source<T>;
  }

  buildForSingle(id: DataModelId): SingleMainSourceImpl<T> {
    const stateMachine = this.createStateMachine();
    const trigger$ = new BehaviorSubject<Filter>({
      id:0
    });
    const observableTrigger$ = this.createObservableTrigger(trigger$, stateMachine);

    const mainSelector = new SingleMainSelector(this.mainEntity.name, this.mainEntity.name, observableTrigger$, this.store, this.eventBus);
    if(this.modelCreatorFn != null) {
      mainSelector.setModelCreatorFn(this.modelCreatorFn);
    }
    this.sourceSelectors.push(mainSelector);
    this.buildLinkedSelectors();

    const endPoints = this.sourceSelectors.filter(s => !s.isLinked);
    const dataSource$ = this.createDataSource(endPoints, stateMachine);

    if(this.name == null) this.name = "UNKNOWN";
    const source = new SingleMainSourceImpl<T>(this.mainEntity.name, this.store, this.eventBus, trigger$, dataSource$, stateMachine, this.name);
    return source;
  }

  setItems(items: T[]): SourceBuilderImpl<T> {
    this.items = items;
    return this;
  }

  setModelCreatorFn(fn: ()=>DataModel) : SourceBuilderImpl<T> {
    this.modelCreatorFn = fn;
    return this;
  }

  /**
   * Builds a static source with the given items
   *
   * @param stateMachine
   * @param trigger$
   */
  buildStatic(
    stateMachine: StateMachine<SourceLoadingStates, SourceStateEvents>,
    trigger$: BehaviorSubject<Filter>
  ): Source<T> {

    if(this.name == null) this.name = "UNKNOWN";
    const source = new StaticSourceImpl(this.store, this.eventBus, trigger$, this.items, stateMachine, this.name);
    return source as Source<T>;
  }

  link(linkEntity: string): SourceBuilderImpl<T> {
    const schemaEntity = this.schema.getEntity(linkEntity);
    if (schemaEntity == null) throw Error(`Linked entity ${linkEntity} not found in schema`);
    this.linkedEntities.push(schemaEntity);
    this.links.push(new BuildEntity(schemaEntity, schemaEntity.name));
    return this;
  }

  linkRelation(linkEntity: string, alias: string, relationName: string): SourceBuilderImpl<T> {
      const relation = this.schema.getRelation(relationName);
      if (!relation) throw Error(`Relation ${linkEntity} not found in schema`);
      this.links.push(new BuildEntity(relation.entity, alias, relation));
      return this;
  }

  buildLinkedSelectors() {
    this.links.forEach((buildEntity, index) => {

      if(!buildEntity.relation) {
        // Find relation with previously used entities based on the name of the entity
        // Relations are always found for children
        const linkedEntity = buildEntity.entity;
        const relation = this.findRelationFor(linkedEntity);
        if (relation == null) throw new Error(`Relation to ${linkedEntity.name} not found`);

        let relatedSelector =
          this.sourceSelectors.find(selector => selector.alias === relation.sourceField.entity.name);

        if (relatedSelector == null) throw new Error(`Related selector for ${linkedEntity.name} not found`);

        const selector = new LinkedSelector(
          buildEntity.alias,
          linkedEntity,
          relation,
          this.store,
          this.eventBus,
          relatedSelector
        );
        this.sourceSelectors.push(selector);

      } else {

        const relation = buildEntity.relation;
        let relatedSelector =
          this.sourceSelectors.find(selector => selector.alias === relation.sourceField.entity.name);

        if (relatedSelector == null) throw new Error(`Related selector for ${buildEntity.alias} not found`);

        // Find the ONE_TO_MANY inverse relation
        const oneToManyRelation = relation.targetField.entity.getRelation("_"+relation.name);
        if (oneToManyRelation == null) throw new Error(`Related ONE TO MANY relation for _${relation.name} not found`);

        const selector = new LinkedSelector(
          buildEntity.alias,
          oneToManyRelation.targetField.entity,
          oneToManyRelation,
          this.store,
          this.eventBus,
          relatedSelector
        );
        this.sourceSelectors.push(selector);

      }
    });
  }

  private findRelationFor(entity: SchemaEntity) {
    let relationFound: SchemaRelation | undefined = undefined;
    for (let [relationName, linkedRelation] of entity.relations) {

      const linkFound = this.sourceSelectors.find(selector =>
        linkedRelation.sourceField.entity.name === selector.entityName
      );
      if (linkFound) {
        relationFound = linkedRelation;
        break;
      }
    }
    return relationFound;
  }

  private createEmptyFilter(): Filter {
    return {
      search: "emptyFilter",
      offset: 0,
      limit: 0,
      sort: [],
      filter: {}
    }
  }

  private createStateMachine(): StateMachine<SourceLoadingStates, SourceStateEvents> {
    return new StateMachine<SourceLoadingStates, SourceStateEvents>(
      SourceLoadingStates.INIT,
      SourceStateEvents.INIT,
      SourceStateDefinition,
      this.name ? this.name : "SOURCE STATEMACHINE "+this.mainEntity.name
    );
  }

  private createObservableTrigger(trigger$: Observable<Filter>, stateMachine: StateMachine<SourceLoadingStates, SourceStateEvents>): Observable<Filter> {
    return trigger$.pipe(
      debounceTime(100),
      tap(filter => {
        stateMachine.on(SourceStateEvents.PARAMS_CHANGED)
      })
    );
  }

  private createDataSource(endPoints: SourceSelector[], stateMachine: StateMachine<SourceLoadingStates, SourceStateEvents>): Observable<any> {

      let totalCount = 1;
      let regex = new RegExp(/\./g);
      const observableSelectors = endPoints.reduce((observables, sourceSelector) => {
        observables.push(sourceSelector.selector);

        let dotCount = sourceSelector.breadcrumb.match(regex)?.length;
        if(dotCount === undefined) dotCount = 0;
        totalCount += dotCount;

        return observables;
      }, [] as Observable<any>[]);

      return combineLatest(observableSelectors)
        .pipe(
          map(data => {
            // If all selectors have loaded, we're done loading
            if (data[0].progress.length >= totalCount) {
                stateMachine.on(SourceStateEvents.LOADED);
            }
            return data[0];
          }),
          shareReplay(1)
        );
    }
 }
