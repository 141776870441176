
<!-- TODO: Require permission stats/get-candidates-per-region -->
<!--@if(accountService.get().hasPermission(STATS_GET_CANDADATES_PER_REGION)) {-->
<!--<div>-->

<!--  <candidates-per-region-chart></candidates-per-region-chart>-->

<!--</div>-->
<!--}-->

<div
  appHeaderReset
  appState
  [title]="'Dashboard'"
  [isSubPage]="false">

  <candidateContact-list-card [searchInHeaderBar]="true"></candidateContact-list-card>
</div>

