import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule, Routes } from "@angular/router";
import { PropeldAppStateModule } from "../../../lib/service/app-state/module";
import { CandidateContactComponentModule } from "../../component/candidate-contact/module";
import { CandidatesPerRegionComponent } from "../../component/chart/region/component";
import { UserComponentModule } from "../../component/user/module";
import { DashboardPageComponent } from "./component";

const routes: Routes = [
  {
    path: "",
    component: DashboardPageComponent
  }
];

@NgModule({
	declarations: [
    DashboardPageComponent
	],

	exports: [
	],

	imports: [
		RouterModule.forChild(routes),
		CommonModule,
		ReactiveFormsModule,

		MatIconModule,
		RouterModule,
		MatMenuModule,
		MatButtonModule,
		MatListModule,
		MatCheckboxModule,
		MatInputModule,
		CandidateContactComponentModule,
		UserComponentModule,
		PropeldAppStateModule,
		CandidatesPerRegionComponent
	]
})
export class DashboardModule {
}

