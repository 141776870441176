import { CollectionViewer } from "@angular/cdk/collections";
import { signal, Signal, WritableSignal } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Store } from "@ngrx/store";
import { BehaviorSubject, distinctUntilChanged, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { EventBus } from "../core/event-bus/event-bus";
import { MachineEvent, StateMachine } from "../statemachine/statemachine";
import { Sort, SortDirection, Source, SourceLoadingStates } from "./source";
import { Filter } from "./source-builder";
import { SourceStateEvents } from "./source-impl";
import { SourcePageState } from "./state/source-page-state-store";

export class StaticSourceImpl<T> implements Source<T> {

  private filterParams: Filter = {
    search: "",
    offset: 0,
    limit: 0,
    sort: [],
    filter: null
  };

  private _requiredFilter: any | null = null;
  private _paginator: MatPaginator | null = null;
  private _sort: MatSort | null = null;
  private _count = signal<number>(0);
  private _pageSize: number = 20;

  model$ = signal({} as T);

  public get pageFilterState$(): Signal<SourcePageState> {
    throw Error("TODO");
  }

  getState(): SourcePageState {
    throw Error("TODO");
  }

  setState(filterState?: SourcePageState): this {
    throw Error("TODO");
  }

  getName(): Readonly<string> {
    throw Error("TODO");
  }

  setName(name: string): this {
    throw Error("TODO");
  }

  get dataSourceAsArray$(): Observable<any[]> {
    return this.dataSource$;
  }

  get singleModel$(): WritableSignal<T> {
    throw Error("Unsupported");
  }

  get loadingStatus$(): Signal<SourceLoadingStates> {
    return this.stateMachine.state;
  }

  get loadingEvent$(): Signal<MachineEvent | undefined> {
    return this.stateMachine.event;
  }

  get status$(): Observable<SourceLoadingStates> {
    return this.stateMachine.state$;
  }

  constructor(
    private store: Store<any>,
    private eventBus: EventBus,
    private trigger$: BehaviorSubject<Filter>,
    private items: readonly T[],
    private stateMachine: StateMachine<SourceLoadingStates, SourceStateEvents>,
    readonly name: string
  ) {
    this.on(SourceStateEvents.READY);
  }

  get count(): Readonly<number> {
    return this._count();
  }

  private set count(value: number) {
    this._count.set(value);
  }

  get count$(): Signal<number> {
    return this._count;
  }

  getPageSize(): number {
    throw new Error("Method not implemented.");
  }

  setPageSize(pageSize: number): this {
    // throw new Error("Method not implemented.");
    return this;
  }

  private on(event: SourceStateEvents) {
    this.stateMachine.on(event);
  }

  get dataSource$(): Observable<any> {
    return of(this.items);
  }

  connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    return this.dataSource$;//.pipe(map(data => (data as any).items));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
    this._paginator.page.subscribe({
      next: (pageEvent: PageEvent) => {
        this.filterParams.offset = pageEvent.pageIndex * pageEvent.pageSize;
        this.filterParams.limit = pageEvent.pageSize;
        this.refresh(this.filterParams);
      },
      error: (error: Error) => {
        console.log(error.message);
      }
    });

    this.dataSource$.pipe(map(data => (data as any).count), distinctUntilChanged())
      .subscribe(length => {
        if (this._paginator) this._paginator.length = length;
        this._count = length;
      });
  }

  addSort(sort: Sort): this {
    throw Error("TODO");
  }

  adjustSort(fieldName: string, direction: SortDirection): this {
    throw Error("TODO");
  }

  clone(filterHandlerAddress?: string): Source<T> {
    throw Error("TODO");
  }

  countChanged(): Observable<number> {
    throw Error("TODO");
  }

  dataChanged(): Observable<T> {
    throw Error("TODO");
  }

  getFilter(): Readonly<Filter> {
    throw Error("TODO");
  }

  setFilter(filter: Filter): this {
    this.filterParams.filter = filter;
    this.refresh(this.filterParams);
    return this;
  }

  setRequiredFilter(requiredFilter: any): this {
    this._requiredFilter = requiredFilter;
    return this;
  }

  getLimit(): Readonly<number> {
    throw Error("TODO");
  }

  setLimit(limit: number): this {
    this.filterParams.limit = limit;
    this.refresh(this.filterParams);
    return this;
  }

  getOffset(): Readonly<number> {
    throw Error("TODO");
  }

  setOffset(offset: number): this {
    this.filterParams.offset = offset;
    this.refresh(this.filterParams);
    return this;
  }

  getParams(): Readonly<unknown> {
    throw Error("TODO");
  }

  getSearch(): Readonly<string> {
    throw Error("TODO");
  }

  setSearch(query: string | null): this {
    this.filterParams.search = query ? query : "";
    this.setOffset(0);
    this.refresh(this.filterParams);
    return this;
  }

  getSort(): Readonly<Sort[]> {
    throw Error("TODO");
  }

  refresh(filter?: Filter): void {
    if (filter) {
      this.trigger$.next(filter);
      return;
    }
    this.trigger$.next(this._requiredFilter ?? {});
  }

  removeOperator(fieldName: string): this {
    throw Error("TODO");
  }

  setParams(params: unknown): this {
    throw Error("TODO");
  }

  setSort(sort: Sort[]): this {
    this.filterParams.sort = sort;
    this.refresh(this.filterParams);
    return this;
  }

  sourceUpdated(): Observable<void> {
    throw Error("TODO");
  }

  /**
   * Fetch the items with the given ids based on the main entity name.
   *
   *
   *
   * @param ids
   */
  getIds(ids: number[]): Observable<T[]> {
    const list = [] as T[];
    ids.forEach(id => {
      const item = this.items.find(item => (item as any).id === id);
      if (item !== undefined) {
        list.push(item);
      }
    });
    return of(list);
  }
}
