import { Injectable, InjectionToken } from "@angular/core";
import { SchemaEntity } from "./schema-entity";
import { SchemaRelation } from "./schema-relation";

export const SCHEMA_PROVIDER =
	new InjectionToken<Schema>("Schema");


@Injectable({
	providedIn: "root"
})

export class Schema {

	private readonly entities = new Map<string, SchemaEntity>;

	getEntity(name: string): SchemaEntity | undefined {
		return this.entities.get(name);
	}

	createEntity(name: string) : SchemaEntity{
		const entity = new SchemaEntity(this, name);
		this.entities.set(name, entity);
		return entity;
	}

  getRelation(name: string): SchemaRelation | undefined {
    for (let [key, value] of this.entities) {
      const relation = value.getRelation(name);
      if(relation !== undefined) return relation;
    }
    return undefined;
  }
}
