import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData, NullableString
} from "../../../../lib/store/model/dataModel";

export type CompanyStore = DataModelMap<CompanyModel>;

export const initialCompanyStore: CompanyStore = new Map<DataModelId, CompanyModel>();

export interface CompanyData extends ModelData {
	name: string;
  v_street: NullableString;
  v_houseNumber: NullableString;
  "v_zipCode": NullableString;
  v_city: string;
  p_street: NullableString;
  "p_houseNumber": NullableString;
  "p_zipCode": NullableString;
  p_city: NullableString;
  phone: NullableString;
  email: NullableString;
  site: NullableString;
  "cocNo": NullableString;
  "taxNo": NullableString;
}

export interface CompanyModel extends CompanyData, DataModel {

}

export class Company extends BaseModel implements CompanyModel {

	readonly name: string;
	readonly v_street: NullableString;
  readonly v_houseNumber: NullableString;
  readonly v_zipCode: NullableString;
  readonly v_city: string;
  readonly p_street: NullableString;
  readonly p_houseNumber: NullableString;
  readonly p_zipCode: NullableString;
  readonly p_city: NullableString;
  readonly phone: NullableString;
  readonly email: NullableString;
  readonly site: NullableString;
  readonly cocNo: NullableString;
  readonly taxNo: NullableString;


	constructor(data: CompanyData) {
    super(data.id, data.rev);
		this.name = data.name;
    this.v_street = data.v_street;
    this.v_houseNumber = data.v_houseNumber;
    this.v_zipCode = data.v_zipCode;
    this.v_city = data.v_city;
    this.p_street = data.p_street;
    this.p_houseNumber = data.p_houseNumber;
    this.p_zipCode = data.p_zipCode;
    this.p_city = data.p_city;
    this.phone = data.phone;
    this.email = data.email;
    this.site = data.site;
    this.cocNo = data.cocNo;
    this.taxNo = data.taxNo;

	}

  static placeholder(): Company {
    return new Company({
      id: 0,
      rev: -1,
      name: "",
      v_street: "",
      v_houseNumber: "",
      v_zipCode: "",
      v_city: "",
      p_street: "",
      p_houseNumber: "",
      p_zipCode: "",
      p_city: "",
      phone: "",
      email: "",
      site: "",
      cocNo: "",
      taxNo: ""
    });
  }

	/**
	 * Clones a company and applies given properties in the data parameter
	 *
	 * @param data
	 */
	clone(data?: any): Company {
      data = data ? data : {};

      return new Company({
        id: this.id, //
        rev: Object.hasOwn(data,"rev") ? data.rev : this.rev, //
        name: Object.hasOwn(data,"name") ? data.name : this.name, //
        v_street: Object.hasOwn(data,"v_street") ? data.v_street : this.v_street, //
        v_houseNumber: Object.hasOwn(data,"v_houseNumber") ? data.v_houseNumber : this.v_houseNumber, //
        v_zipCode: Object.hasOwn(data,"v_zipCode") ? data.v_zipCode : this.v_zipCode, //
        v_city: Object.hasOwn(data,"v_city") ? data.v_city : this.v_city, //
        p_street: Object.hasOwn(data,"p_street") ? data.p_street : this.p_street, //
        p_houseNumber: Object.hasOwn(data,"p_houseNumber") ? data.p_houseNumber : this.p_houseNumber, //
        p_zipCode: Object.hasOwn(data,"p_zipCode") ? data.p_zipCode : this.p_zipCode, //
        p_city: Object.hasOwn(data,"p_city") ? data.p_city : this.p_city, //
        phone: Object.hasOwn(data,"phone") ? data.phone : this.phone, //
        email: Object.hasOwn(data,"email") ? data.email : this.email, //
        site: Object.hasOwn(data,"site") ? data.site : this.site, //
        cocNo: Object.hasOwn(data,"cocNo") ? data.cocNo : this.cocNo, //
        taxNo: Object.hasOwn(data,"taxNo") ? data.taxNo : this.taxNo //

      } as CompanyModel);
	}

	get displayName(): string {
		return this.name ? this.name : "";
	};
}
