<mat-toolbar class="app-toolbar">
    @if(appState.isMobile$()) {

      <div class="left">
      @if(!appHeader$().searchExpanded?.()) {
          @if(appHeader$().isSubPage?.()) {
            <button mat-icon-button class="back-button" (click)="navigateBack()">
              <mat-icon>arrow_back</mat-icon>
            </button>
          } @else {
            <button mat-icon-button class="menu-close-button" (click)="toggleMenu()">
              <mat-icon>menu</mat-icon>
            </button>
          }
      } @else {
        <button mat-icon-button class="back-button" (click)="toggleSearch()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      }
        </div>

      <div class="center">
        @if(!appHeader$().searchExpanded?.()) {
          <div>
            <div>{{ appHeader$().title?.() }}</div>
          </div>
        }

        @if(appHeader$().searchExpanded?.()) {
          <mat-search-input [appearance]="'fill'" [subscriptSizing]="'dynamic'" [iconPrefix]="false"
                            [source]="appState.searchSource$()"></mat-search-input>
        }
      </div>

      <div class="right">
        @if(!appHeader$().searchExpanded?.()) {
            <top-card-actions [location]="appBarLocation"></top-card-actions>
          }
      </div>
    } @else if(!isMenuOpen$()) {
      <button mat-icon-button class="menu-close-button" (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Talent Portal Nederland</span>
    }
</mat-toolbar>

<mat-menu #menu="matMenu">
  @for (item of menuItems$(); track item) {
    <button mat-menu-item>
      <mat-icon>{{ item.icon }}</mat-icon>
      <span>{{ item.text }}</span>
    </button>
  }
</mat-menu>

