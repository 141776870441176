import { computed, InjectionToken, signal, Signal } from "@angular/core";
import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";

// export const ACCOUNT_PERMISSION_TOKEN = new InjectionToken<ActionReducerMap<any>>("AccountPermission");
export const APP_ACTIONS_TOKEN = new InjectionToken<AppActions>("AppActions");

export interface AccountPermission {
  hasPermission(permission: string): boolean;
}

export class EmptyAccountPermissionService implements AccountPermission {
  hasPermission(permission: string): boolean {
    return false;
  }
}

export interface AppActionItem {
  id: string,
  text?: string,
  icon?: () => string,
  target?: string,
  permission?: string,
  location: AppLocation[],
  condition?: () => boolean,
  tooltip?: () => string
}

export type AppActionItems = AppActionItem[];
export type AppActionsMap = Map<string, Signal<AppActionItem>>;

export const enum AppLocation {
  APPBAR, CARD_ACTION, CARD_MENU_ITEM, CARD_BUTTON, MAIN_MENU
}

const initialState = {
  account: new EmptyAccountPermissionService() as AccountPermission,
  allActions: [] as AppActionItems // ,
  // cardActions: [] as AppActionItems,
  // appBarActions: [] as AppActionItems
};

export interface AppActions {
  setAccount(account: AccountPermission): void;
  action$(location: AppLocation): Signal<AppActionItem[]>;
}

export const AppActionsStore = signalStore(
  {providedIn: "root"},
  withState(initialState),
  withComputed(({allActions, account}) => ({
    cardActions: computed(() => {
      if(account() == null) return [];
      return allActions()
        .filter( (item: AppActionItem) => item.location.includes(AppLocation.CARD_ACTION))
        .filter( (item: AppActionItem) => item.permission == null || account().hasPermission(item.permission))
        .filter( (item: AppActionItem) => item.condition == null || item.condition());
    }),
    appBarActions: computed(() => {
      if(account() == null) return [];
      return allActions().filter((item: AppActionItem) => item.location.includes(AppLocation.APPBAR));
    })
  })),
  withMethods(((store) => ({
    setAccount(account: AccountPermission) {
      patchState(store, { account: account as any});
    },
    setActions(target: string, actions: AppActionItems) {
      patchState(store, state => ({
        allActions: actions
      }));
    },
    action$(location: AppLocation)  {
      if(location == AppLocation.CARD_ACTION) {
        return store.cardActions;
      } else if(location == AppLocation.APPBAR)  {
        return store.appBarActions;
      }
      return signal([] as AppActionItem[]);
    }
  })))
);
//
// // @Injectable({
// //   providedIn: "root"
// // })
// // export class AppActions {
// //
// //   private account = inject(ACCOUNT_PERMISSION_TOKEN);
// //
// //   state$ = signalState<AppActionLocations>({
// //     cardActions: new Map(),
// //     appBarActions: new Map()
// //   });
// //
// //   // readonly scalar = computed( () => this.state$.scalar);
// //   readonly cardActions = computed(() => this.state$.cardActions());
// //   readonly appBarActions = computed(() => this.state$.appBarActions());
// //
// //   // readonly cardActions = signalState<AppActionsMap>(new Map());
// //   // readonly cardMenuActions = signalState<AppActionItems>([]);
// //   // readonly cardButtonActions = signalState<AppActionItems>([]);
// //
// //   private readonly allActions;
// //
// //   private handler = new Subject<string>();
// //
// //   constructor() {
// //     this.allActions = allActions;
// //   }
// //
// //   get handle$(): Observable<string> {
// //     return this.handler as Observable<string>;
// //   };
// //
// //   change(key: string) {
// //     const map = this.state$.cardActions();
// //     const item = map.get(key);
// //     if (item != null) {
// //       const newItem = (Object.assign({}, item, {permission: "yes"}) as any) as AppActionItem;
// //
// //       map.set(key, signal(newItem));
// //       patchState(this.state$, state => ({
// //         cardActions: map
// //       }));
// //     }
// //   }
// //
// //   setActions(target: string, actions: AppActionItems) {
// //     const _cardActions = [] as AppActionItems;
// //     const _appBarActions = [] as AppActionItems;
// //
// //     patchState(this.state$, state => ({
// //       appBarActions: this.filterItems(actions, AppLocation.APPBAR),
// //       cardActions: this.filterItems(actions, AppLocation.CARD_ACTION)
// //     }));
// //     debugger;
// //   }
// //
// //   private filterItems(actions: AppActionItems, appLocation: AppLocation): AppActionsMap {
// //     return actions.filter(item => item.location.includes(appLocation))
// //       .reduce((acc, item) => {
// //         return acc.set(item.id, signal(item));
// //       }, new Map() as AppActionsMap);
// //   }
// // }
// //
// // const allActions = [
// //   {
// //     "candidate/detail-card": [
// //       {
// //         id: "candidate/edit",
// //         icon: "edit",
// //         permission: UPDATE_CANDIDATE,
// //         location: [AppLocation.CARD_ACTION, AppLocation.APPBAR]
// //       }, {
// //         id: "candidate/share",
// //         icon: "share",
// //         permission: SHARE_CANDIDATE,
// //         location: [AppLocation.CARD_ACTION, AppLocation.APPBAR]
// //       }, {
// //         id: "cancel",
// //         icon: "close"
// //       }
// //     ]
// //   }
// // ];
//
// // id: string,
// // text?: string,
// // icon: string,
// // target: string,
// // permission: string,
// // location: AppLocation[]
