import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, tap } from "rxjs";
import { Action } from "../../core/event-bus/action";
import { CrudAction } from "../../core/event-bus/crud-action";
import { EventBus } from "../../core/event-bus/event-bus";
import { CountResultData, FilterData, FilterResultData } from "../../core/event-bus/filter";
import { MessageConsumer } from "../../core/event-bus/message-consumer";
import { Message } from "../../core/event-bus/message/message";
import { LoggerLocator } from "../../core/logger/locator";
import { DataModelId } from "../model/dataModel";

@Injectable({
	providedIn: "root"
})
export class StoreFilterHandler {

	private logger = LoggerLocator.getLogger("StoreFilterHandler")();
	private consumer: MessageConsumer<Action<any>> | null = null;

	private readonly eventBus: EventBus = inject(EventBus);
	private readonly store: Store<unknown> = inject(Store);

	constructor() {
		this.addFilterHandler();
	}

	protected addFilterHandler() {
		const address = "store/filter";
		this.consumer = this.eventBus.consumer(address);

		this.consumer.subscribe({
			next: (message: Message<any>) => {
				this.executeFilter(message);
			}, error: (error) => {
				throw new Error(error);
			}
		});
	}

	protected executeFilter(message: Message<Action<any>>) {

		const data: any = message.body.data as FilterData;
		const entity = message.body.type.split("/")[0];
		//data.filter = data.filter.build();

		const countAction = new CrudAction(`${entity}/count`, 0, 0, data);
		const filterAction = new CrudAction(`${entity}/filter`, 0, 0, data);

		combineLatest([
			this.eventBus.request(countAction.type, countAction).asObservable,
			this.eventBus.request(filterAction.type, filterAction).asObservable
		]).pipe(tap(([countResult, filterResult]) => {
			this.eventBus.send("store/sync", filterResult.body);
		}))
			.subscribe({
				next: ([countResult, filterResult]) => {
					const countData = countResult.body.data as CountResultData;
					const filterData = filterResult.body.data as FilterResultData;

					const ids = filterData.items.map(item => item.id) as DataModelId[];
					const totalCount = countResult.body.data.count;
					message.reply(new Action(`store/filtered`, {
						ids: ids, count: totalCount
					}));
				}, error: (error) => {
					throw Error(error.message);
				}
			});
	}

	public removeHandler() {
		if (this.consumer) {
			this.consumer.unregister();
			this.consumer = null;
		}
	}

	public removeHandlers() {
		this.removeHandler();
	}
}
