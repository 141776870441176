import { FieldType } from "./schema-definitions";
import { SchemaEntity } from "./schema-entity";

export class SchemaField {


	// These are ignored for now
	//
	// private nullable: boolean,
	// private length: number,
	// private default: string,
	// private cast: SchemaFieldType | null
	// private jsonStrategy?: "MERGE" | "REPLACE_ALL"

	constructor(
		readonly entity: SchemaEntity,
		readonly name: string,
		private readonly type: FieldType) {
	}
}
