import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PaginatorDirective } from "./paginator/paginator";

@NgModule({
  declarations: [
    PaginatorDirective
  ],

  exports: [
    PaginatorDirective
  ],

  imports: [
    CommonModule
  ]
})
export class PropeldComponentModule {
}
