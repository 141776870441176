import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { initialFunctionAreaStore, FunctionArea, FunctionAreaModel } from "./model";

export const FUNCTION_AREA_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("FunctionAreaReducer");

export function functionAreaReducerProvider() {
	return reducerFactory<FunctionAreaModel>("functionArea", initialFunctionAreaStore, (data: FunctionAreaModel) => {
		return new FunctionArea(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("functionArea", FUNCTION_AREA_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("functionArea"),

	], providers: [
		{
			provide: FUNCTION_AREA_REDUCER_TOKEN,
			useFactory: functionAreaReducerProvider
		}
	]
})
export class FunctionAreaCoreModule {

}
