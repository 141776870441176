import { AfterViewInit, Component, DestroyRef, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatestWith } from "rxjs";
import { first } from "rxjs/operators";
import { EventBus } from "../../../lib/core/event-bus/event-bus";
import { Socket, SocketStates } from "../../../lib/core/socket/socket";
import { SocketConnector } from "../../../lib/core/socket/socket-connector";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "login",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class LoginComponent implements AfterViewInit {

  isMenuOpened = signal<Boolean>(false);
  isInvalidLogin = signal<Boolean>(false);

  emailFormControl = new FormControl("", [Validators.required, Validators.email]);
  passwordFormControl = new FormControl("", [Validators.required]);

  form = new FormGroup({
    email: this.emailFormControl,
    password: this.passwordFormControl,
    rememberMe: new FormControl(false, [])
  });

  matcher = new MyErrorStateMatcher();

  private eventBus: EventBus = inject(EventBus);
  private store: Store<any> = inject(Store);
  private socket: Socket = inject(Socket);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private socketConnector: SocketConnector = inject(SocketConnector);
  private destroyRef = inject(DestroyRef);

  constructor() {
  }

  login() {
    if (!this.form.valid) {
      return;
    }

    this.socketConnector.setReconnectOnClose(false);
    if (this.socket.status === SocketStates.OPEN) {
      this.socket.close();
    }

    this.eventBus.requestAction("user/login", this.form.value).subscribe({
      next: (message) => {
        if((message.body as any).failureCode != undefined) {
          this.isInvalidLogin.set(true);
          return;
        }

        const action = message.body;
        this.store.dispatch(action);

        this.socketConnector.setReconnectOnClose(true);
        this.socket.open();

        let path;
        this.route?.params?.pipe(first()).subscribe({
          next: (params) => {
            path = params["url"];
          }
        });

        this.router.navigateByUrl(path ?? "/dashboard").catch((error) => alert(error.message));

      },
      error: (error) => {
        this.isInvalidLogin.set(true);
      }
    });

    if (this.form.invalid) {
      return;
    }
  }

  ngAfterViewInit() {
    this.emailFormControl.valueChanges.pipe(combineLatestWith(this.passwordFormControl.valueChanges),
      takeUntilDestroyed(this.destroyRef)
    )
      .subscribe({
        next: ([email, password]) => {
          this.isInvalidLogin.set(false);
        }
      });
  }

  toggleMenu() {
    this.isMenuOpened.set(!this.isMenuOpened());
    if (this.isMenuOpened()) {

    }
  }
}
