import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { initialUserStore, User, UserData, UserModel } from "./model";

export const USER_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("UserReducer");
// export const USER_STORE_MODULE_TOKEN = new InjectionToken<ActionReducerMap<any>>("UserStoreModuleToken");

export function userReducerProvider() {
	return reducerFactory<UserModel>("user", initialUserStore, (data: UserData) => {
		return new User(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("user", USER_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("user")
	], providers: [
		{
			provide: USER_REDUCER_TOKEN,
			useFactory: userReducerProvider
		}
	]
})
export class UserCoreModule {

}
