import { SchemaEntity } from "./schema-entity";
import { SchemaField } from "./schema-field";

export enum SchemaRelationType {
	ONE_TO_MANY = "ONE_TO_MANY", MANY_TO_ONE = "MANY_TO_ONE", ONE_TO_ONE = "ONE_TO_ONE"
}

export class SchemaRelation {

	constructor(
		readonly entity: SchemaEntity,
		readonly name: string,
		readonly relationType: SchemaRelationType,
		readonly sourceField: SchemaField,
		readonly targetField: SchemaField
	) {
	}
}
