@if (displayOnly) {
  <div style="display: flex;">
    @if (selectedIcon() != undefined) {
      <mat-icon matPrefix class="{{className}}-value-{{selectedId()}}">square</mat-icon>
    }
    {{ displayFn(model$()) }}
  </div>
} @else {

  <mat-form-field [appearance]="'outline'">

    @if(selectedIcon() != undefined) {
      <mat-icon matPrefix class="{{className}}-value-{{selectedId()}}">square</mat-icon>
    }

    <input
      type="text" matInput [formControl]="control" [matAutocomplete]="auto" [placeholder]="placeholder"
    >

    @if (this.control.value) {
      <button mat-icon-button matSuffix (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    } @else {
      <mat-icon matIconSuffix>expand_more</mat-icon>
    }
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (option of source?.dataSourceAsArray$ | async; track option) {
        <mat-option [value]="option">
          @if(option.icon) {
            <div class="{{className}}-value-{{option.id}}">
              <mat-icon  class="{{className}}-value-{{option.id}}">square</mat-icon>
              <!--              <span class="material-icons">square</span>-->
              <span>{{ displayFn(option) }}</span>
            </div>
          } @else {
            {{ displayFn(option) }}
          }
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}
