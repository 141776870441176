import { AfterViewInit, Directive, inject, Input, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { ReplaySubject } from "rxjs";
import { Source } from "../../source/source";

@Directive({
  selector: "mat-paginator[dataSource]"
})
export class PaginatorDirective implements AfterViewInit, OnDestroy {

  private dataSource: Source<any> | undefined;
  private destroyed = new ReplaySubject<boolean>(1);

  readonly paginator = inject(MatPaginator, { self: true });

  @Input("dataSource") set source(source: Source<any>) {
    // source.paginator = this.paginator;
    this.dataSource = source;
  };

  ngAfterViewInit(): void {
    if(this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnDestroy(): void {
      this.dataSource = undefined;
      this.destroyed.next(true);
      this.destroyed.complete();
  }
}
