import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SnackbarComponent } from "./inner/component";

@NgModule({
	imports: [CommonModule, MatSnackBarModule, MatIconModule, MatButtonModule],
	declarations: [SnackbarComponent],
})
export class PropeldSnackbarModule {
}
