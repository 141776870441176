import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltip } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { PropeldSearchInputModule } from "../../../lib/component/mat-search-input/module";
import { AccountComponentModule } from "../account/module";
import { TopCardActionsComponent } from "./top/component";

@NgModule({
	declarations: [
		TopCardActionsComponent
	],

	exports: [
    TopCardActionsComponent
	],

	imports: [
		RouterModule,
		CommonModule,
		RouterModule,

		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		RouterModule,
		MatMenuModule,
		MatButtonModule,
		MatListModule,
		MatCheckboxModule,
		PropeldSearchInputModule,
		MatInputModule,
		MatTooltip
	]
})
export class CardActionsModule {
}
