import { Component, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Action } from "../../../../lib/core/event-bus/action";
import { CrudAction } from "../../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../../lib/core/event-bus/event-bus";
import { Message } from "../../../../lib/core/event-bus/message/message";
import { fatalError } from "../../../../lib/service/snackbar/service/error-filter";
import { SnackbarService } from "../../../../lib/service/snackbar/service/snackbar.service";

@Component({
	selector: "user-list-page", templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/styles.scss"]
})
export class UserListPageComponent {


	private store = inject(Store);
	private eventBus = inject(EventBus);
  	private snackbar = inject(SnackbarService);

	constructor() {
		this.store.subscribe({
			next: (store) => {
				console.log("Store updated: ",store);
			}
		});
	}

	clear() {
		this.store.dispatch(new Action("store/logout", {}));
	}

	dispatch() {
		this.store.dispatch(new Action("project/received", {
			items: [
				{
					id: 1, rev: 1, name: "Project 1"
				}
			]
		}));
	}

	update() {
		this.store.dispatch(new CrudAction("project/updated", 1, 1, {
			rev: 2, name: "Project 12345"
		}));
	}

	updated() {
		this.eventBus.send("project/updated",
			new CrudAction("project/updated", 1, 1, {
			rev: 2, name: "Project 12345"
		}));
	}


	delete() {
		this.store.dispatch(new CrudAction("project/deleted", 1, 2, {

		}));
	}

	filter() {
		const action = new Action("user/filter", { });

		this.eventBus.request("store/filter", action)
			.subscribe({
				next: (message: Message<Action<any>>) => {
					console.log("store/filtered: "+message);
				},
        		error: (error: Error) => fatalError(this.snackbar, error)
			});
	}
}
