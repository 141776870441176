import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { FormInputComponent } from "./component";

@NgModule({
	declarations: [
    FormInputComponent
	],
	exports: [
    FormInputComponent
	],

	imports: [
		MatInputModule, MatIconModule, ReactiveFormsModule, MatButtonModule
	]
})
export class PropeldFormInputModule {
}
