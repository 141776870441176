<div class="section-content">
  <div class="mat-typography">
    <div class="inner">

      @switch (pageDisplayType()) {

        @case ("info") {
<!--          <div class="row action">-->

<!--            <div class="left">-->
<!--              @if(tokenControl.value == null || tokenControl.value == '') {-->
<!--                <button mat-flat-button [routerLink]="['../']">-->
<!--                  <mat-icon>-->
<!--                    <span class="material-symbols-outlined">chevron_left</span>-->
<!--                  </mat-icon>-->
<!--                  Terug naar overzicht-->
<!--                </button>-->
<!--              }-->
<!--            </div>-->

<!--            <div class="right">-->
<!--              <button mat-raised-button color="accent" (click)="togglePageDisplayType('form')">-->
<!--                @if(additionalDataDisclosed()) {-->
<!--                  Ik ben geïnteresseerd!-->
<!--                } @else {-->
<!--                  Vertel mij meer-->
<!--                }-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
        }
        @case ("form-filled") {
          <div class="row action">
            <p>Dank! We nemen zo snel mogelijk contact met u op</p>
          </div>
        }
        @case ("form") {

          <p class="form-info mat-body-1">Vul uw gegevens in en we nemen zo snel mogelijk contact met u op over
            deze kandidaat.</p>

          <form
            id="form"
            [formGroup]="form"
            class="detail-form"
            (ngSubmit)="onOk()"
            [hidden]="pageDisplayType() !== 'form'"
          >

<!--            <div class="row" style="display: none;">-->
<!--              <mat-form-field class="uuid-input" [appearance]="'outline'">-->
<!--                <mat-label>UUID</mat-label>-->
<!--                <input id="uuid" matInput formControlName="uuid">-->
<!--              </mat-form-field>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <mat-form-field class="token-input" [appearance]="'outline'">-->
<!--                <mat-label>token</mat-label>-->
<!--                <input id="token" matInput formControlName="token">-->
<!--              </mat-form-field>-->
<!--            </div>-->

            <div class="row">
              <mat-form-field class="name-input" [appearance]="'outline'">
                <mat-label>Uw naam</mat-label>
                <input id="name" matInput formControlName="name" autofocus #inp>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field class="firstName-input" [appearance]="'outline'">
                <mat-label>Uw telefoonnummer</mat-label>
                <input id="phone" matInput formControlName="phone">
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field class="email-input" [appearance]="'outline'">
                <mat-label>Uw email-adres</mat-label>
                <input id="email" matInput formControlName="email">
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field class="remark-input" [appearance]="'outline'">
                <mat-label>Opmerking</mat-label>
                <input id="remark" matInput formControlName="remark">
              </mat-form-field>
            </div>

            <div class="row action">

              <div class="remark-input">
                <button
                  mat-flat-button (click)="onCancel()" type="button"
                >Annuleren
                </button>

                <button
                  mat-raised-button color="accent" [disabled]="pageDisplayType() !== 'form'" type="submit"
                >Verzend
                </button>
              </div>
            </div>

          </form>
        }
      }
    </div>
  </div>
</div>


