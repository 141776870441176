import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButton, MatMiniFabButton } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBar } from "@angular/material/progress-bar";
import { FileUploadComponent } from "./component";
import { ConfirmDeleteUploadComponent } from "./confirm-delete/component";

@NgModule({
  declarations: [
    FileUploadComponent
    // FileDeleteDirective,
    // FilePreviewDirective,
    // QueuedFileDirective,
    // FileBannerDirective
  ],

  exports: [
    FileUploadComponent,
    // FileDeleteDirective,
    // FilePreviewDirective,
    // QueuedFileDirective,
    // FileBannerDirective
  ],

  imports: [
    CommonModule,
    MatIconModule,
    MatMiniFabButton,
    MatProgressBar,
    MatButton,
    ConfirmDeleteUploadComponent
  ]
})
export class PropeldFileUploadModule {
}
