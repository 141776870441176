import {
  BehaviorSubject,
  combineLatest,
  combineLatestAll,
  merge,
  mergeAll,
  Observable,
  of, switchMap
} from "rxjs";
import { map } from "rxjs/operators";
import { OperatorEncoder } from "./encoder";
import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 *  "$and: [...]
 * }
 */
export class AndOperator implements Operator {
  get field(): string { return  "$and" } ;

  private s$ = new BehaviorSubject<Observable<any>[]>([]);
  private _filterChanges$: Observable<any>;

  constructor(private children: Operator[] = []) {
    this._filterChanges$ = this.s$.pipe(
      switchMap(filters$ => {
        return combineLatest(filters$)
      }),
      map(values => {
        const and = values.filter(value => value != null);
        if (and.length <= 0) return null;

        return {
          "$and": and
        };
      })
    );
  }

  addObservables(filters$: Observable<any>[]): void {
    this.s$.next(filters$);
  }

  get filterChanges$(): Observable<any> {
    return this._filterChanges$;
  }

  add(operator: Operator): AndOperator {
    this.children.push(operator);
    return this;
  }

  remove(operator: Operator): AndOperator {
    this.children = this.children.reduce((reduction, value: Operator) => {
      if (value === operator) return reduction;
      reduction.push(value);
      return reduction;
    }, [] as Operator[]);
    return this;
  }

  getChildren(): Operator[] {
    return this.children;
  }

  definition(): EncodedOperator {
    const encodedChildren = this.children.map(filter => filter.definition())
    return {
      o: "$and", v: encodedChildren
    }
  }

  build(): object | null {
    const and = this.children.map(value => value.build())
      .filter(value => value != null);

    if (and.length <= 0) return null;
    return {
      "$and": and
    };
  }
}
