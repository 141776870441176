import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { Company, CompanyModel, initialCompanyStore } from "./model";

export const COMPANY_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CompanyReducer");

export function companyReducerProvider() {
	return reducerFactory<CompanyModel>("company", initialCompanyStore, (data: CompanyModel) => {
		return new Company(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("company", COMPANY_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("company"),

	], providers: [
		{
			provide: COMPANY_REDUCER_TOKEN,
			useFactory: companyReducerProvider
		}
	]
})
export class CompanyCoreModule {

}
