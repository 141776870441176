<div class="page-content">
  <div class="title">
    <div class="inner">
      <h1>Login</h1>
    </div>
  </div>

  <div class="form section mat-typography">
    <div class="inner">
      <div class="column">

        <form [formGroup]="form" (ngSubmit)="login()">
          <div class="pre-text">
            <p>Reset wachtwoord</p>
          </div>

          <div class="invalid-credentials">
            @if (isInvalidLogin()) {
              <p>Ongeldige login. Probeer het opnieuw.</p>
            } @else {
              <p>&nbsp;</p>
            }
          </div>

          <div class="row">

            <mat-form-field class="email" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                type="email"
                matInput
                [formControl]="emailFormControl"
                [errorStateMatcher]="matcher"
                placeholder="Je email adres"
              >

              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is verplicht
              </mat-error>
            </mat-form-field>
          </div>

          <div class="row action">
            <button mat-flat-button [routerLink]="['/login']">Annuleren</button>
            <button mat-raised-button color="accent" type="submit">Ok</button>
          </div>

        </form>

      </div>

    </div>
  </div>
</div>

