import { Component, inject } from "@angular/core";
import { STATS_GET_CANDADATES_PER_REGION } from "../../app.actions";
import { AccountService } from "../../service/account-service/account-service";

@Component({
  selector: "dashboard-page",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class DashboardPageComponent {

  accountService = inject(AccountService);

  constructor() {
  }

  protected readonly STATS_GET_CANDADATES_PER_REGION = STATS_GET_CANDADATES_PER_REGION;
}
