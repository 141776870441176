import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Inject, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import {
  MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatOptgroup, MatOption, MatSelect } from "@angular/material/select";
import { Action } from "../../../../../lib/core/event-bus/action";
import { CrudAction } from "../../../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../../../lib/core/event-bus/event-bus";
import { Message } from "../../../../../lib/core/event-bus/message/message";
import {
  fatalError, filterFailuresAsSnackbarMessage
} from "../../../../../lib/service/snackbar/service/error-filter";
import { SnackbarService } from "../../../../../lib/service/snackbar/service/snackbar.service";
import { DISCLOSE_CANDIDATE_DATA, SHARE_CANDIDATE } from "../../../../app.actions";
import { Candidate } from "../../../../core/model/candidate/model";
import { CandidateContact } from "../../../../core/model/candidateContact/model";

export interface DiscloseAdditionalDataPopupComponent {
  candidateContact: CandidateContact;
}

@Component({
  selector: "publish-candidate-popup",
  standalone: true,
  templateUrl: "./component.html",
  imports: [
    MatDialogContent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogActions,
    MatButton,
    MatInput,
    MatDialogTitle,
    MatSelect,
    MatOptgroup,
    MatOption,
    MatCheckbox
  ],
  styleUrls: ["./component.scss"]
})
export class DiscloseAdditionalDataPopupComponent {

  private dialogRef = inject(MatDialogRef<DiscloseAdditionalDataPopupComponent>);
  private eventbus = inject(EventBus);
  private snackbar = inject(SnackbarService);
  private clipboard = inject(Clipboard);

  communicationTypeControl = new FormControl("");

  form = new FormGroup({
    communicationType: this.communicationTypeControl,
    personalMessage: new FormControl(""),
    email: new FormControl(""),
    phone: new FormControl(""),
    sendMeACopy: new FormControl(false)
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: DiscloseAdditionalDataPopupComponent) {
    this.form.get("email")?.patchValue(data.candidateContact.email);
    this.form.get("phone")?.patchValue(data.candidateContact.phone);
  }

  onConfirm() {
    const data = this.form.value;

    const action = new CrudAction(DISCLOSE_CANDIDATE_DATA,
      this.data.candidateContact.id,
      this.data.candidateContact.rev,
      this.form.value
    );
    this.eventbus.request(action.type, action).asObservable
      .pipe(filterFailuresAsSnackbarMessage(this.snackbar, "Kan gegevens niet delen"))
      .subscribe({
        next: (message: Message<Action<any>>) => {
          const action = message.body as CrudAction<any>;

          //   const token = action.data.tokenInfo.token;
          //   const url = this.configService.get(["websiteUrl"]);
          //   const link = `${url}t/${token}`;
          //
          //   // Copy to clipboard
          //   // TODO: Move to utils
          //   let remainingAttempts = 5;
          //   const pending = this.clipboard.beginCopy(link);
          //   const attempt = () => {
          //     const result = pending.copy();
          //     if (result) {
          //       pending.destroy();
          //       this.snackbar.info("De link is gekopieerd naar het klembord.");
          //     } else {
          //       if (--remainingAttempts > 0) {
          //         setTimeout(attempt);
          //       } else {
          //         pending.destroy();
          //         this.snackbar.info("Er is iets mis gegaan, probeer het nog eens");
          //       }
          //     }
          //   };
          //   attempt();
        },
        error: (error: Error) => fatalError(this.snackbar, error)
      });

    this.dialogRef.close({
      data: this.form.value
    });
  }

  onCancel() {
    this.dialogRef.close(undefined);
  }
}
