import { NgModule } from "@angular/core";

@NgModule({
  declarations: [
  ],

  exports: [
  ],

  imports: [
  ]
})
export class PropeldLoggerModule {
}
