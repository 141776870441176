import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$nlk: <<value>>}
 * }
 */
export class NotLikeOperator implements Operator {
	constructor(public readonly  field: string, private value: any) {
	}

  definition(): EncodedOperator {
    return {
      o: "$nlk", f: this.field, v: this.value
    }
  }

  build(): object {
		return {
      [this.field]: {"$nlk": this.value}
    };
	}
}
