/* tslint:disable:no-console */
import { LogHandler } from "../handler";
import { LogTransformer } from "../transformer";
import { LogType } from "../type";

class EmptyTransformer implements LogTransformer {
	transform(type: LogType, message: string, context?: object): { message: string; context?: object } {
		return {
			message: message,
			context: context
		};
	}
}

export class ConsoleHandler implements LogHandler {
	private transformer: LogTransformer = new EmptyTransformer();

	setTransformer(transformer: LogTransformer): void {
		this.transformer = transformer;
	}

	handle(type: LogType, message: string, context?: object): void {
		if (this.transformer != null) {
			const result = this.transformer.transform(type, message, context);
			message = result.message;
			context = result.context;
		}

		switch (type) {
			case LogType.DEBUG:
				console.debug(`${message}`, context);
				break;
			case LogType.TRACE:
				console.trace(`${message}`, context);
				break;
			case LogType.INFO:
				console.info(`${message}`, context);
				break;
			case LogType.WARNING:
				console.warn(`${message}`, context);
				break;
			case LogType.ERROR:
				console.error(`${message}`, context);
				break;
			case LogType.FATAL:
				console.error(`${message}`, context);
				break;
			default:
				console.error(`LogType: ${type} isn't a known type`);
				break;
		}
	}
}
