import { ACTION_TOKEN_COMPONENT_CONFIGS } from "../../../lib/token/model";

export function componentLoader() {
	return import("./component").then(mod => mod.ViewSharedCandidatePage);
}

export const VIEW_SHARED_CANDIDATE_PROVIDER = {
	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
	multi: true,
	useValue: {
		tokenType: "candidate/view-shared",
		onSuccess: {
			navigateTo: ["/login"],
			toastMessage: "Toon kandidaat"
		},
		loader: componentLoader
	}
};
