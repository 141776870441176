import { signal, Signal, WritableSignal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Store } from "@ngrx/store";
import { BehaviorSubject, distinctUntilChanged, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EventBus } from "../core/event-bus/event-bus";
import { MachineEvent, StateMachine } from "../statemachine/statemachine";
import { Source, SourceData, SourceLoadingStates } from "./source";
import { Filter } from "./source-builder";
import { SourceStateEvents } from "./source-impl";

export type SingleSourceFilterParams = {
  name: string,
  id: number
}

export class SingleMainSourceImpl<T> {

  private current = signal<SingleSourceFilterParams>({
    name: "",
    id: 0
  });

  public get state$(): Signal<SingleSourceFilterParams> {
    return this.current.asReadonly()
  }

  private patch(partialState: Partial<SingleSourceFilterParams>) {
    this.current.update((state) => ({
      ...state,
      ...partialState,
    }));
  }

  private _count = signal<number>( 0);

  model$ = signal({} as T);

  get dataSource$(): Observable<SourceData<T>> {
    return this._dataSource$;
  }

  get dataSourceAsArray$(): Observable<T[]> {
    return this.dataSource$.pipe(map(items => items.items));
  }

  get singleModel$(): WritableSignal<T> {
    throw Error("Unsupported")
  }

  get status(): Signal<SourceLoadingStates> {
    return this.stateMachine.state;
  }
  get event(): Signal<MachineEvent|undefined> {
    return this.stateMachine.event;
  }

  get status$(): Observable<SourceLoadingStates> {
    return this.stateMachine.state$;
  }

  constructor(
    private mainEntityName: string,
    private store: Store<any>,
    private eventBus: EventBus,
    private trigger$: BehaviorSubject<Filter>,
    private _dataSource$: Observable<SourceData<T>>,
    public stateMachine: StateMachine<SourceLoadingStates, SourceStateEvents>,
    readonly name: string
  ) {
    this.on(SourceStateEvents.READY);

    this._dataSource$.pipe(map(data => (data as any).count), distinctUntilChanged())
      .subscribe(length => {
        this.count = length;
      });
  }

  private on(event: SourceStateEvents) {
    this.stateMachine.on(event);
  }

  get singleModel(): Signal<T> {
    // return toSignal(
    const x: Observable<T> = this.dataSource$.pipe(map((data: SourceData<T>) => {
      return data.items[0] as T;
    }));
    return (toSignal(x) as any) as Signal<T>
  }

  clone(filterHandlerAddress?: string): Source<T> {
    throw Error("TODO");
  }

  countChanged(): Observable<number> {
    throw Error("TODO");
  }

  dataChanged(): Observable<T> {
    throw Error("TODO");
  }

  getName(): Readonly<string> {
    return this.current().name as Readonly<string>;
  }

  setName(name: string): this {
    this.patch({
      name: name
    });
    // patchState(this.current, (state) => {
    //   state.name = name;
    //   return state;
    // });
    console.log(">>> Fetch primary 1 candidate setName: "+ JSON.stringify(this.current(),null,4));
    return this;
  }

  getState() {
    return this.current();
  }

  setState(filterState?: SingleSourceFilterParams): this {
    if(filterState == null) return this;

    this.patch({
      name : filterState.name,
      id : filterState.id
    });

    console.log(">>> Fetch primary 1 candidate setState: "+ JSON.stringify(this.current(),null,4));
    return this;
  }

  get count(): Readonly<number> {
    return this._count();
  }

  private set count(value: number) {
    this._count.set(value);
  }

  get count$() {
    return this._count;
  }


  refresh(filter?: Filter): void {
    console.log(">>> Fetch primary 1 candidate refresh: "+ JSON.stringify(filter,null,4));
    if (filter) {
      const clone = JSON.parse(JSON.stringify(filter));
      console.log(">>> Fetch primary 1a candidate refresh: "+ JSON.stringify(clone,null,4));
      this.trigger$.next(clone);
      return;
    }
    // this.trigger$.next({});
  }
}
