import { Component, EventEmitter, inject, Output, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { ReplaySubject } from "rxjs";
import { EventBus } from "../../../../lib/core/event-bus/event-bus";
import { AppActionsStore, AppLocation } from "../../../../lib/service/app-actions/app-actions";
import { SHARE_CANDIDATE, UPDATE_CANDIDATE } from "../../../app.actions";
import { AccountService } from "../../../service/account-service/account-service";

@Component({
  selector: "account-menu-item",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class AccountMenuItemComponent {

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

  accountService = inject(AccountService);

  @Output() menuOpened = new EventEmitter<boolean>();

  logout() {
    this.accountService.logout();
  }
}
