import { signal, WritableSignal } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";

export type MachineState = string | number | symbol;
export type MachineEvent = string | number | symbol;

export type Transition = {
  [toState: MachineState]: { }
}

export type TransitionEvent = {
  [event: MachineEvent]: Transition
}

export type MachineStateDefinition = {
  [fromState: MachineState]: TransitionEvent
}

let counter = 0;

export type StateChangeEVent = {
  event: MachineEvent,
  state: MachineState
}

export class StateMachine<State extends MachineState, Event extends MachineEvent> {

  protected ref: TransitionEvent;

  stateObs = new Subject<State>();
  beforeStateChanges = new Subject<StateChangeEVent>();
  afterStateChanges = new Subject<StateChangeEVent>();
  state : WritableSignal<State>;
  event : WritableSignal<Event|undefined>;

  // afterStateChanges$: WritableSignal<StateChangeEVent>;

  private _name: string;

  get name(): string | null {
    return this._name;
  }

  // initialize the state-machine
  constructor(initialState: State, initialEvent: Event, private definition: MachineStateDefinition, name?: string) {
    this.event = signal(initialEvent);
    this.state = signal(initialState);
    this.ref = this.definition[initialState];
    if(name == undefined) {
      this._name = "STATEMACHINE "+(++counter);
    } else {
      this._name = name;
    }
  }

  get state$(): Observable<State> {
    return this.stateObs as Observable<State>;//toObservable(this.state)
  }

  protected setState(newState: State) {
    this.state.set(newState);
    this.stateObs.next(newState);
  }

  on(event: Event) {

    console.log(`${this._name} STATE: ${this.state().toString()} EVENT `+event.toString());

    const transition = this.ref[event];
    if(transition == null) {
      // Transition doesnt exists
      return;
    }

    const fromState = this.state();
    const newState = Object.keys(transition)[0] as State;
    if(!newState) {
      throw new Error(`Can not transition from '${fromState.toString()}' to '${newState.toString()}': State '${newState.toString()}' not found`);
    }

    this.beforeStateChanges.next({
      state: fromState,
      event: event
    });

    console.log(`${this._name} STATECHANGE FROM ${fromState.toString()} to ${newState.toString()}`);

    this.ref = this.definition[newState];

    this.event.set(event);
    this.setState(newState);

    this.afterStateChanges.next({
      state: newState,
      event: event
    });
  }
}
