import { inject, Injectable, InjectionToken } from "@angular/core";
import { catchError, Observable, of, ReplaySubject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { EventBus } from "../event-bus/event-bus";
import { MessageConsumer } from "../event-bus/message-consumer";
import { Message } from "../event-bus/message/message";
import { Logger } from "../logger/interface";
import { LoggerLocator } from "../logger/locator";

export const EVENT_BUS_BRIDGE_OPTIONS = new InjectionToken<EventBusBridgeOptions>(
  "SocketEventBusBridgeOptions");

export const EVENT_BUS_BRIDGE_OPTIONS_PROVIDER = {
  provide: EVENT_BUS_BRIDGE_OPTIONS,
  useValue: {
    allowedInbound: [],
    allowedOutbound: []
  }
};

export interface EventBusBridgeOptions {
  allowedInbound: string[];
  allowedOutbound: string[];
}

class OutboundConsumer {

  constructor(private readonly address: string, readonly consumer: MessageConsumer<Message<any>>) {
  }

  unregister() {
    this.consumer.unregister();
  }
}

export interface Allowed {
  isAllowed(address: string): boolean;

  unregister(): void;
}

class StringAllowed implements Allowed {

  constructor(
    private address: string,
    private readonly consumer: MessageConsumer<Message<any>> | undefined = undefined
  ) {
  }

  isAllowed(address: string): boolean {
    return this.address === address;
  }

  unregister() {
    this.consumer?.unregister();
  }
}

class RegexAllowed implements Allowed {

  private regex: RegExp;

  constructor(
    regex: string,
    private readonly consumer: MessageConsumer<Message<any>> | undefined = undefined
  ) {
    this.regex = new RegExp(regex);
  }

  isAllowed(address: string): boolean {
    return this.regex.test(address);
  }

  unregister() {
    this.consumer?.unregister();
  }
}

@Injectable({
  providedIn: "root"
})
export abstract class AbstractEventBusBridge {

  protected logger: Logger = LoggerLocator.getLogger("SocketEventBusBridge")();
  protected destroyed = new ReplaySubject<boolean>();
  protected eventBus = inject(EventBus) as EventBus;

  private allowedInbound = new Map<string, Allowed>();
  private allowedOutbound = new Map<string, Allowed>();

  protected constructor() {
  }

  addAllowedInbound(address: string): AbstractEventBusBridge {
    this.allowedInbound.set(address, new StringAllowed(address));
    return this;
  }

  addAllowedInboundRegex(address: string): AbstractEventBusBridge {
    this.allowedInbound.set(address, new RegexAllowed(address));
    return this;
  }

  removeAllowedInbound(address: string): AbstractEventBusBridge {
    this.allowedInbound.delete(address);
    return this;
  }

  addAllowedOutbound(address: string): AbstractEventBusBridge {
    const consumer: MessageConsumer<Message<any>> = this.eventBus.consumer(address);
    consumer.observable
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (message: Message<any>) => {
          this.send(message);
        },
        error: (error) => {
          throw new Error("Unable to send message to socket");
        }
      });

    this.allowedOutbound.set(address, new StringAllowed(address, consumer));
    return this;
  }

  addAllowedOutboundRegex(address: string): AbstractEventBusBridge {
    const consumer: MessageConsumer<Message<any>> = this.eventBus.localConsumer(address);
    consumer.observable
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (message: Message<any>) => {
          this.send(message);
        },
        error: (error) => {
          throw new Error("Unable to send message to socket");
        }
      });

    this.allowedOutbound.set(address, new RegexAllowed(address));
    return this;
  }

  removeAllowedOutbound(address: string): AbstractEventBusBridge {
    const outboundConsumer = this.allowedOutbound.get(address);
    if (outboundConsumer) {
      outboundConsumer.unregister();
      this.allowedOutbound.delete(address);
    }
    return this;
  }

  protected initAllowedInbound(allowedInbound: string[]): void {
    allowedInbound.forEach(value => {
      if (value.startsWith("__regex:")) {
        this.addAllowedInboundRegex(value.substring(8));
      } else {
        this.addAllowedInbound(value);
      }
    });
  }

  protected initAllowedOutbound(allowedOutbound: string[]): void {
    allowedOutbound.forEach((value) => {
      if (value.startsWith("__regex:")) {
        this.addAllowedOutboundRegex(value);
      } else {
        this.addAllowedOutbound(value);
      }
    });
  }

  protected listenToInbound(): void {
    this.inboundMessageObserver().pipe(
      takeUntil(this.destroyed),
      filter((message) => this.isAllowedInbound(message)),
      catchError( (errorMessage, caught) => {
        this.eventBus.sendReply(errorMessage);
        return of(errorMessage);
      })
    )
      .subscribe({
        next: (message: Message<any>) => {
          this.eventBus.send(message.address, message.body, {isLocal: false});
        },
        error: (error) => {
          throw new Error(`Unable to send message from socket to eventbus: ${error.message}`);
        }
      });
  }

  protected isAllowedInbound(message: Message<any>): boolean {
    if(message.address.startsWith("__vertx.reply")) return true;
    for (const [_, entry] of this.allowedInbound) {
      if (entry.isAllowed(message.address)) {
        return true;
      }
    }
    return false;
  }

  protected isAllowedOutbound(message: Message<any>): boolean {
    return this.allowedOutbound.has(message.address);
  }

  abstract inboundMessageObserver(): Observable<any>;

  abstract send(message: Message<any>): void;
}
