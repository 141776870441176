<!--<mat-list-item [disableRipple]="true">-->
<!--    <mat-icon matListItemIcon>account_circle</mat-icon>-->
<!--    <h3 matListItemTitle>{{accountService.get().displayName}}</h3>-->

<!--  <mat-icon matListItemIcon>account_circle</mat-icon>-->
<!--    <button mat-icon-button >&lt;!&ndash;[matMenuTriggerFor]="menu"&ndash;&gt;-->
<!--      <mat-icon>home</mat-icon>-->
<!--    </button>-->
<!--</mat-list-item>-->

<mat-divider></mat-divider>
<div class="account-menu-item">
  <mat-list-item>
      <mat-icon matListItemIcon>account_circle</mat-icon>
      <span matListItemTitle>{{accountService.get().displayName}}</span>
  </mat-list-item>
  <button mat-icon-button [matMenuTriggerFor]="menu" >
      <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['/account']" (click)="this.menuOpened.emit(false)">
    <mat-icon>account_circle</mat-icon>
    <span>Account</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Afmelden</span>
  </button>
</mat-menu>
