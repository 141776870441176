import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData
} from "../../../../lib/store/model/dataModel";

export type FunctionAreaStore = DataModelMap<FunctionAreaModel>;

export const initialFunctionAreaStore: FunctionAreaStore = new Map<DataModelId, FunctionAreaModel>();

export interface FunctionAreaData extends ModelData {
  name: string;
}

export interface FunctionAreaModel extends FunctionAreaData, DataModel {
}

export class FunctionArea extends BaseModel implements FunctionAreaModel {

  readonly name: string;

  constructor(data: FunctionAreaData) {
    super(data.id, data.rev);
    this.name = data.name;
  }

  static placeholder(): FunctionArea {
    return new FunctionArea({
      id: 0,
      rev: -1,
      name: ""
    });
  }


  /**
   * Clones a functionArea and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): FunctionArea {
    data = data ? data : {};

    return new FunctionArea({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      name: Object.hasOwn(data, "name") ? data.name : this.name, //
    } as FunctionAreaModel);
  }

  get displayName(): string {
    return this.name ? this.name : "";
  };
}
