import { Component, DestroyRef, forwardRef, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";

@Component({
  selector: "address-input",
  templateUrl: 'component.html',
  styleUrls: ["./component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true
    }
  ]
})
export class AddressInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() appearance: MatFormFieldAppearance = "fill";

  private destroyedRef = inject(DestroyRef);
  private onChangeFn?: (_: any) => void = undefined;

  form = new FormGroup({
    street: new FormControl(null),
    houseNumber: new FormControl(null),
    zipCode: new FormControl(null),
    city: new FormControl(null)
  });

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe({
      next: (value) => {
        this.onChangeFn?.({
          street: this.form.get("street")?.value,
          houseNumber: this.form.get("houseNumber")?.value,
          zipCode: this.form.get("zipCode")?.value,
          city: this.form.get("city")?.value
        });
      },
      error: (error) => {
        console.error(error.message);
      }
    })
  }
  ngOnDestroy() {
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(data: any): void {
    this.form.patchValue(data, {emitEvent: false});
  }
}
