import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component, DestroyRef, ElementRef, forwardRef, inject, Input, OnInit, ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { BehaviorSubject, debounceTime, Observable, shareReplay, startWith, tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { InOperator } from "src/lib/source-filter/operator";
import { SourceFilterComponentState } from "src/lib/source-filter/source-filter-state";
import { FilterSetting } from "../../source-filter/source-filter";
import {
  SourceFilterStateComponent,
  SourceFilterStateComponentProvider
} from "../../source-filter/source-filter-component";
import { Source } from "../../source/source";
import { DataModelId } from "../../store/model/dataModel";

export type ChipsValueType = {
  id?: DataModelId, name: string
};

@Component({
  selector: "chips-input",
  templateUrl: "component.html",
  styleUrls: ["component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true
    }, SourceFilterStateComponentProvider(ChipsInputComponent)
  ]
})
export class ChipsInputComponent implements OnInit, ControlValueAccessor, SourceFilterStateComponent, Validator {
  private destroyedRef = inject(DestroyRef);

  @Input({required: true}) source: Source<any> | null = null;
  @Input() placeholder: string = "";
  @Input() className: string = "";
  @Input() displayOnly: boolean = false;
  @Input() allowNewItems: boolean = false;
  @Input() filterField: string = "NOT_SET";
  @Input() required: boolean = false;

  control!: FormControl; // = new FormControl(null, []);

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * The value of the selected items that are given to the control as value.
   */
  givenValues$ = new BehaviorSubject<(number | string)[]>([]);
  selectedValues$ = new BehaviorSubject<ChipsValueType[]>([]);

  private onChangeFn?: (_: any) => void = undefined;
  private onTouchedFn?: () => void = undefined;

  @ViewChild("input") input!: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete!: MatAutocomplete;

  get filterComponentId(): string {
    return [this.source?.name ?? "chips-input", this.filterField].join("/");
  }
  getSourceFilterComponentState(): SourceFilterComponentState {
    return {
      name: this.filterComponentId,
      value: this.selectedValues$.value
    }
  }
  setSourceFilterComponentState(state: SourceFilterComponentState): void {
    const ids = (state.value ?? []).reduce( (acc: (number | string)[], item: any) => {
      acc.push(item.id);
      return acc;
    }, []);
    this.givenValues$.next(ids);
  }

  get id(): string {
    return this.source?.name ?? "chips-input";
  }

  get filterSetting(): FilterSetting {
    return this.selectedValues$.value;
  }

  set filterSetting(setting: FilterSetting)  {
    if(setting == null) {
      this.selectedValues$.next([] as ChipsValueType[]);
      return;
    }
    this.selectedValues$.next(setting as ChipsValueType[]);
  }

  ngOnInit() {
    var validators = null;
    if(this.required && !this.displayOnly) {
      validators = [Validators.required];
    }
    this.control = new FormControl(null, validators);
    this.listenToGivenValues();
    this.listenToKeyboard();
  }

  onClear() {
    this.control.setValue(null);
    this.control.updateValueAndValidity();
    this.selectedValues$.next([]);
    this.onChangeFn?.(this.transformToArray(this.selectedValues$.value));
    this.onTouchedFn?.();
    this.onValidatorChangeFn?.();
  }

  add(event: MatChipInputEvent): void {
    // Add text item only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.chipInput.inputElement;
      const value = event.value;

      // Add new item
      if (this.allowNewItems && (value || "").trim()) {
        this.addSelectedValue(value);
      }

      // Reset the input value
      if (input) {
        input.value = "";
      }

      this.control.setValue(null);
    }
  }

  remove(value: ChipsValueType | string): void {
    const index = this.selectedValues$.value.findIndex((item) => {
        if(typeof value === "string") {
          return item.name === value;
        } else {
          return item.id === value.id;
        }
    });
    if (index >= 0) {
      const list = this.selectedValues$.value;
      list.splice(index, 1);
      this.control.setValue(list);
      this.control.updateValueAndValidity();
      this.selectedValues$.next(list);
      this.onChangeFn?.(this.transformToArray(this.selectedValues$.value));
      this.onTouchedFn?.();
      this.onValidatorChangeFn?.();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addSelectedValue(event.option.value);
    this.input.nativeElement.value = '';
    this.control.setValue(null);
  }

  private addSelectedValue(value: ChipsValueType | string) {
    // Check if value is already in list
    const isDuplicate = this.isDuplicate(value);
    if(isDuplicate) return;

    // Add value to list
    var list = this.selectedValues$.value;
    if(typeof value === "string") {
      list = list.concat({name: value.trim()});
    } else {
      list = list.concat(value);
    }
    this.control.setValue(list);
    this.control.updateValueAndValidity();
    this.selectedValues$.next(list);
    this.onTouchedFn?.();
    this.onChangeFn?.(this.transformToArray(this.selectedValues$.value));
    this.onValidatorChangeFn?.();
  }

  private isDuplicate(value: ChipsValueType | string) {
    return (this.selectedValues$.value.findIndex((item) => {
      if (typeof value === "string") {
        return item.name === value || item.name.toLowerCase() === value.trim().toLowerCase();
      } else {
        return item.id === value.id;
      }
    }) >= 0);
  }

  private transformToArray(values: ChipsValueType[]): (number | string)[] {
    return values.map((value) => value.id || value.name);
  }

  private listenToKeyboard() {
    if(this.source == null) return;
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef), debounceTime(300)).subscribe({
      next: (value) => {
        this.source?.setSearch(value);
      },
      error: (error) => {
        console.error(error.message);
      }
    })
  }

  /**
   * listens to given values and loads appropriate values from source.
   * This is an array with either numbers or strings.
   * @private
   */
  private listenToGivenValues() {
    if (this.source == null) return;
    this.givenValues$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe({
      next: (selectedValues) => {
        if (selectedValues.length > 0) {
          // Filter out all integer values and load these via the source
          const ids = selectedValues.filter((value) => typeof value === "number") as number[];
          const newValues = selectedValues.filter((value) => typeof value === "string") as string[];
          this.source!.getIds(ids).subscribe({
            next: (models) => {
              const mergedValues = models.concat(newValues);
              this.selectedValues$.next(mergedValues);
              this.control.setValue(mergedValues);
              this.control.markAsPristine();
              this.control.updateValueAndValidity();
              // this.onChangeFn?.(this.transformToArray(this.selectedValues$.value));
              this.onValidatorChangeFn?.();
            },
            error: (error) => {
              console.error(error.message);
            }
          });
        }
      }
    });
  }

  get filterChanges(): Observable<any> {
    return this.selectedValues$.pipe(
      startWith(this.control.value),
      tap(value => {
          this.control.updateValueAndValidity();
          this.onChangeFn?.(this.transformToArray(this.selectedValues$.value));
          this.onValidatorChangeFn?.();
      }),
      takeUntilDestroyed(this.destroyedRef),
      debounceTime(300),
      filter(value => {
        return typeof value !== "string";
      }),
      map(values => {
        if(values == null ) return null;

        const ids = values
          .filter((value: { id: DataModelId; }) => value?.id != null)
          .map((value: { id: any; }) => value.id) as DataModelId[];
        return new InOperator(this.filterField, ids).build();
      }),
      shareReplay(1)
    );
  }

  /**
   * @description
   * Writes a new value to the element.
   *
   * This method is called by the forms API to write to the view when programmatic
   * changes from model to view are requested.
   *
   * @usageNotes
   * ### Write a value to the element
   *
   * The following example writes a value to the native DOM element.
   *
   * ```ts
   * writeValue(value: any): void {
   *   this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
   * }
   * ```
   *
   * @param obj The new value for the element
   */
  writeValue(obj: (number | string)[]): void {
    if (obj == null) {
      this.givenValues$.next([]);
    } else if (Array.isArray(obj)) {
      this.givenValues$.next(obj);
    } else {
      throw new Error("ChipsInputComponent.writeValue: value must be an array");
    }
  }

  /**
   * @description
   * Registers a callback function that is called when the control's value
   * changes in the UI.
   *
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   *
   * When implementing the `registerOnChange` method in your own value accessor,
   * save the given function so your class calls it at the appropriate time.
   *
   * @usageNotes
   * ### Store the change function
   *
   * The following example stores the provided function as an internal method.
   *
   * ```ts
   * registerOnChange(fn: (_: any) => void): void {
   *   this._onChange = fn;
   * }
   * ```
   *
   * When the value changes in the UI, call the registered
   * function to allow the forms API to update itself:
   *
   * ```ts
   * host: {
   *    '(change)': '_onChange($event.target.value)'
   * }
   * ```
   *
   * @param fn The callback function to register
   */
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  /**
   * @description
   * Registers a callback function that is called by the forms API on initialization
   * to update the form model on blur.
   *
   * When implementing `registerOnTouched` in your own value accessor, save the given
   * function so your class calls it when the control should be considered
   * blurred or "touched".
   *
   * @usageNotes
   * ### Store the callback function
   *
   * The following example stores the provided function as an internal method.
   *
   * ```ts
   * registerOnTouched(fn: any): void {
   *   this._onTouched = fn;
   * }
   * ```
   *
   * On blur (or equivalent), your class should call the registered function to allow
   * the forms API to update itself:
   *
   * ```ts
   * host: {
   *    '(blur)': '_onTouched()'
   * }
   * ```
   *
   * @param fn The callback function to register
   */
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  /**
   * @description
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   *
   * @usageNotes
   * The following is an example of writing the disabled property to a native DOM element:
   *
   * ```ts
   * setDisabledState(isDisabled: boolean): void {
   *   this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
   * }
   * ```
   *
   * @param isDisabled The disabled status to set on the element
   */
  setDisabledState?(isDisabled: boolean): void {
    // debugger;
  }


  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return this.control.errors;
  }

  private onValidatorChangeFn: (() => void) | null = null;
  registerOnValidatorChange?(onValidatorChangeFn: () => void): void {
    this.onValidatorChangeFn = onValidatorChangeFn;
  }

  protected readonly JSON = JSON;
}
