<div class="input-column">

  @if(displayMode === "display" || (displayMode === "edit" && !editable)) {
    <p><ng-content select=".display"></ng-content></p>

  } @else if (displayMode === "edit") {
      <ng-content></ng-content>
  } @else {
    <p></p>
  }
</div>
