import { Store } from "@ngrx/store";
import { combineLatestWith, Observable, shareReplay } from "rxjs";
import { map } from "rxjs/operators";
import { Placeholder } from "../../app/core/model/placeholder/model";
import { CrudAction } from "../core/event-bus/crud-action";
import { EventBus } from "../core/event-bus/event-bus";
import { DataModel } from "../store/model/dataModel";
import { SourceSelector } from "./source-builder";

export class SingleMainSelector implements SourceSelector {
  private _isLinked = false;
  readonly level: number = 0;
  readonly selector: Observable<any>;
  readonly breadcrumb: string;
  private _modelCreatorFn: (() => DataModel) | null = null;

  constructor(
    readonly alias: string,
    readonly entityName: string,
    trigger$: Observable<any>,
    store: Store<any>,
    eventBus: EventBus
  ) {
    this.breadcrumb = this.entityName;

    this.selector = trigger$.pipe( //
      map(filter =>
        filter.id ?? 0
      ), //
      // filter( (id: DataModelId) =>
      //   id != null && id != 0 && id !== ""
      // ), //
      combineLatestWith(
        store.select(state => (state as any)[entityName])
      ),
      map(([id, mainState$]) => {
        const data =  {
          count: 1,
          ids: [id]
        };

        let isNew = id == null || id === 0 || (typeof id === 'string' && id.trim() === "");
        let loaded = true;
        let model = null;
        if(!isNew) {
          model = (mainState$ as any).get(id);
        }
        if (model == null) {
          model = this._modelCreatorFn?.() ?? new Placeholder(id, -1);

          if(!isNew) {
            loaded = false;
            const action = new CrudAction(`${entityName}/get`, 0, 0, {
              ids: [id]
            });
            eventBus.request(`${entityName}/get`, action).subscribe({
              next: (message) => {
                // inFlight = false;
                console.log(`>> RECEIVED ${entityName} IDS, DISPATCH `, message.body);
                store.dispatch(message.body);
              },
              error: (error) => {
                // inFlight = false;
                console.log(error.message);
              }
            });
          }

        } else {
          model = model.clone();
        }

        const progress: string[] = [];
        if(loaded) progress.push(this.breadcrumb);

        console.log(`>> MAIN ${this.entityName} PUSH`);

        return {
          entity: entityName,
          count: data.count,
          items: [model],
          related: new Map<string, Map<string, DataModel>>(),
          progress: progress
        };
      }), shareReplay(1)
    );
  }

  setModelCreatorFn(fn: (() => DataModel) | null) {
    this._modelCreatorFn = fn;
  }

  setLinked() {
    this._isLinked = true;
  }

  get isLinked(): boolean { return this._isLinked; }
}
