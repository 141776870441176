import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { Source } from "../source/source";
import { AndOperator } from "./operator";
import { SourceFilter } from "./source-filter";

@Injectable({
  providedIn: "root"
})
export class SourceFilterStateFactory {

  private services = new Map<string, SourceFilterState>();

  constructor() {
  }

  create(name: string): SourceFilterState {
    let service = this.services.get(name);
    if (service == null) {
      service = new SourceFilterState(name);
      this.services.set(name, service);
    }
    return service;
  }
}

export class SourceFilterState {

  private operator = new AndOperator();
  private subscription?: Subscription | null;
  private data = new Map<string, any>();
  private _source: Source<any> | null = null;
  private _components: SourceFilter[] = [];

  constructor(private name: string) {
  }

  get source(): Source<any> | null {
    return this._source;
  }

  set source(source: Source<any> | null) {
    this._source = source;
  }

  register(id: string, components: SourceFilter[]) {
    this._components = components;

    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }

    const observables = components.map(component => component.filterChanges);
    this.operator.addObservables(observables);

    this.subscription = this.operator.filterChanges$.subscribe({
      next: (filter) => {
        this._source?.setFilter(filter);
      }
    });
  }

  patchSettings() {
    this._components.forEach(component => {
      //component.filterSetting = this.data.get(component.id);
    });
  }

  saveSettings() {
    this._components.forEach(component => {
      this.data.set(component.id, component.filterSetting);
    });
  }
}
