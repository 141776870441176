import { Component, DestroyRef, inject, OnInit, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { AppActionsStore } from "../../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { EqualOperator } from "../../../../lib/source-filter/operator";
import { Source, SourceData, SourceLoadingStates } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { Candidate } from "../../../core/model/candidate/model";
import {
  CandidateAvailabilityOptions, CandidateStatus, DiscStyleOptions, getCandidateAvailabilityName
} from "../../../core/model/constants";
import { AccountService } from "../../../service/account-service/account-service";

@Component({
	selector: "public-candidate-detail-page",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/styles.scss"]
})
export class PublicCandidateDetailPageComponent implements OnInit {

  cardId = "candidate/public-detail-page";

  /**
   *  Housekeeping variables
   */
  accountService = inject(AccountService);
  appState = inject(AppState);
  appActions = inject(AppActionsStore);
  private destroyRef = inject(DestroyRef);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  displayMode = signal<string>("loading");
  model$ = signal<Candidate>(Candidate.placeholder());
  profilePictureUrl$ = signal<string>("");

  /**
   *  Source related
   */
  private sourceBuilder = inject(SourceBuilder);
  source: Source<Candidate> = this.sourceBuilder
    .builder("candidate")
    .setModelCreatorFn(() => Candidate.placeholder())
    .setName("CandidateListSource")
    .link("educationLevel")
    .link("user")
    .build()
    .setRequiredFilter(new EqualOperator("uuid", '').build())

  availabilitySource: Source<any> = this.sourceBuilder
    .builder("*availability")
    .setName("availabilitySource")
    .setItems(CandidateAvailabilityOptions)
    .build();

  discStyleSource: Source<any> = this.sourceBuilder
    .builder("*discStyle")
    .setName("discStyleSource")
    .setItems(DiscStyleOptions)
    .build();

  protected readonly SourceStates = SourceLoadingStates;

  constructor() {
    this.source.dataSource$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (data: SourceData<Candidate>) => {
        if(data.count === 0) return;
        const candidate = data.items[0];
        this.model$.set(candidate);
        this.profilePictureUrl$.set(environment.httpEndpoint+"/file/"+candidate.ppId);
        if(!candidate.isPlaceholder) {
          this.displayMode.set("display");
        }
      }
    });
  }

  ngOnInit() {
    const snapshot = this.route.snapshot;
    const params = snapshot.params;
    const uuid = params["uuid"];
    this.source.setRequiredFilter(new EqualOperator("uuid", uuid).build());
  }

  protected readonly CandidateStatus = CandidateStatus;
  protected readonly getCandidateAvailabilityName = getCandidateAvailabilityName;
}
