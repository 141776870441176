import { BehaviorSubject, mergeAll, reduce } from "rxjs";
import { first, map } from "rxjs/operators";
import { Operator } from "../../source-filter/operator";
import { OperatorEncoder } from "../../source-filter/operator/encoder";

export type NullableOperator = Operator | null;
export type NullableListFilter = ListFilter | null;

class ListFilter {
  readonly id: string;
  visible: boolean = true;
  required: boolean = false;
  operator: Operator | null = null;

  constructor(id: string, operator: NullableOperator) {
    this.id = id;
    this.operator = operator;
  }
}

export class ListFilterDisplay {

  private _filters = new Map<string, ListFilter>();

  filters$ = new BehaviorSubject<ListFilter[]>([]);

  requiredFilters$ = this.filters$.pipe(
    map( list => list.filter( v => v.required))
  );

  visibleFilters$ = this.filters$.pipe(
    map( list =>  list.filter( v => v.visible))
  );

  constructor(ids?: string[]) {
    this.add(ids);
  }

  add(ids?: string[]): ListFilterDisplay {
    ids?.forEach( (key: string) => {
      this._filters.set(key, new ListFilter(key, null));
    });
    return this;
  }

  setRequired(id: string, operator: Operator): ListFilterDisplay {
    let filter = this._filters.get(id);
    if(filter != null) {
      filter.operator = operator;
    } else {
      filter = new ListFilter(id, operator);
    }
    filter.required = true;
    filter.visible = false;

    return this;
  }

  refresh():ListFilterDisplay {
    this.filters$.next([...this._filters.values()]);
    return this;
  }

  encodeRequiredFilters() {
    let encoded: string = "";

    this.requiredFilters$.pipe(
      first(),
      mergeAll(),
      reduce( (acc, filter) => {
        if(filter.operator != null) {
          acc.push(filter.operator);
        }
        return acc;
      }, [] as Operator[])
    ).subscribe( list => {
      encoded = OperatorEncoder.encode(list);
    });
    return encoded;
  }
}

