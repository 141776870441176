// "columns": {
// 	"id": {
// 		"type": "INTEGER",
// 			"nullable": false,
// 			"length": 0,
// 			"default": "nextval('user_id_seq'::regclass)",
// 			"cast": null
// 	},

import { InjectionToken } from "@angular/core";

export const SCHEMA_DEFINITION = new InjectionToken<SchemaDefinition>("SchemaDefinition");

export enum FieldType {
	NUMBER = "NUMBER", STRING = "STRING", BOOLEAN = "BOOLEAN", OBJECT = "OBJECT", DATE = "DATE", ARRAY = "ARRAY"
}


export enum SchemaFieldType {
	INTEGER = "INTEGER",
	VARCHAR = "VARCHAR",
	TEXT = "TEXT",
	BOOLEAN = "BOOLEAN",
	JSONB = "JSONB",
	CHAR = "CHAR",
	TIMESTAMPZ = "TIMESTAMPZ"
}

export interface SchemaFieldDefinition {
	type: string,
	nullable?: boolean,
	length?: number,
	default?: string,
	cast?: SchemaFieldType | null
	jsonStrategy?: "MERGE" | "REPLACE_ALL"
}

export type SchemaFieldListDefinition = { [name: string]: SchemaFieldDefinition }

//--------

export interface SchemaEntityDefinition {
	columns: SchemaFieldListDefinition;
	relations: SchemaRelationListDefinition;
	// indexes: SchemaIndexes
}

export type SchemaDefinition = { [name: string]: SchemaEntityDefinition }

export interface SchemaRelationDefinition {
	column: string,
	references?: string,
	referencedBy?: string
}


export type SchemaRelationListDefinition = { [name: string]: SchemaRelationDefinition }


export type FullSchemaDefinition = { [tables: string]: any }
