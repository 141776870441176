import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData, NullableNumber
} from "../../../../lib/store/model/dataModel";

export type AuthRoleStore = DataModelMap<AuthRoleModel>;

export const initialAuthRoleStore: AuthRoleStore = new Map<DataModelId, AuthRoleModel>();

export interface AuthRoleData extends ModelData {
  name: string,
  parentId: NullableNumber,
  parent: AuthRole | null
}

export interface AuthRoleModel extends AuthRoleData, DataModel {

}

export class AuthRole extends BaseModel implements AuthRoleModel {

  readonly name: string;
  readonly parentId: NullableNumber;
  readonly parent: AuthRole | null

  constructor(data: AuthRoleData) {
    super(data.id, data.rev);
    this.name = data.name;
    this.parentId = data.parentId;
    this.parent = data.parent;
  }

  static placeholder(): AuthRole {
    return new AuthRole({
      id: 0,
      rev: -1,
      name: "",
      parentId: null,
    } as AuthRoleData);
  }

  /**
   * Clones a authRole and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): AuthRole {
    data = data ? data : {};
    return new AuthRole({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      name: Object.hasOwn(data, "name") ? data.name : this.name, //,
      parentId: Object.hasOwn(data, "parentId") ? data.parentId : this.parentId,
      parent: Object.hasOwn(data, "parent") ? data.parent : this.parent
    } as AuthRoleData);
  }

  get displayName(): string {
    return this.name;
  };
}
