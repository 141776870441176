import { Action, Store } from "@ngrx/store";
import {
  combineLatestWith, debounceTime,
  distinctUntilChanged,
  Observable,
  shareReplay,
  switchMap,
  tap
} from "rxjs";
import { map } from "rxjs/operators";
import { Placeholder } from "../../app/core/model/placeholder/model";
import { CrudAction } from "../core/event-bus/crud-action";
import { EventBus } from "../core/event-bus/event-bus";
import { Message } from "../core/event-bus/message/message";
import { DataModel, DataModelId } from "../store/model/dataModel";
import { SourceSelector } from "./source-builder";

export class MainSelector implements SourceSelector {

  private _isLinked = false;
  readonly level: number = 0;
  readonly selector: Observable<any>;
  readonly breadcrumb: string;

  constructor(readonly alias: string, readonly entityName: string, trigger$: Observable<any>, store: Store<any>, eventBus: EventBus) {
    this.breadcrumb = this.entityName;

    this.selector = trigger$.pipe( //
      distinctUntilChanged(),
      debounceTime(50),
      map( filter => {
        return {
          search: filter.search ?? "",
          offset: filter.offset ?? 0,
          limit: filter.limit ?? 20,
          sort: filter.sort ?? [],
          filter: filter.filter
        }
      }),
      switchMap(filter => {
        console.log(`>>> FETCH primary ${entityName}-2/filter`, filter);
        const action = new CrudAction(`${entityName}/filter`, 0, 0, filter);
        return eventBus.request("store/filter", action).asObservable
          .pipe(
            tap(message => {
              console.log(`>> PRIMARY ${this.entityName} FETCHED`, message);
            })
          ) as Observable<Message<Action>>;
      }), //
      combineLatestWith(
        store.select(state => (state as any)[entityName])
          .pipe(
            tap(state => {
                console.log(`>> STATE ${this.entityName} CHANGED`, state);
            })
          )
      ),
      map(([message, mainState$]) => {
        const data = (message as any).body.data as {
          count: number, ids: DataModelId[];
        };

        let loaded = true;
        const list = data.ids.reduce((acc, id) => {
          const model = (mainState$ as any).get(id);
          if (model == null) {
            acc.push(new Placeholder(id, -1));
            loaded = false;
          } else {
            acc.push(model.clone());
          }
          return acc;
        }, [] as DataModel[]);

        const progress: string[] = [];
        if(loaded) progress.push(this.breadcrumb);

        console.log(`>> MAIN ${this.entityName} PUSH`);

        return {
          entity: entityName,
          count: data.count,
          items: list,
          related: new Map<string, Map<string, DataModel>>(),
          progress: progress
        };
      }), shareReplay(1)
    );
  }

  setLinked() {
    this._isLinked = true;
  }

  get isLinked(): boolean { return this._isLinked; }
}
