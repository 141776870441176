<h1 mat-dialog-title>Verwijder afbeelding</h1>
<mat-dialog-content>

  <p>Wil je de afbeelding verwijderen?</p>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button (click)="onCancel()">Annuleer</button>
  <button mat-flat-button color="primary" (click)="onDelete()"autofocus>Verwijder</button>
</mat-dialog-actions>
