/// <reference types="@angular/localize" />



import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DefaultLogger } from "./lib/core/logger/default/logger";
import { ConsoleHandler } from "./lib/core/logger/handler/console";
import { LoggerLocator } from "./lib/core/logger/locator";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const defaultLogger = new DefaultLogger();
defaultLogger.addHandler(new ConsoleHandler());
LoggerLocator.provide(defaultLogger);

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));
