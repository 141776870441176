import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { MatFormField, MatLabel } from "@angular/material/form-field";

@Component({
  selector: "publish-candidate-popup",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
  imports: [
    CommonModule,
    MatDialogContent,
    MatFormField,
    MatDialogActions,
    MatLabel,
    ReactiveFormsModule,
    MatButton,
    MatDialogTitle
  ],
  standalone: true
})
export class ConfirmDeleteUploadComponent {

  private dialogRef = inject(MatDialogRef<ConfirmDeleteUploadComponent>);

  form = new FormGroup({
    contact: new FormControl("")
  });

  onDelete() {
    this.dialogRef.close({
      delete: true
    });
  }

  onCancel() {
    this.dialogRef.close(undefined);
  }
}
