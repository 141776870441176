import { Directive, ElementRef, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { distinctUntilChanged, Observable, Subscription, tap } from "rxjs";
import { AccountService } from "../../../app/service/account-service/account-service";

@Directive({
  selector: '[hasPermission]',
})
export class PermissionDirective implements OnInit, OnDestroy {

  @Input() permission: string | undefined = undefined;
  @Input() display: 'block' | 'flex' | undefined = "block";

  accountService = inject(AccountService);
  private subscription: Subscription | null = null;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.style.display = "none";

    this.subscription = this.accountService.asObservable.
      pipe(distinctUntilChanged()).subscribe(account => {
      this.elementRef.nativeElement.style.display = account.hasPermission(this.permission ?? "") ? this.display : "none";
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
