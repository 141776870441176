@if (displayMode() === 'loading') {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<mat-card class="mat-typography">

  <div class="card-header">
    <mat-card-header class="card-title-block">
      <mat-card-title>
        Kandidaten per regio
      </mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    @if(!appState.isMobile$()) {
      <div class="card-actions-top-block">
        <top-card-actions></top-card-actions>
      </div>
    }
  </div>

  <mat-card-content class="mat-body-1">
    <div class="dashboard-chart" echarts [options]="chartOptions()" (chartInit)="onChartInit($event)"></div>

  </mat-card-content>

  <mat-card-actions>

    <div class="left">
      <!--      <button mat-flat-button>Reset wachtwoord</button>-->
      <!--      <button mat-flat-button>Verander email</button>-->
    </div>

    <div class="right">
    </div>

  </mat-card-actions>
</mat-card>
