import { Injectable } from "@angular/core";
import { Schema } from "./schema";
import { FieldType, SchemaDefinition, SchemaEntityDefinition } from "./schema-definitions";
import { SchemaField } from "./schema-field";
import { SchemaRelationType } from "./schema-relation";

const FIELD_TYPE_MAP = {
  VARCHAR: FieldType.STRING,
  TIMESTAMPZ: FieldType.STRING,
  CHAR: FieldType.STRING,
  TEXT: FieldType.STRING,
  INTEGER: FieldType.NUMBER,
  SMALLINT: FieldType.NUMBER,
  BOOLEAN: FieldType.BOOLEAN,
  JSONB: FieldType.OBJECT
};

@Injectable({
  providedIn: "root"
})
export class SchemaBuilder {

  readonly schema: Schema = new Schema();

  build(definition: SchemaDefinition): Schema {
    for (let [key, value] of Object.entries(definition)) {
      this.buildEntity(key, value);
    }

    for (let [key, value] of Object.entries(definition)) {
      this.buildEntityRelations(key, value);
    }
    return this.schema;
  }

  private buildEntity(name: string, definition: SchemaEntityDefinition) {
    const entity = this.schema.createEntity(name);

    for (let [fieldName, fieldDefinition] of Object.entries(definition.columns)) {

      const fieldType = (FIELD_TYPE_MAP as any)[fieldDefinition.type];
      if (fieldType == null) {
        throw Error(`Field type ${fieldDefinition.type} not found in field ${fieldName}`);
      }
      entity.createField(fieldName, fieldType);
    }
  }

  private buildEntityRelations(entityName: string, definition: SchemaEntityDefinition) {
    for (let [relationName, relationDefinition] of Object.entries(definition.relations)) {
      const entity = this.schema.getEntity(entityName);
      if (!entity) throw Error(`Entity ${entityName} not found`);

      let relationType = SchemaRelationType.MANY_TO_ONE;
      if (relationName.startsWith("_")) {
        relationType = SchemaRelationType.ONE_TO_MANY;
      }

      const sourceField = entity.getField(relationDefinition.column);
      if (sourceField == null) {
        throw Error(`Source field (column) ${relationDefinition.column} not found in relation ${relationName}`);
      }

      if (relationType === SchemaRelationType.MANY_TO_ONE) {
        entity.createRelation(relationName,
          relationType,
          sourceField,
          this.findTargetField(relationDefinition.references)
        );
      } else {
        entity.createRelation(relationName,
          relationType,
          this.findTargetField(relationDefinition.referencedBy),
          sourceField
        );
      }
    }
  }

  private findTargetField(name: string | undefined): SchemaField {
    if (name == null) {
      throw Error(`target field name required in references or referencedBy`);
    }
    const index = name.indexOf(".");
    const entityName = name.substring(0, index);
    const fieldName = name.substring(index + 1);
    const field = this.schema.getEntity(entityName)?.getField(fieldName);
    if (field == null) {
      throw Error(`target field ${name} not found`);
    }
    return field;
  }

  private getFieldType(type: String) {

  }
}
