import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, DestroyRef, inject, Input, signal, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { async } from "rxjs";
import { ListFilterDisplay } from "../../../../lib/component/list-filter/list-filter";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { EqualOperator } from "../../../../lib/source-filter/operator";
import { SourceFilterState } from "../../../../lib/source-filter/source-filter-state";
import { SortDirection, Source, SourceLoadingStates } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { ExpandedRowsDirective } from "../../../../lib/source/state/expanded-rows-directive";
import { SourcePageStateStore } from "../../../../lib/source/state/source-page-state-store";
import { DataModelId } from "../../../../lib/store/model/dataModel";
import { CandidateContact } from "../../../core/model/candidateContact/model";
import { AccountService } from "../../../service/account-service/account-service";
import { DiscloseAdditionalDataPopupComponent } from "./disclose-additional-data-popup/component";

@Component({
  selector: "candidateContact-list-card",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({
          height: "0px",
          minHeight: "0",
          display: "none"
        })
      ),
      state("expanded", style({height: "*"})),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class CandidateContactListComponent {

  /**
   *  Housekeeping variables
   */
  accountService = inject(AccountService);
  appState = inject(AppState);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private sourceStateStore = inject(SourcePageStateStore);
  private dialog = inject(MatDialog);

  /**
   *  Source related
   */
  private sourceBuilder = inject(SourceBuilder);

  source: Source<CandidateContact> = this.sourceBuilder
    .builder("candidateContact")
    .setName("candidateContactListSource")
    .setInitialFilter(this.sourceStateStore.getState("candidateContactListSource", {
      pageSize: 20,
      sort: [
        {
          field: "timestamp",
          direction: SortDirection.DESC
        }
      ]
    }))
    .link("candidate")
    .link("user")
    .linkRelation("user", "sharedBy", "fk_candidateContact_sharedByUserId")
    .build();

  /**
   *  List related
   */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(ExpandedRowsDirective) expandedRows!: ExpandedRowsDirective;

  columns = [
    "source",
    "timestamp",
    "candidate",
    "sharedByUserId",
    "discloseAdditionalDataButton",
    "name",
    "phone",
    "email",
    "expand",
    "contextMenu"
  ];
  filters = new ListFilterDisplay(["companyId"]).refresh();
  visibleFilters$ = this.filters.visibleFilters$;
  displayFilters = signal<boolean>(false);

  @Input() set searchInHeaderBar(searchInHeaderBar: boolean) {
    this.appState.setSearchSource(this.source);
  }

  @Input() set candidateId(candidateId: DataModelId) {
    this.source.setRequiredFilter(new EqualOperator("candidateId", candidateId).build());
  }

  constructor() {
    const displayFilters = (this.source.getState() as SourceFilterState).displayFilters;
    this.displayFilters.set(displayFilters ?? false);
  }

  ngOnDestroy() {
    this.sourceStateStore.setState(this.source.name, this.source.getState());

    if (this.appState.searchSource$() != null) {
      this.appState.clearSearchSource();
    }
  }

  onToggleFilters() {
    this.displayFilters.set(!this.displayFilters());
  }

  onOpenCandidate(id: DataModelId) {
    this.router.navigate(["/candidate", id]).catch(error => {
      console.log(error.message);
    });
  }

  onDiscloseAdditionalData(candidateContact: CandidateContact) {
      const dialogRef = this.dialog.open(DiscloseAdditionalDataPopupComponent, {
        data: {
          candidateContact: candidateContact
        }
      });
      dialogRef.afterClosed().subscribe(result => {
          console.log(JSON.stringify(result, null, 4))
      });
  }

  onToggleExpandRow(id: DataModelId) {
    this.expandedRows?.toggle(id);
  }

  protected readonly SourceStates = SourceLoadingStates;
  protected readonly async = async;
  protected readonly alert = alert;
}
