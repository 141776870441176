<ng-container *ngIf="data.icon == null; else providedIcon">
  <mat-icon *ngIf="data.level === level.ERROR">report</mat-icon>
  <mat-icon *ngIf="data.level === level.WARNING">priority_high</mat-icon>
  <mat-icon *ngIf="data.level === level.SUCCESS">done</mat-icon>
  <mat-icon *ngIf="data.level === level.INFO">info</mat-icon>
  <mat-icon *ngIf="data.level === level.DEFAULT || data.level == null">emoji_objects</mat-icon>
</ng-container>
<ng-template #providedIcon>
  <mat-icon>{{data.icon}}</mat-icon>
</ng-template>

<span>{{data.message}}</span>

<ng-container *ngIf="hasAction">
  <button mat-button (click)="action()">{{data.action?.label}}</button>
</ng-container>
