import { Action } from "../action";
import { MESSAGE_CODEC, MessageCodec } from "../message-codec";

export class ActionCodec<T> implements MessageCodec<Action<T>, Action<T>>
{
	// private logger = LoggerLocator.getLogger("ActionCodec")();
	private actionProperties = ["type", "id", "rev", "data"];

	transform(body: {
		type: string,
		// id: number,
		// rev: number,
		data: T
	}): Action<T> {
		const {type, data} = body;
		return new Action(type,  data);
	}
}

export const ACTION_MESSAGE_CODEC_PROVIDER = {
	provide: MESSAGE_CODEC, useClass: ActionCodec
};
