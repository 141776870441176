import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData, NullableNumber, NullableString
} from "../../../../lib/store/model/dataModel";
import { AuthRole } from "../authRole/model";
import { Company } from "../company/model";

export type UserStore = DataModelMap<UserModel>;

export const initialUserStore: UserStore = new Map<DataModelId, UserModel>();

export interface UserData extends ModelData {
  firstName?: NullableString,
  infix?: NullableString,
  surname?: NullableString,
  email: string,
  companyId?: NullableNumber,
  companyName?: NullableString,
  gender: string,
  city?: NullableString,
  phone?: NullableString,
  authRoleId: number,
  locale: string,
  active: boolean,
  initials: string,
  ppId: number,
  lastLogin: string,

  company?: Company | null;
  authRole?: AuthRole | null;
}

export interface UserModel extends UserData, DataModel {
  clone(data?: any): User;
}

export class User extends BaseModel implements UserModel {

  readonly firstName?: NullableString;
  readonly infix?: NullableString;
  readonly surname?: NullableString;
  readonly email: string;
  readonly companyId?: NullableNumber;
  readonly companyName?: NullableString;
  readonly gender: string;
  readonly city?: NullableString;
  readonly phone?: NullableString;
  readonly authRoleId: number;
  readonly locale: string;
  readonly active: boolean;
  readonly initials: string;
  readonly ppId: number;
  readonly lastLogin: string;
  readonly company?: Company | null;
  readonly authRole?: AuthRole | null;

  constructor(data: UserData) {
    super(data.id, data.rev);
    this.firstName = data.firstName;
    this.infix = data.infix;
    this.surname = data.surname;
    this.email = data.email;
    this.companyId = data.companyId;
    this.companyName = data.companyName;
    this.gender = data.gender;
    this.city = data.city;
    this.phone = data.phone;
    this.authRoleId = data.authRoleId;
    this.locale = data.locale;
    this.active = data.active;
    this.initials = data.initials;
    this.ppId = data.ppId;
    this.lastLogin = data.lastLogin;
    this.company = null;
    this.authRole = null;
  }

  static placeholder(): User {
    return new User({
      id: 0,
      rev: -1,
      firstName: null,
      infix: null,
      surname: null,
      email: "",
      companyId: null,
      companyName: null,
      gender: "U",
      city: null,
      phone: null,
      authRoleId: 10,
      locale: "NL",
      active: false,
      initials: "",
      ppId: 0,
      lastLogin: "",
      company: null,
      authRole: null
    });
  }

  /**
   * Clones a user and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): User {
    data = data ? data : {};
    return new User({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      firstName: Object.hasOwn(data, "firstName") ? data.firstName : this.firstName, //,
      infix: Object.hasOwn(data, "infix") ? data.infix : this.infix,
      surname: Object.hasOwn(data, "surname") ? data.surname : this.surname,
      email: Object.hasOwn(data, "email") ? data.email : this.email,
      companyId: Object.hasOwn(data, "companyId") ? data.companyId : this.companyId,
      companyName: Object.hasOwn(data, "companyName") ? data.companyName : this.companyName,
      gender: Object.hasOwn(data, "gender") ? data.gender : this.gender,
      city: Object.hasOwn(data, "city") ? data.city : this.city,
      phone: Object.hasOwn(data, "phone") ? data.phone : this.phone,
      authRoleId: Object.hasOwn(data, "authRoleId") ? data.authRoleId : this.authRoleId,
      locale: Object.hasOwn(data, "locale") ? data.locale : this.locale,
      active: Object.hasOwn(data, "active") ? data.active : this.active,
      initials: Object.hasOwn(data, "initials") ? data.initials : this.initials,
      ppId: Object.hasOwn(data, "ppId") ? data.ppId : this.ppId,
      lastLogin: Object.hasOwn(data, "lastLogin") ? data.lastLogin : this.lastLogin,
      company: Object.hasOwn(data, "company") ? data.company : this.company,
      authRole: Object.hasOwn(data, "authRole") ? data.authRole : this.authRole
    } as UserData);
  }

  override get displayName(): string {
    const v = [];
    if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
    if (this.infix != null && this.infix !== "") v.push(this.infix);
    if (this.surname != null && this.surname !== "") v.push(this.surname);
    return v.join(" ");
  };

  get surnameFirst(): string {
    const v = [];
    const sn = this.surname;

    if (sn != null && sn !== "") v.push(sn);
    if (this.firstName != null && this.firstName !== "") {
      if(sn != null && sn !== "") {
        v.push(", "+this.firstName);
      } else {
        v.push(this.firstName)
      }
    }
    if (this.infix != null && this.infix !== "") v.push(" "+this.infix);
    return v.join("");
  };
}
