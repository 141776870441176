import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { inject, signal } from "@angular/core";
import { Subscription } from "rxjs";
import { Breakpoint, BreakpointDefinition } from "./breakpoints";

export interface ResizeDetector {
  resizer: Resizer;
}

const defaultBreakpoints: BreakpointDefinition[] = [
  Breakpoint.XSmall, Breakpoint.Small, Breakpoint.Medium, Breakpoint.Large, Breakpoint.XLarge
];

export class Resizer {

  private breakpoints: BreakpointDefinition[];
  private el: HTMLElement | null = null;

  screenSize$ = signal<BreakpointDefinition>({
    name: "init"
  });

  private screenObserver = inject(BreakpointObserver);
  private subscription?: Subscription;

  constructor(el: HTMLElement | null = null, breakpoints: BreakpointDefinition[] = defaultBreakpoints) {
    this.breakpoints = breakpoints;

    if (el == null) {
      this.observeScreen();
    }
  }

  unsubscribe() {
    this.subscription?.unsubscribe();
  }

  private observeScreen() {
    const breakpointValues = this.buildBreakpointValues();
    this.subscription = this.screenObserver.observe(breakpointValues)
      .subscribe((state: BreakpointState) => {
        const key = this.findMatchingQuery(state);
        const breakpoint = this.breakpoints.find(breakpoint => breakpoint.query === key);
        console.log("!! SIZE: "+breakpoint?.name);
        this.screenSize$.set(breakpoint ?? {
          name: "NOT FOUND"
        });
      });
  }

  private findMatchingQuery(state: BreakpointState): string | null {
    for (let key in state.breakpoints) {
      const matches = state.breakpoints[key] as boolean;
      if (matches) {
        return key;
      }
    }
    return null;
  }

  private buildBreakpointValues() {
    return this.breakpoints.reduce((acc, item) => {
      if (item.query === undefined) {
        const lines = [] as string[];
        if (item.minWidth !== undefined) {
          lines.push(`(min-width: ${item.minWidth}px)`);
        }
        if (item.maxWidth !== undefined) {
          lines.push(`(max-width: ${item.maxWidth}px)`);
        }
        item.query = lines.join(" and ");
      }
      acc.push(item.query!);
      return acc;
    }, [] as string[]);
  }
}
