import { Component, DestroyRef, inject, Input, OnDestroy, signal, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { async } from "rxjs";
import { ListFilterDisplay } from "../../../../lib/component/list-filter/list-filter";
import { AppActionsStore, AppLocation } from "../../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { EqualOperator } from "../../../../lib/source-filter/operator";
import { SortDirection, Source, SourceLoadingStates } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { SourcePageStateStore } from "../../../../lib/source/state/source-page-state-store";
import { DataModelId } from "../../../../lib/store/model/dataModel";
import { Company } from "../../../core/model/company/model";
import { CANDIDATE_CREATE_PERMISSION, USER_CREATE_PERMISSION } from "../../../core/model/permissions";
import { User } from "../../../core/model/user/model";
import { AccountService } from "../../../service/account-service/account-service";

@Component({
  selector: "user-list-card",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/list-styles.scss"]
})
export class UserListComponent implements OnDestroy {

  readonly cardId = "user/list-card";
  /**
   *  Housekeeping variables
   */
  accountService = inject(AccountService);
  appState = inject(AppState);
  appActions = inject(AppActionsStore);
  destroyRef = inject(DestroyRef);
  private sourceStateStore = inject(SourcePageStateStore);

  /**
   *  Source related
   */
  private sourceBuilder = inject(SourceBuilder);
  source: Source<User> = this.sourceBuilder
    .builder("user")
    .setName("UserListSource")
    .setInitialFilter(this.sourceStateStore.getState("UserListSource", {
      pageSize: 20,
      sort: [
        {
          field: "surname",
          direction: SortDirection.ASC
        }
      ]
    }))
    .link("company")
    .link("authRole")
    .build();

  companySource: Source<Company> = this.sourceBuilder
    .builder("company")
    .setName("CompanySource")
    .build();

  /**
   *  List related
   */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  columns = ["surname", "infix", "firstName", "authRole.name", "active", "company.name"];
  filters = new ListFilterDisplay(["companyId"]).refresh();
  visibleFilters$ = this.filters.visibleFilters$;
  showFilters = signal<boolean>(false);

  @Input() set filterCompanyId(companyId: DataModelId) {
    this.filters.setRequired("companyId", new EqualOperator("companyId", companyId));
    this.source.setRequiredFilter(new EqualOperator("companyId", companyId).build());
  }

  form = new FormGroup({
    companyFilter: new FormControl(0)
  });

  @Input() set searchInHeaderBar(searchInHeaderBar: boolean) {
    this.appState.setSearchSource(this.source);
  }

  constructor(private route: ActivatedRoute, private router: Router) {
    this.initActions();
  }

  initActions() {
    this.appActions.setActions(this.cardId, [{
        id: "search",
        icon: () => "search",
        location: [AppLocation.APPBAR],
      }]
    );
  }

  ngOnDestroy() {
    this.sourceStateStore.setState(this.source.name, this.source.getState());

    if (this.appState.searchSource$() != null) {
      this.appState.clearSearchSource();
    }
  }

  onClick(id: DataModelId) {
    const requiredFilters = this.filters.encodeRequiredFilters();

    this.router.navigate(["/user/", id], {queryParams: {r: requiredFilters}})
      .catch((error) => alert(error.message));
  }

  onToggleFilters() {
    this.showFilters.set(!this.showFilters());
  }

  protected readonly SourceStates = SourceLoadingStates;
  protected readonly async = async;
  protected readonly CANDIDATE_CREATE_PERMISSION = CANDIDATE_CREATE_PERMISSION;
  protected readonly USER_CREATE_PERMISSION = USER_CREATE_PERMISSION;
}
