@for (item of action$(); track item) {
  @if (item.id === "search") {
    <button mat-icon-button (click)="toggleSearch()">
      <mat-icon>{{ item?.icon?.() }}</mat-icon>
    </button>
  } @else if (item.id === "cancel") {
    @if(!appState.isMobile$()) {
      <button mat-icon-button (click)="onClick(item.id)" [matTooltip]="item?.tooltip?.() ?? ''">
        <mat-icon>{{ item?.icon?.() }}</mat-icon>
      </button>
    }
  } @else {
    @if(item.condition === undefined || item.condition()) {
      <button mat-icon-button (click)="onClick(item.id)" [matTooltip]="item?.tooltip?.() ?? ''">
        <mat-icon>{{ item?.icon?.() }}</mat-icon>
<!--        <span>-->
<!--          @if(item.condition === undefined) { undefined }-->
<!--          @else {-->
<!--            C:{{ item.condition() }}-->
<!--          }-->
<!--          </span>-->
      </button>
    }
  }
}

<!--@for (item of buttons$(); track item) {-->
<!--  @if (item.id === "search") {-->
<!--    <button mat-icon-button (click)="toggleSearch()">-->
<!--      <mat-icon>{{ item.icon }}</mat-icon>-->
<!--    </button>-->
<!--  } @else if (item.id === "cancel") {-->
<!--    @if(!appState.isMobile$()) {-->
<!--      <button mat-icon-button (click)="onClick(item.id)">-->
<!--        <mat-icon>{{ item.icon }}</mat-icon>-->
<!--      </button>-->
<!--    }-->
<!--  } @else {-->
<!--    <button mat-icon-button (click)="onClick(item.id)">-->
<!--      <mat-icon>{{ item.icon }}</mat-icon>-->
<!--    </button>-->
<!--  }-->
<!--}-->

@if (menuItems$().length > 0) {
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
}

<mat-menu #menu="matMenu">
  @for (item of menuItems$(); track item) {
    <button mat-menu-item>
      <mat-icon>{{ item.icon }}</mat-icon>
      <span>{{ item.text }}</span>
    </button>
  }
</mat-menu>

