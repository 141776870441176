@if (displayOnly) {
  @for (item of (selectedValues$ | async); track item; let index = $index) {
    @if(index>0) { {{", &nbsp;" }} }
    {{ item.name ?? item }}
  }
} @else {
  <mat-form-field [class]="className" [appearance]="'outline'">
    <mat-chip-grid #chipList>
      @for (item of (selectedValues$ | async);track item) {
        <mat-chip
          [removable]="removable" (removed)="remove(item)"
        >
          {{ item.name }}
          @if (removable) {
            <mat-icon matChipRemove>cancel</mat-icon>
          }
        </mat-chip>
      }
      <input
        [placeholder]="placeholder"
        #input
        [required]="required"
        [formControl]="control"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      >
    </mat-chip-grid>

    @if ((selectedValues$ | async)!.length > 0) {
      <button mat-icon-button matSuffix (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    } @else {
      <mat-icon matIconSuffix>expand_more</mat-icon>
    }

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let item of (source?.dataSourceAsArray$ | async)" [value]="item">
        {{ item.displayName }}
      </mat-option>
    </mat-autocomplete>

    @if (control.errors != null) {
      <mat-error>Dit veld is verplicht {{control.errors}}</mat-error>
    }
  </mat-form-field>
}
