import { produce, setAutoFreeze } from "immer";
import { Action } from "../../core/event-bus/action";
import { CrudAction } from "../../core/event-bus/crud-action";
import { DataModel, DataModelMap, DataModelRevision } from "../model/dataModel";

setAutoFreeze(false);

export function reducerFactory<T extends DataModel>(entity: string,
  initialValue: DataModelMap<T>,
  creatorFn: (data: T) => T,
  converterFn?: (data: T) => T
) {
  return new Reducer(entity, initialValue, creatorFn).reducer;
}

export class Reducer<T extends DataModel> {

  protected readonly updated: string;
  protected readonly deleted: string;
  protected readonly received: string;

  constructor(private readonly entity: string,
    protected readonly initialValue: DataModelMap<T>,
    protected readonly creatorFn: (data: T) => T
  ) {
    this.updated = `${entity}/updated`;
    this.deleted = `${entity}/deleted`;
    this.received = `${entity}/received`;
  }

  get reducer() {
    return produce((draft: DataModelMap<T>, action: Action<T>) => {

      switch (action.type) {

        case this.received: {
          this.onReceived(draft, action as any);
          break;
        }

        case this.updated: {
          const crudAction = this.requireCrudAction(action);
          const model = draft.get(crudAction.id);
          if (model != null && (crudAction.data as { rev: DataModelRevision }).rev > model.rev) {
            const newModel = (model as any).clone(action.data) as T;
            draft.set(newModel.id, newModel);
          }
          break;
        }

        case this.deleted: {
          draft.delete(this.requireCrudAction(action).id);
          break;
        }

        case "store/logout": {
          draft.clear();
          break;
        }

        default: {
          this.checkAdditional(draft, action);
        }
      }
    }, this.initialValue);
  }

  checkAdditional(draft: DataModelMap<T>, action: Action<T>) {
    // Placeholder
  }

  onReceived(draft: DataModelMap<T>, action: Action<{ items: T[] }>) {
    const items = action.data.items;
    if(items == null || items.length === 0) {
      // Single record given in request.data
      const givenAction = action as CrudAction<any>;
      const id = givenAction.id;
      let storeItem = draft.get(id);
      if(storeItem != null && storeItem.rev < givenAction.data.rev) {
        storeItem = storeItem?.clone(givenAction.data) as T;
        draft.set(id, storeItem);
      }
    } else {
      items.forEach((item: T) => {
        const storeItem = draft.get(item.id);
        if (storeItem == null || storeItem.rev < item.rev) {
          const data = item as T;
          draft.set(item.id, this.creatorFn(data));
        }
      });
    }
  }

  requireCrudAction(action: Action<T>): CrudAction<T> {
    if (!(action instanceof CrudAction)) {
      throw Error(`CrudAction expected, got ${action.encode()}`);
    }
    return action as CrudAction<T>;
  }
}
