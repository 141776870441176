import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { tap } from "rxjs";
import { filter, map } from "rxjs/operators";

export const loginGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);
  const stateSnapshot = state;

  return store.select(state => state["account"])
    .pipe(// first(),
      map(state => {
        if (state == null) return null;
        const [account] = state.values();
        return account;
      }),
      filter(account => account != null && account.rev !== -1),
      tap(account => {

        const isGuest = account.authRoleId <= 10;
        let path = stateSnapshot.url;
        if (isGuest) {
          if(!path.startsWith("/login")) {
            if (!path == null || path == "" || path == "/") {
              router.navigate(["/login"]);
            } else {
              router.navigate(["/login", {url: path}]);
            }
          }
          return;
        }

        const isUser = account.authRoleId <= 20;
        if (isUser) {
          if(!path.startsWith("/candidates")) {
            if (!path == null || path == "" || path == "/") {
              router.navigate(["/candidates"]);
            } else {
              router.navigate(["/candidates", {url: path}]);
            }
          }
          return;
        }

        if(path.startsWith("/login")) {
          router.navigate(["/dashboard"]);
        }
      }));
};
