<div class="multi-input" [formGroup]="form">
  <div>
    <mat-form-field class="street-input">
      <mat-label>Straat</mat-label>
      <input id="street" matInput formControlName="street" [placeholder]="'Straat'">
    </mat-form-field>

    <mat-form-field class="houseNumber-input">
      <mat-label>Huisnr.</mat-label>
      <input matInput formControlName="houseNumber" [placeholder]="'Huisnr.'">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="zipCode-input">
      <mat-label>Postcode.</mat-label>
      <input matInput formControlName="zipCode" [placeholder]="'Postcode'">
    </mat-form-field>

    <mat-form-field class="city-input">
      <mat-label>Plaats</mat-label>
      <input matInput formControlName="city" [placeholder]="'Plaats'">
    </mat-form-field>
  </div>
</div>
