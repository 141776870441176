import { ChangeDetectionStrategy, Component, HostBinding, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { EventBus } from "../../../core/event-bus/event-bus";
import { SnackbarData, SnackbarLevel } from "../action";

@Component({
	selector: "propeld-snackbar-component",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
	public level = SnackbarLevel;

	constructor(
		public snackBarRef: MatSnackBarRef<SnackbarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
		private eventBus: EventBus
	) {
	}

	@HostBinding("class") get className(): string {
		return `level-${this.data?.level ?? SnackbarLevel.DEFAULT}`;
	}

	get hasAction(): boolean {
		return !!this.data.action;
	}

	action(): void {
    alert("TODO")
    // const data = this.data as SnackbarData;
		// this.eventBus.send<unknown>(this.data.action.replyAddress, {});
		// this.snackBarRef.dismiss();
	}
}
