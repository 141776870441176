import { Observable, Subject } from "rxjs";
import { LoggerLocator } from "../logger/locator";
import { EventBus, ReplyHolder } from "./event-bus";
import { MessageConsumer } from "./message-consumer";
import { ErrorMessage } from "./message/error-message";
import { Message } from "./message/message";

/**
 * The handlerHolder class maintains different consumers for a single address.
 * Every handler is stored with its own UUID that is returned upon registration.
 *
 * @author R.v.Raaphorst
 */
export class HandlerHolder implements ReplyHolder {

	private subject = new Subject<Message<any>>;
	private consumers: Map<string, MessageConsumer<any>> = new Map();
	private logger = LoggerLocator.getLogger("HandlerHolder")();

	constructor(protected eventBus: EventBus, public readonly address: string) {

	}

	public get asObservable(): Observable<Message<any>> {
		return this.subject as Observable<Message<any>>;
	}

	get size() {
		return this.consumers.size;
	}

	register(isLocal: boolean = false): MessageConsumer<any> {
		const consumer = new MessageConsumer<any>(this, isLocal);
		this.consumers.set(consumer.uuId, consumer);
		return consumer;
	}

	unregister(uuid: string) {
		if (!this.consumers.delete(uuid)) {
			this.logger.warning(`Handler for uuid: '${uuid}' is not registered and cannot be unregistered`);
			return;
		}
		if(this.consumers.size == 0) {
			this.eventBus.unregisterHolder(this);
		}
	}

	error(error:  Error | ErrorMessage): void {
		// TODO Round Robin algorithm
		this.consumers.values().next().value.error(error);
	}

	send(message: Message<any>): void {
		// TODO Round Robin algorithm
		this.subject.next(message);
	}

	publish(message: Message<any>): void {
		this.consumers.forEach( (messageConsumer) => {
			// todo create clone on message with deep clone
			messageConsumer.send(message);
		});
	}
}

export class RegexReplyHolder extends HandlerHolder {

  private regex :RegExp;

  constructor(eventBus: EventBus, address: string) {
    super(eventBus, address);
    this.regex = new RegExp(address);
  }

  matches(address: string): boolean {
    return this.regex.test(address);
  }
}

