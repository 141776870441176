import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData
} from "../../../../lib/store/model/dataModel";

export type IndustryStore = DataModelMap<IndustryModel>;

export const initialIndustryStore: IndustryStore = new Map<DataModelId, IndustryModel>();

export interface IndustryData extends ModelData {
  name: string;
}

export interface IndustryModel extends IndustryData, DataModel {
}

export class Industry extends BaseModel implements IndustryModel {

  readonly name: string;

  constructor(data: IndustryData) {
    super(data.id, data.rev);
    this.name = data.name;
  }

  static placeholder(): Industry {
    return new Industry({
      id: 0,
      rev: -1,
      name: ""
    });
  }


  /**
   * Clones a industry and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): Industry {
    data = data ? data : {};

    return new Industry({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      name: Object.hasOwn(data, "name") ? data.name : this.name, //
    } as IndustryModel);
  }

  get displayName(): string {
    return this.name ? this.name : "";
  };
}
