import { inject, Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { filter, first, timeoutWith } from "rxjs/operators";
// @ts-ignore
import SockJS from "sockjs-client/dist/sockjs";
import { Util } from "../../util/util";
import { MessageObject } from "../event-bus/message/message";
import { Logger } from "../logger/interface";
import { LoggerLocator } from "../logger/locator";
import { SOCKET_OPTIONS, SocketOptions } from "./options";

export enum SocketStates {
	OPENING, OPEN, CLOSING, CLOSED, NO_HEARTBEAT
}

export const DEFAULT_PING_INTERVAL = 5000;
export const DEFAULT_HEARTBEAT_INTERVAL = 50000; //2 * 25 seconden default VertX
export const DEFAULT_TIMEOUT_DURATION = 3000;

@Injectable({
	providedIn: "root"
})
export class Socket {
	private socketMessages: Subject<any> = new Subject();
	private socket: SockJS;
	private statusEvents = new BehaviorSubject(SocketStates.CLOSED);
	private readonly pingInterval: number = DEFAULT_PING_INTERVAL;
	private readonly heartbeatInterval: number = DEFAULT_HEARTBEAT_INTERVAL;
	private readonly timeoutDuration: number = DEFAULT_TIMEOUT_DURATION;
	private readonly url: string;
	private pingTimerHandle: number | null = null;
	private heartbeatTimerHandle: number | null = null;
	private logger: Logger = LoggerLocator.getLogger("Socket")();

  private options: SocketOptions = inject(SOCKET_OPTIONS);

  constructor(private ngZone: NgZone) {
		this.url = this.options.url;

    if(this.options.pingInterval != null) {
      this.pingInterval = this.options.pingInterval;
    }
    if(this.options.heartbeatInterval != null) {
      this.heartbeatInterval = this.options.heartbeatInterval;
    }
    if(this.options.timeout != null) {
      this.timeoutDuration = this.options.timeout;
    }
		this.initPing();
	}

	get messages(): Observable<any> {
		return this.socketMessages.asObservable();
	}

	get statusObservable(): Observable<SocketStates> {
		return this.statusEvents.asObservable();
	}

	get status(): SocketStates {
		return this.statusEvents.value;
	}

	private set internalStatus(status: SocketStates) {
		this.statusEvents.next(status);
	}

	send(message: any) {
		if (this.status === SocketStates.CLOSING || this.status === SocketStates.CLOSED) {
			this.logger.fatal("Socket is closed, or is closing");
			throw new Error("Socket is closed, or is closing");
		}
		if (this.status === SocketStates.OPENING) {
			this.logger.fatal("Socket has not finished opening yet");
			throw new Error("Socket has not finished opening yet");
		}

		this.logger.debug(`Message send:\n\n${JSON.stringify(message, null, 4)}`);
		this.socket.send(JSON.stringify(message));
	}

  open(): Observable<SocketStates> {

		if (this.status !== SocketStates.CLOSED) {
			this.logger.fatal("Socket is already open, first close it using this.close()");
			return throwError("Socket is already open, first close it using this.close()");
		}
		this.statusEvents.next(SocketStates.OPENING);
		let observable = this.statusEvents.pipe(
			filter((state: SocketStates) => state === SocketStates.OPEN),
			timeoutWith(this.timeoutDuration,
				throwError("[SOCKET-TIMEOUT-MESSAGE] socket was unable to open")
			),
			first()
		);
		this.socket = this.openSocket(this.url);
		this.bindCallbacks();
		return observable;
	}

	close() {
		if (this.status === SocketStates.CLOSING || this.status === SocketStates.CLOSED) {
			this.logger.fatal("Socket was already closed, or is closing");
			throw new Error("Socket was already closed, or is closing");
		}
		if (this.status === SocketStates.OPENING) {
			this.logger.fatal("Socket is not open yet");
			throw new Error("Socket is not open yet");
		}
		this.internalStatus = SocketStates.CLOSING;
		this.socket.close();
	}

	private initPing() {
		this.statusEvents.pipe(filter(event => event === SocketStates.OPEN))
			.subscribe(() => this.startPing());
		this.statusEvents.pipe(filter(event => event === SocketStates.CLOSED))
			.subscribe(() => {
				this.stopPing();
				this.cancelHeartbeatTimeout();
			});
	}

	private startPing() {
		if (this.pingTimerHandle != null) {
			Util.clearIntervalOutsideNgZone(this.ngZone, this.pingTimerHandle);
		}

		this.pingTimerHandle = Util.setIntervalOutsideNgZone(this.ngZone, () => {
			this.socket.send("{\"type\":\"ping\"}");
		}, this.pingInterval);
	}

	private stopPing() {
		if (this.pingTimerHandle == null) {
			return;
		}

		Util.clearIntervalOutsideNgZone(this.ngZone, this.pingTimerHandle);
		this.pingTimerHandle = null;
	}

	private heartbeatReceived() {
		this.cancelHeartbeatTimeout();

		this.heartbeatTimerHandle = Util.setTimeoutOutsideNgZone(this.ngZone, () => {
			this.noServerHeartbeat();
		}, this.heartbeatInterval);
	}

	private cancelHeartbeatTimeout() {
		if (this.heartbeatTimerHandle == null) {
			return;
		}

		Util.clearTimeoutOutsideNgZone(this.ngZone, this.heartbeatTimerHandle);
		this.heartbeatTimerHandle = null;
	}

	private noServerHeartbeat() {
		this.logger.warning("Server didn't send a heartbeat within the required timeframe, closing socket");
		this.internalStatus = SocketStates.NO_HEARTBEAT;
		this.socket.close();
	}

	private bindCallbacks() {
		this.socket.onopen = () => {
			this.internalStatus = SocketStates.OPEN;
		};

		this.socket.onclose = () => {
			this.internalStatus = SocketStates.CLOSED;
		};

		this.socket.onmessage = (event: any) => {
			const message: MessageObject<any> = JSON.parse(event.data);
			this.socketMessages.next(message);
			this.logger.debug(`Message received:\n\n${JSON.stringify(event.data, null, 4)}`);
		};

		this.socket.onheartbeat = () => {
			this.heartbeatReceived();
		};
	}

	private openSocket(url: string) {
		return new SockJS(this.url, {}, {timeout: this.timeoutDuration});
	}
}
