import { ConfigService } from "../../../lib/core/config/service";
import { Schema } from "../../../lib/schema/schema";
import { SchemaBuilder } from "../../../lib/schema/schema-builder";
import { SchemaDefinition } from "../../../lib/schema/schema-definitions";
import * as schemaDefinitionFile from "../../app.schema.definition";

const additionalSchemaDefinitions = {
  account: {
    columns: {
      id: {
        type: "INTEGER",
        nullable: false,
        length: 0
      },
      rev: {
        type: "INTEGER",
        nullable: false,
        length: 0
      },
      parentId: {
        type: "INTEGER",
        nullable: false,
        length: 0
      },
      email: {
        type: "VARCHAR",
        nullable: true,
        length: 60
      },
      gender: {
        type: "CHAR",
        nullable: false,
        length: 1
      },
      firstName: {
        type: "VARCHAR",
        nullable: true,
        length: 20
      },
      infix: {
        type: "VARCHAR",
        nullable: true,
        length: 10
      },
      surname: {
        type: "VARCHAR",
        nullable: false,
        length: 45
      },
      phone: {
        type: "VARCHAR",
        nullable: true,
        length: 15
      },
      role: {
        type: "VARCHAR",
        nullable: false,
        length: 12
      },
      locale: {
        type: "VARCHAR",
        nullable: false,
        length: 5
      },
      active: {
        type: "BOOLEAN",
        nullable: false,
        length: 0
      },
      initials: {
        type: "VARCHAR",
        nullable: true,
        length: 10
      },
      ppId: {
        type: "INTEGER",
        nullable: false,
        length: 0
      },
      lastLogin: {
        type: "TIMESTAMPZ",
        nullable: true,
        length: 0
      }
    },
    relations: {
      fk_account_company: {
        column: "parentId",
        references: "company.id"
      }
    }
  }
} as SchemaDefinition;
;

export function schemaFactory(configService: ConfigService): Schema {
  const definition: SchemaDefinition = Object.assign({},
    schemaDefinitionFile.schemaDefinition,
    additionalSchemaDefinitions
  );
  return new SchemaBuilder().build(definition);
}
