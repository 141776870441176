import { ACTION_TOKEN_COMPONENT_CONFIGS } from "../../../lib/token/model";

export function componentLoader() {
	return import("./component").then(mod => mod.VerifyEmailPage);
}

export const CONFIRM_EMAIL_PROVIDER = {
	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
	multi: true,
	useValue: {
		tokenType: "user/verify-email",
		onSuccess: {
			toastMessage: "Email adres bevestigd"
		},
		loader: componentLoader
	}
};
