import { DeliveryOptions } from "../delivery-options";
import { EventBus } from "../event-bus";
import { MessageCodec } from "../message-codec";
import { ErrorMessage } from "./error-message";

export const DEFAULT_MESSAGE_TIMEOUT = 30 * 1000;

export const MESSAGE_REPLY_PREFIX = "__vertx.reply.";

export enum MessageType {
	SEND = "send",
	PUBLISH = "publish",
	REGISTER = "register",
	UNREGISTER = "unregister",
	REGISTERED = "registered"
}

export interface MessageObject<T> {
	address: string,
	body: T,
	type: MessageType,
	headers?: { [key: string]: string }
	replyAddress?: string
}

export class Message<T> {
	readonly address: string;
	readonly type: MessageType;
	readonly body: T;
	readonly headers: { [key: string]: string } = {};
	readonly replyTimeout = DEFAULT_MESSAGE_TIMEOUT;
	private replyAddress: string | null = null;
  private readonly codec: MessageCodec<unknown, T>;
	private readonly eventBus: EventBus;
  public readonly isLocal: boolean = false;
  private readonly options?: DeliveryOptions;

	constructor(
		address: string,
		type: MessageType,
		body: T,
		eventBus: EventBus,
		codec: MessageCodec<unknown, T>,
		options?: DeliveryOptions
	) {
		this.address = address;
		this.type = type;
		this.body = body; //codec.transform(body);
		this.eventBus = eventBus;
    this.codec = codec;
    this.options = options;

		if (options == null) {
			return;
		}

		if (options.headers) {
			this.headers = options.headers;
		}

		if (options.replyTimeout) {
			this.replyTimeout = options.replyTimeout;
		}

    if(options.isLocal === true) {
      this.isLocal = true;
    }
  }

	public setReplyAddress(replyAddress: string | null): Message<T> {
		this.replyAddress = replyAddress;
		return this;
	}

	public getReplyAddress(): string | null {
		return this.replyAddress;
	}

  isReply(): boolean {
    return this.replyAddress != null && this.replyAddress.startsWith(MESSAGE_REPLY_PREFIX);
  }

	public reply<A>(body: A, options?: DeliveryOptions): void {
		if (this.replyAddress == null) return;
		const reply = this.eventBus.createMessage(this.replyAddress, MessageType.SEND, body, options);
		this.eventBus.sendReply(reply);
	}

	// public replyAndRequest(body: T, options?: DeliveryOptions): Observable<Message<T>> {
	// 	if (this.replyAddress == null) return throwError(() => new Error("Message cannot be replied upon!"));
	// 	const reply = this.eventBus.createMessage(this.replyAddress, MessageType.SEND, body, options);
	// 	return this.eventBus.sendAndRequestReply(reply, options);
	// }

  public fail(failureCode: number, message: string): void {
    if (this.replyAddress == null) return;
    const action = this;

    const failureMessage = this.createFailureMessage(this.replyAddress, this.type, {
      failureType: "RECIPIENT_FAILURE",
      failureCode: failureCode,
      message: message
    });

    this.eventBus.sendReply(failureMessage);
  }

  createFailureMessage(replyAddress: string, type: MessageType, body: any) {
    return new FailureMessage(replyAddress, type, body, this.eventBus, this.codec, {});
  }


	// public fail(failureCode: number, message: string): void {
	// 	if(this.replyAddress == null) return;
	// 	const action = this
  //
	// 	const error = new ErrorMessage({
	// 		address: this.replyAddress,
	// 		failureType: "RECIPIENT_FAILURE",
	// 		failureCode: failureCode,
	// 		message: message,
  //     type: "err",
  //     name: "",
	// 	});
	// 	this.eventBus.sendReply(error as any);
	// }

  /*
  address: "__vertx.reply.0d2628f1b1c919cb509549"
failureCode:  400
failureType: "RECIPIENT_FAILURE"
message: "BAD_REQUEST"
type: "err"
   */
  public toErrorMessage(): FailureMessage {
    return new FailureMessage(
      this.address,
      this.type,
      this.body,
      this.eventBus,
      this.codec,
      {

      }
    );
  }

	public toJSON(): MessageObject<T> {
		return Object.assign({
			address: this.address,
			body: this.body,
			type: this.type
		}, this.replyAddress == null ? {} : {
			replyAddress: this.replyAddress
		}, this.headers == null ? {headers: {}} : {
			headers: this.headers
		});
	}

  public encode(): string {
    return JSON.stringify(this.toJSON());
  }
}

class FailureMessage extends Message<any> {
  constructor(
    address: string,
    type: MessageType,
    body: any,
    eventBus: EventBus,
    codec: MessageCodec<unknown, any>,
    options?: DeliveryOptions
  ) {
    super(address, type, body, eventBus, codec, options);
  }
}

