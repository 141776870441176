import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"$nnull: <<field>>
 * }
 */
export class NotNullOperator implements Operator {
	constructor(public readonly  field: string) {
	}

  definition(): EncodedOperator {
    return {
      o: "$nnull", f: this.field
    }
  }

  build(): object {
		return {
			"$nnull": this.field
		};
	}
}
