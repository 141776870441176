import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { ConfigJson } from "./config";

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  private config: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);

  constructor(private httpClient: HttpClient) {
  }

  loadConfig(): Observable<unknown> {
    this.httpClient.get<ConfigJson>("./assets/config.json")
      .pipe(map(configJson => this.mergeTargetConfigurationIntoBase(configJson)))
      .subscribe(this.config);

    return this.config.pipe(filter(value => value != null), take(1));
  }

  get<T extends unknown>(path?: string[] | string): T {
    const currentConfig = this.config.value;

    if (path == null) return null as any;
    if (typeof path === "string") {
      path = path.split(".");
    }

    return path.reduce((objectSection, attribute) => {
      if (objectSection == null) {
        return undefined;
      }

      return (objectSection as any)[attribute] || undefined;
    }, currentConfig) as any;
  }

  private mergeTargetConfigurationIntoBase<T>(config: ConfigJson): T {
    const baseConfiguration = config["$base"];
    const overrideConfiguration = config["$targetOverrides"]["consumer"] || {};
    return this.mergeDeep({}, baseConfiguration, overrideConfiguration) as T;
  }

  private mergeDeep(target: any, ...sources: any): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (ConfigService.isObject(target) && ConfigService.isObject(source)) {
      for (const key in source) {
        if (ConfigService.isObject(source[key])) {
          if (!target[key]) Object.assign(target, {[key]: {}});
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, {[key]: source[key]});
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  private static isObject(item: any) {
    return (item && typeof item === "object" && !Array.isArray(item));
  }
}
