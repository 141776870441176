
export const CREATE_CANDIDATE = "candidate/update"
export const UPDATE_CANDIDATE = "candidate/update"
export const SHARE_CANDIDATE = "candidate/share"

export const DISCLOSE_CANDIDATE_DATA = "candidateContact/disclose-additional-data"
export const CANDIDATE_DATA_DISCLOSED = "candidateContact/additional-data-disclosed"

export const STATS_GET_CANDADATES_PER_REGION = "stats/get-candidates-per-region"

export const CREATE_COMPANY = "company/update"
export const UPDATE_COMPANY = "company/update"
