export const schemaDefinition = {
    actionToken: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            token: {
                type: "VARCHAR",
                nullable: false,
                length: 40
            },
            type: {
                type: "VARCHAR",
                nullable: false,
                length: 80
            },
            timestamp: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            },
            validUntil: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            },
            tokenData: {
                type: "JSONB",
                nullable: false,
                length: 0
            },
            formData: {
                type: "JSONB",
                nullable: false,
                length: 0
            }
        },
        relations: {},
        indexes: {}
    },
    authRole: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            parentId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 20
            }
        },
        relations: {
            _fk_authPermission_authRole: {
                column: "id",
                referencedBy: "authPermission.authRoleId"
            },
            fk_authRole_authRole: {
                column: "parentId",
                references: "authRole.id"
            },
            _fk_authRole_authRole: {
                column: "id",
                referencedBy: "authRole.parentId"
            },
            _fk_user_authRoleId: {
                column: "id",
                referencedBy: "user.authRoleId"
            }
        },
        indexes: {}
    },
    authPermission: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            authRoleId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 60
            }
        },
        relations: {
            fk_authPermission_authRole: {
                column: "authRoleId",
                references: "authRole.id"
            }
        },
        indexes: {}
    },
    candidate: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            uuid: {
                type: "VARCHAR",
                nullable: false,
                length: 36
            },
            gender: {
                type: "CHAR",
                nullable: false,
                length: 1
            },
            jobTitle: {
                type: "VARCHAR",
                nullable: true,
                length: 0
            },
            jobTitleIds: {
                type: "JSONB",
                nullable: false,
                length: 0
            },
            region: {
                type: "VARCHAR",
                nullable: true,
                length: 0
            },
            regionIds: {
                type: "JSONB",
                nullable: false,
                length: 0
            },
            educationLevelId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            workLevelId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            industry: {
                type: "VARCHAR",
                nullable: true,
                length: 0
            },
            industryIds: {
                type: "JSONB",
                nullable: false,
                length: 0
            },
            age: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            info: {
                type: "TEXT",
                nullable: true,
                length: 0
            },
            personalInfo: {
                type: "TEXT",
                nullable: true,
                length: 0
            },
            typeOfPerson: {
                type: "TEXT",
                nullable: true,
                length: 0
            },
            competences: {
                type: "TEXT",
                nullable: true,
                length: 0
            },
            motivation: {
                type: "TEXT",
                nullable: true,
                length: 0
            },
            salaryIndication: {
                type: "VARCHAR",
                nullable: true,
                length: 100
            },
            availability: {
                type: "VARCHAR",
                nullable: true,
                length: 100
            },
            drivingLicence: {
                type: "BOOLEAN",
                nullable: false,
                length: 0
            },
            firstName: {
                type: "VARCHAR",
                nullable: true,
                length: 45
            },
            infix: {
                type: "VARCHAR",
                nullable: true,
                length: 10
            },
            surname: {
                type: "VARCHAR",
                nullable: true,
                length: 64
            },
            street: {
                type: "VARCHAR",
                nullable: true,
                length: 128
            },
            houseNumber: {
                type: "VARCHAR",
                nullable: true,
                length: 10
            },
            zipCode: {
                type: "VARCHAR",
                nullable: true,
                length: 7
            },
            city: {
                type: "VARCHAR",
                nullable: true,
                length: 64
            },
            phone: {
                type: "VARCHAR",
                nullable: true,
                length: 15
            },
            email: {
                type: "VARCHAR",
                nullable: true,
                length: 255
            },
            vimeoLink: {
                type: "VARCHAR",
                nullable: true,
                length: 255
            },
            status: {
                type: "CHAR",
                nullable: false,
                length: 1
            },
            ownerId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            ppId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            timestampModified: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_candidate_educationLevel: {
                column: "educationLevelId",
                references: "educationLevel.id"
            },
            fk_candidate_ownerId: {
                column: "ownerId",
                references: "user.id"
            },
            _fk_candidate2jobTitle_candidateId: {
                column: "id",
                referencedBy: "candidate2jobTitle.candidateId"
            },
            _fk_candidate2region_candidateId: {
                column: "id",
                referencedBy: "candidate2region.candidateId"
            },
            _fk_candidate2industry_candidateId: {
                column: "id",
                referencedBy: "candidate2industry.candidateId"
            },
            _fk_candidate2functionArea_candidateId: {
                column: "id",
                referencedBy: "candidate2functionArea.candidateId"
            },
            _fk_candidateView_candidateId: {
                column: "id",
                referencedBy: "candidateView.candidateId"
            },
            _fk_candidateContact_candidateId: {
                column: "id",
                referencedBy: "candidateContact.candidateId"
            }
        },
        indexes: {}
    },
    candidateContact: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            source: {
                type: "VARCHAR",
                nullable: false,
                length: 1
            },
            token: {
                type: "VARCHAR",
                nullable: true,
                length: 36
            },
            timestamp: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            },
            candidateId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            userId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            sharedByUserId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 80
            },
            phone: {
                type: "VARCHAR",
                nullable: true,
                length: 15
            },
            email: {
                type: "VARCHAR",
                nullable: true,
                length: 128
            },
            remark: {
                type: "TEXT",
                nullable: true,
                length: 0
            }
        },
        relations: {
            fk_candidateContact_candidateId: {
                column: "candidateId",
                references: "candidate.id"
            },
            fk_candidateContact_userId: {
                column: "userId",
                references: "user.id"
            },
            fk_candidateContact_sharedByUserId: {
                column: "sharedByUserId",
                references: "user.id"
            }
        },
        indexes: {}
    },
    candidateView: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            token: {
                type: "VARCHAR",
                nullable: false,
                length: 36
            },
            timestampCreated: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            },
            candidateId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            userId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            contact: {
                type: "VARCHAR",
                nullable: true,
                length: 255
            },
            sharedByUserId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            timestampViewed: {
                type: "TIMESTAMPZ",
                nullable: true,
                length: 0
            },
            ip: {
                type: "VARCHAR",
                nullable: true,
                length: 36
            },
            userAgent: {
                type: "VARCHAR",
                nullable: true,
                length: 255
            }
        },
        relations: {
            fk_candidateView_candidateId: {
                column: "candidateId",
                references: "candidate.id"
            },
            fk_candidateView_userId: {
                column: "userId",
                references: "user.id"
            },
            fk_candidateView_sharedByUserId: {
                column: "sharedByUserId",
                references: "user.id"
            }
        },
        indexes: {}
    },
    candidate2functionArea: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            candidateId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            functionAreaId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_candidate2functionArea_candidateId: {
                column: "candidateId",
                references: "candidate.id"
            },
            fk_candidate2functionArea_functionAreaId: {
                column: "functionAreaId",
                references: "functionArea.id"
            }
        },
        indexes: {}
    },
    candidate2industry: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            candidateId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            industryId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_candidate2industry_candidateId: {
                column: "candidateId",
                references: "candidate.id"
            },
            fk_candidate2industry_industryId: {
                column: "industryId",
                references: "industry.id"
            }
        },
        indexes: {}
    },
    candidate2jobTitle: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            candidateId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            jobTitleId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_candidate2jobTitle_candidateId: {
                column: "candidateId",
                references: "candidate.id"
            },
            fk_candidate2jobTitle_jobTitleId: {
                column: "jobTitleId",
                references: "jobTitle.id"
            }
        },
        indexes: {}
    },
    candidate2region: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            candidateId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            regionId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_candidate2region_candidateId: {
                column: "candidateId",
                references: "candidate.id"
            },
            fk_candidate2region_regionId: {
                column: "regionId",
                references: "region.id"
            }
        },
        indexes: {}
    },
    city: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 100
            }
        },
        relations: {},
        indexes: {}
    },
    company: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            parentId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 64
            },
            v_street: {
                type: "VARCHAR",
                nullable: true,
                length: 45
            },
            v_houseNumber: {
                type: "VARCHAR",
                nullable: true,
                length: 10
            },
            v_zipCode: {
                type: "VARCHAR",
                nullable: true,
                length: 7
            },
            v_city: {
                type: "VARCHAR",
                nullable: true,
                length: 64
            },
            p_street: {
                type: "VARCHAR",
                nullable: true,
                length: 45
            },
            p_houseNumber: {
                type: "VARCHAR",
                nullable: true,
                length: 10
            },
            p_zipCode: {
                type: "VARCHAR",
                nullable: true,
                length: 7
            },
            p_city: {
                type: "VARCHAR",
                nullable: true,
                length: 64
            },
            phone: {
                type: "VARCHAR",
                nullable: true,
                length: 15
            },
            email: {
                type: "VARCHAR",
                nullable: true,
                length: 45
            },
            site: {
                type: "VARCHAR",
                nullable: true,
                length: 60
            },
            cocNo: {
                type: "VARCHAR",
                nullable: true,
                length: 20
            },
            taxNo: {
                type: "VARCHAR",
                nullable: true,
                length: 20
            }
        },
        relations: {
            fk_company_company: {
                column: "parentId",
                references: "company.id"
            },
            _fk_company_company: {
                column: "id",
                referencedBy: "company.parentId"
            },
            _fk_user_company: {
                column: "id",
                referencedBy: "user.companyId"
            }
        },
        indexes: {}
    },
    educationLevel: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 100
            },
            orderNo: {
                type: "INTEGER",
                nullable: false,
                length: 0
            }
        },
        relations: {
            _fk_candidate_educationLevel: {
                column: "id",
                referencedBy: "candidate.educationLevelId"
            }
        },
        indexes: {}
    },
    functionArea: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 100
            }
        },
        relations: {
            _fk_candidate2functionArea_functionAreaId: {
                column: "id",
                referencedBy: "candidate2functionArea.functionAreaId"
            }
        },
        indexes: {}
    },
    industry: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 100
            }
        },
        relations: {
            _fk_candidate2industry_industryId: {
                column: "id",
                referencedBy: "candidate2industry.industryId"
            }
        },
        indexes: {}
    },
    jobTitle: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 100
            }
        },
        relations: {
            _fk_candidate2jobTitle_jobTitleId: {
                column: "id",
                referencedBy: "candidate2jobTitle.jobTitleId"
            }
        },
        indexes: {}
    },
    region: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 100
            }
        },
        relations: {
            _fk_candidate2region_regionId: {
                column: "id",
                referencedBy: "candidate2region.regionId"
            }
        },
        indexes: {}
    },
    file: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            entityPrefix: {
                type: "VARCHAR",
                nullable: false,
                length: 10
            },
            refId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            fileSeq: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            fileSeqRev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            group: {
                type: "VARCHAR",
                nullable: false,
                length: 10
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 128
            },
            ext: {
                type: "VARCHAR",
                nullable: false,
                length: 10
            },
            derived: {
                type: "VARCHAR",
                nullable: true,
                length: 20
            },
            userId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            oName: {
                type: "VARCHAR",
                nullable: false,
                length: 128
            },
            contentType: {
                type: "VARCHAR",
                nullable: true,
                length: 128
            },
            charSet: {
                type: "VARCHAR",
                nullable: true,
                length: 32
            },
            fileSize: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            timestamp: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            },
            physicalFileDeleted: {
                type: "BOOLEAN",
                nullable: true,
                length: 0
            }
        },
        relations: {
            fk_file_user: {
                column: "userId",
                references: "user.id"
            }
        },
        indexes: {}
    },
    notification: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            uuId: {
                type: "VARCHAR",
                nullable: false,
                length: 45
            },
            userId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            type: {
                type: "VARCHAR",
                nullable: false,
                length: 50
            },
            timestampCreated: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            },
            sendInfo: {
                type: "JSONB",
                nullable: true,
                length: 0
            },
            readInfo: {
                type: "JSONB",
                nullable: true,
                length: 0
            },
            action: {
                type: "JSONB",
                nullable: false,
                length: 0
            },
            summary: {
                type: "JSONB",
                nullable: false,
                length: 0
            },
            renderedMessages: {
                type: "JSONB",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_notification_user: {
                column: "userId",
                references: "user.id"
            }
        },
        indexes: {}
    },
    notification_setting: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            userId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            type: {
                type: "VARCHAR",
                nullable: false,
                length: 60
            },
            medium: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            retention: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            showSummary: {
                type: "BOOLEAN",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_notificationsetting_user: {
                column: "userId",
                references: "user.id"
            }
        },
        indexes: {}
    },
    notification_setting_default: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            type: {
                type: "VARCHAR",
                nullable: false,
                length: 60
            },
            medium: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            retention: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            showSummary: {
                type: "BOOLEAN",
                nullable: false,
                length: 0
            }
        },
        relations: {},
        indexes: {}
    },
    notification_tpl: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            name: {
                type: "VARCHAR",
                nullable: false,
                length: 64
            },
            medium: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            type: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            refId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            locale: {
                type: "VARCHAR",
                nullable: false,
                length: 6
            },
            template: {
                type: "TEXT",
                nullable: true,
                length: 0
            },
            lastModified: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            }
        },
        relations: {},
        indexes: {}
    },
    revChange: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            userId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            type: {
                type: "VARCHAR",
                nullable: false,
                length: 40
            },
            refId: {
                type: "VARCHAR",
                nullable: true,
                length: 10
            },
            refIdStr: {
                type: "VARCHAR",
                nullable: true,
                length: 20
            },
            refRev: {
                type: "SMALLINT",
                nullable: false,
                length: 0
            },
            timestamp: {
                type: "TIMESTAMPZ",
                nullable: false,
                length: 0
            },
            data: {
                type: "JSONB",
                nullable: false,
                length: 0
            }
        },
        relations: {
            fk_revChange_user: {
                column: "userId",
                references: "user.id"
            }
        },
        indexes: {}
    },
    user: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            companyId: {
                type: "INTEGER",
                nullable: true,
                length: 0
            },
            email: {
                type: "VARCHAR",
                nullable: true,
                length: 128
            },
            gender: {
                type: "CHAR",
                nullable: false,
                length: 1
            },
            firstName: {
                type: "VARCHAR",
                nullable: true,
                length: 20
            },
            infix: {
                type: "VARCHAR",
                nullable: true,
                length: 10
            },
            surname: {
                type: "VARCHAR",
                nullable: true,
                length: 45
            },
            city: {
                type: "VARCHAR",
                nullable: true,
                length: 80
            },
            phone: {
                type: "VARCHAR",
                nullable: true,
                length: 15
            },
            authRoleId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            locale: {
                type: "VARCHAR",
                nullable: false,
                length: 5
            },
            active: {
                type: "BOOLEAN",
                nullable: false,
                length: 0
            },
            initials: {
                type: "VARCHAR",
                nullable: true,
                length: 10
            },
            ppId: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            companyName: {
                type: "VARCHAR",
                nullable: true,
                length: 255
            },
            lastLogin: {
                type: "TIMESTAMPZ",
                nullable: true,
                length: 0
            }
        },
        relations: {
            _fk_file_user: {
                column: "id",
                referencedBy: "file.userId"
            },
            _fk_revChange_user: {
                column: "id",
                referencedBy: "revChange.userId"
            },
            fk_user_authRoleId: {
                column: "authRoleId",
                references: "authRole.id"
            },
            fk_user_company: {
                column: "companyId",
                references: "company.id"
            },
            _fk_user_authentication_user: {
                column: "id",
                referencedBy: "user_authentication.id"
            },
            _fk_candidate_ownerId: {
                column: "id",
                referencedBy: "candidate.ownerId"
            },
            _fk_notification_user: {
                column: "id",
                referencedBy: "notification.userId"
            },
            _fk_notificationsetting_user: {
                column: "id",
                referencedBy: "notification_setting.userId"
            },
            _fk_candidateView_userId: {
                column: "id",
                referencedBy: "candidateView.userId"
            },
            _fk_candidateView_sharedByUserId: {
                column: "id",
                referencedBy: "candidateView.sharedByUserId"
            },
            _fk_candidateContact_userId: {
                column: "id",
                referencedBy: "candidateContact.userId"
            },
            _fk_candidateContact_sharedByUserId: {
                column: "id",
                referencedBy: "candidateContact.sharedByUserId"
            }
        },
        indexes: {}
    },
    user_authentication: {
        columns: {
            id: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            rev: {
                type: "INTEGER",
                nullable: false,
                length: 0
            },
            password: {
                type: "VARCHAR",
                nullable: true,
                length: 60
            },
            emailVerified: {
                type: "BOOLEAN",
                nullable: true,
                length: 0
            }
        },
        relations: {
            fk_user_authentication_user: {
                column: "id",
                references: "user.id"
            }
        },
        indexes: {}
    }
}