<ng-container
  sourceFilter [source]="source" [displayFilters]="displayFilters()"
>

  <div class="talents section">
    <div class="inner 2-columns">

      @if(!isMobile()) {
        <div class="column left">
        <form [formGroup]="form">

          <mat-search-input id="search" [source]="source" [appearance]="'outline'"></mat-search-input>

          <chips-input
            [placeholder]="'Functiegebied'"
            [filterField]="'candidate2functionArea.functionAreaId'"
            [source]="functionAreaSource"
          ></chips-input>

          <autocomplete
            [className]="'functionLevel'"
            [source]="functionLevelSource"
            [filterField]="'functionLevel'"
            [placeholder]="'Functieniveau'"
            [displayField]="'name'"
          ></autocomplete>

          <chips-input
            [placeholder]="'Functie'"
            [filterField]="'candidate2jobTitle.jobTitleId'"
            [source]="jobTitleSource"
          ></chips-input>

          <autocomplete
            [className]="'discStyle'"
            [source]="discStyleSource"
            [filterField]="'discStyle'"
            [placeholder]="'DISC stijl'"
            [displayField]="'name'"
          ></autocomplete>

          <autocomplete
            [source]="educationLevelSource"
            [filterField]="'educationLevelId'"
            [placeholder]="'Opleidingsniveau'"
          ></autocomplete>

          <autocomplete
            [source]="workLevelSource"
            [filterField]="'workLevelId'"
            [placeholder]="'Ervaring'"
            [displayField]="'name'"
          ></autocomplete>

          <chips-input
            [placeholder]="'Branche'"
            [filterField]="'candidate2industry.industryId'"
            [source]="industrySource"
          ></chips-input>

          <chips-input
            [placeholder]="'Regio'" [filterField]="'candidate2region.regionId'" [source]="regionSource"
          ></chips-input>

          <autocomplete
            [source]="availabilitySource"
            [filterField]="'availability'"
            [placeholder]="'Beschikbaarheid'"
            [displayField]="'name'"
          ></autocomplete>
        </form>
      </div>
      }

      <div class="column right">
        <!--        @if (source.status() === SourceStates.LOADING) {-->
        <!--          <div class="loading"></div>-->
        <!--        } @else {-->

        <div class="candidate-items">
          @if(isMobile()) {
            <div class="filter-mobile">
              <div>
                <div class="search-bar">
                  <mat-search-input
                    id="search2" class="search" [source]="source" [readonly]="searchReadonly()"
                  ></mat-search-input>

                  <button
                    mat-icon-button color="accent" class="toggle-filter-button" (click)="onToggleFilters()"
                  >
                    <mat-icon>
                      @if (displayFilters()) {
                        filter_list
                      } @else {
                        filter_list_off
                      }
                    </mat-icon>
                  </button>
                </div>


                <form [formGroup]="form">
                  <div class="filters" [ngClass]="{ 'expanded': displayFilters() }">
                    <chips-input
                      [placeholder]="'Functiegebied'"
                      [filterField]="'candidate2functionArea.functionAreaId'"
                      [source]="functionAreaSource"
                    ></chips-input>

                    <autocomplete
                      [className]="'functionLevel'"
                      [source]="functionLevelSource"
                      [filterField]="'functionLevel'"
                      [placeholder]="'Functieniveau'"
                      [displayField]="'name'"
                    ></autocomplete>

                    <chips-input
                      [placeholder]="'Functie'"
                      [filterField]="'candidate2jobTitle.jobTitleId'"
                      [source]="jobTitleSource"
                    ></chips-input>

                    <autocomplete
                      [className]="'discStyle'"
                      [source]="discStyleSource"
                      [filterField]="'discStyle'"
                      [placeholder]="'DISC stijl'"
                      [displayField]="'name'"
                    ></autocomplete>

                    <autocomplete
                      [source]="educationLevelSource"
                      [filterField]="'educationLevelId'"
                      [placeholder]="'Opleidingsniveau'"
                    ></autocomplete>

                    <autocomplete
                      [source]="workLevelSource"
                      [filterField]="'workLevelId'"
                      [placeholder]="'Ervaring'"
                      [displayField]="'name'"
                    ></autocomplete>

                    <chips-input
                      [placeholder]="'Branche'"
                      [filterField]="'candidate2industry.industryId'"
                      [source]="industrySource"
                    ></chips-input>

                    <chips-input
                      [placeholder]="'Regio'"
                      [filterField]="'candidate2region.regionId'"
                      [source]="regionSource"
                    ></chips-input>

                    <autocomplete
                      [source]="availabilitySource"
                      [filterField]="'availability'"
                      [placeholder]="'Beschikbaarheid'"
                      [displayField]="'name'"
                    ></autocomplete>

                  </div>
                </form>

              </div>
            </div>
          }

          @for (model of (source.dataSourceAsArray$ | async); track model; let index = $index) {
              <div id="candidate-{{model.uuid}}" class="candidate-item" [ngClass]="model.status">
                <div class="first-row">
                  <div class="profile-picture">
                    @if (model.ppId > 0) {
                      <img src="{{ baseFileName + '/file/' + model.ppId }}">
                    } @else {
                      <div class="placeholder">
                        <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M95.6244 54.8125C85.7806 56.875 78.5619 64.1875 76.6869 73.9687C75.0619 82.5312 78.5931 91.625 85.5931 96.9375C88.0306 98.7812 91.2494 100.375 94.0619 101.125C96.8431 101.906 103.124 101.906 105.937 101.125C112.562 99.3437 118.249 94.6875 121.249 88.5937C123.093 84.8437 123.562 82.6875 123.562 78.125C123.562 73.5625 123.093 71.4062 121.218 67.6562C118.156 61.5 112.437 56.8125 105.937 55.1562C103.156 54.4687 98.1556 54.2812 95.6244 54.8125ZM104.218 58.5937C111.468 60.125 117.499 65.9687 119.374 73.3125C122.093 83.875 115.499 94.7812 104.812 97.5C92.4056 100.687 79.9994 91 79.9994 78.125C79.9994 69.125 86.2806 61.0625 95.0619 58.75C97.8119 58.0312 101.249 57.9687 104.218 58.5937Z"
                            fill="black"
                          />
                          <path
                            d="M77.8122 105.594C71.0935 110.594 65.9685 118.344 63.8435 126.687C63.156 129.5 63.031 130.656 62.906 137.062C62.7497 144.125 62.7497 144.312 63.406 144.969L64.0622 145.625H99.9997H135.937L136.593 144.969C137.25 144.312 137.25 144.125 137.093 137.062C136.968 130.625 136.875 129.5 136.156 126.812C134.156 119.25 131.562 114.687 126.093 109.219C123.968 107.094 121.718 105.125 120.906 104.687C119.5 103.969 119.406 103.969 118.625 104.469C117.375 105.281 117.531 106.625 118.937 107.719C124.375 111.906 127.281 115.281 129.875 120.469C132.468 125.687 133.156 128.781 133.343 136L133.5 141.875H100.031H66.5622V137.062C66.5622 131.812 67.031 128.312 68.2497 124.781C70.1872 119.125 74.0622 113.437 78.3747 109.906C82.156 106.812 82.5622 106.344 82.3435 105.562C82.0622 104.656 81.3122 104.062 80.4997 104.062C80.1247 104.062 78.9372 104.75 77.8122 105.594Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    }
                  </div>
                  <div class="intro">
                    <h2>
                      <a href="/candidates{{model?.uuid}}" [routerLink]="['/candidates', model?.uuid]">
                        {{ model?.jobTitle }}
                      </a>
                    </h2>
                    <p class="subtitle">{{ model?.region }}</p>
                  </div>
                </div>
                <p>{{ (model?.info ?? '') | ellipsis:200 }}</p>
                <button
                  mat-flat-button color="accent" (click)="onClick(model.uuid, $event)"
                > Bekijk meer
                </button>
              </div>
          }
          @if ((source.dataSourceAsArray$ | async)?.length == 0) {
            <div class="candidate-item no-results">
              <h2>Geen kandidaat gevonden</h2>
              <p>Er is geen kandidaat die aan deze criteria voldoet. Verander de criteria om naar een passende
                kandidaat te zoeken.
              </p>
            </div>
          }

          <mat-paginator
            [dataSource]="source"
            [pageSizeOptions]="[5, 15, 10, 20, 50]"
            [length]="source.count$()"
            showFirstLastButtons
          ></mat-paginator>

        </div>
        <!--        }-->
      </div>
    </div>
  </div>
</ng-container>
