import { InjectionToken, Type } from "@angular/core";
import { Action } from "../core/event-bus/action";

export interface ActionTokenComponentConfig<T> {
  loader: () => Promise<Type<T>>,
  tokenType: string,
  onSuccess: {
    navigateTo: any[], toastMessage: string
  },
  initialReply?: ActionTokenRequiresMoreInformation
}

export type TokenSuccess = {
  statusCode: 200, type?: string, form?: string
}

export type ActionTokenSuccess = Action<TokenSuccess>

export function isActionTokenSuccess(token: ActionTokenSuccess | ActionTokenRequiresMoreInformation): token is ActionTokenSuccess {
  return token.data.statusCode === 200;
}

export interface TokenRequiresMoreInformation {
  statusCode: 409,
  token: string,
  form: string,

  [key: string]: any
}

export type ActionTokenRequiresMoreInformation = Action<TokenRequiresMoreInformation>;

export function isActionTokenRequiresMoreInformation(token: ActionTokenSuccess | ActionTokenRequiresMoreInformation): token is ActionTokenRequiresMoreInformation {
  return token.data.statusCode === 409;
}

export const ACTION_TOKEN_COMPONENT_CONFIGS = new InjectionToken<ActionTokenComponentConfig<unknown>[]>(
  "ActionTokenComponentConfigs");

export const ACTION_TOKEN_COMPONENT_CONFIGS_PROVIDER = {
  provide: ACTION_TOKEN_COMPONENT_CONFIGS,
  multi: true,
  useValue: undefined
};
