import { EqualOperator } from "./equal";
import { GreaterThanOperator } from "./greater-than";
import { GreaterThanOrEqualOperator } from "./greater-than-or-equal";
import { InOperator } from "./in";
import { LessThanOperator } from "./less-than";
import { LessThanOrEqualOperator } from "./less-than-or-equal";
import { LikeOperator } from "./like";
import { NorOperator } from "./nor";
import { NotOperator } from "./not";
import { NotEqualOperator } from "./not-equal";
import { NotInOperator } from "./not-in";
import { NotLikeOperator } from "./not-like";
import { NotNullOperator } from "./not-null";
import { NullOperator } from "./null";
import { Operator, EncodedOperator } from "./operator";
import { OrOperator } from "./or";
import { SearchOperator } from "./search";


export class OperatorEncoder {

  static encode(list: Operator[]): string {
    const items = list.map(operator => operator.definition());
    return JSON.stringify(items);
  }

  static decode(value: string): Operator[] {
    const json = JSON.parse(value) as EncodedOperator[];
    const list = json.map(obj => {
      return OperatorEncoder.decodeFilterItem(obj);
    });
    return list;
  }

  static asFields(operators: Operator[]): any {
    return operators
      .filter( operator => operator instanceof EqualOperator)
      .reduce( (acc, operator) => {
        acc[(operator as EqualOperator).field] = (operator as EqualOperator).value;
      return acc;
    }, {} as any);
  }

  private static decodeFilterItem(obj: EncodedOperator): Operator {
    switch (obj.o) {
      case "$and": return new EqualOperator(obj.f!, obj.v);
      case "$e":
        return new EqualOperator(obj.f!, obj.v);
      case "$gt":
        return new GreaterThanOperator(obj.f!, obj.v);
      case "$gte":
        return new GreaterThanOrEqualOperator(obj.f!, obj.v);
      case "$in":
        return new InOperator(obj.f!, obj.v);
      case "$lt":
        return new LessThanOperator(obj.f!, obj.v);
      case "$lte":
        return new LessThanOrEqualOperator(obj.f!, obj.v);
      case "$lk":
        return new LikeOperator(obj.f!, obj.v);
      // case "$nor": return new NorOperator(obj.f, obj.v);
      // case "$not": return new NotOperator(obj.f, obj.v);
      case "$ne":
        return new NotEqualOperator(obj.f!, obj.v);
      case "$nin":
        return new NotInOperator(obj.f!, obj.v);
      case "$nlk":
        return new NotLikeOperator(obj.f!, obj.v);
      case "$nnull":
        return new NotNullOperator(obj.f!);
      case "$null":
        return new NullOperator(obj.f!);
      // case "$or": return new OrOperator(obj.f);
      case "$s":
        return new SearchOperator(obj.f!, obj.v);
      default:
        throw new Error(`Unsupported operator ${obj.o}`);
    }
  }
}
