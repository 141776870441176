import { Component, Input } from "@angular/core";

@Component({
  selector: "form-display-mode",
  templateUrl: "./component.html"
})
export class FormDisplayComponent {

  @Input({required: true}) displayMode!: string;
  @Input() editable: boolean = true;

}
