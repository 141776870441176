import { NgModule } from "@angular/core";
import { PermissionDirective } from "./directive";

@NgModule({
	declarations: [
    PermissionDirective
	],
	exports: [
    PermissionDirective
	],

	imports: [
	]
})
export class PropeldPermissionDirectiveModule {
}
