export interface BreakpointDefinition {
  name: string,
  minWidth?: number,
  maxWidth?: number,
  portrait?: boolean,
  landscape?: boolean,
  query?: string
}

export const Breakpoint = {
  XSmall: {
    name: "XSmall",
    maxWidth: 600,
    query: "(max-width: 599.98px)"
  },
  Small: {
    name: "Small",
    minWidth: 600,
    maxWidth: 960,
    query: "(min-width: 600px) and (max-width: 959.98px)"
  },
  Medium: {
    name: "Medium",
    minWidth: 960,
    maxWidth: 1280,
    query: "(min-width: 960px) and (max-width: 1279.98px)"
  },
  Large: {
    name: "Large",
    minWidth: 1280,
    maxWidth: 1920,
    query: "(min-width: 1280px) and (max-width: 1919.98px)"
  },
  XLarge: {
    // XLarge, //
    name: "XLarge",
    minWidth: 1920,
    query: "(min-width: 1920px)"
  },
  // Handset: {
  //   // Handset,
  //   // (max-width: 599.98px) and (orientation: portrait)
  //   // (max-width: 959.98px) and (orientation: landscape)
  //   name: "Handset",
  //   maxWidth: 600
  // },
  // Tablet: {
  //   // Tablet, // (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)
  //   // (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)
  //   name: "Tablet",
  //   minWidth: 600,
  //   maxWidth: 840,
  //   portrait: true
  // },
  // Web: {
  //   // Web,  // (min-width: 840px) and (orientation: portrait)
  //   // (min-width: 1280px) and (orientation: landscape)
  //   name: "Web",
  //   minWidth: 0,
  //   maxWidth: 600
  // },
  // HandsetPortrait: {
  //   //HandsetPortrait, // (max-width: 599.98px) and (orientation: portrait)
  //   name: "HandsetPortrait",
  //   minWidth: 0,
  //   maxWidth: 600
  // },
  // TabletPortrait: {
  //   // TabletPortrait, // (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)
  //   name: "TabletPortrait",
  //   minWidth: 0,
  //   maxWidth: 600
  // },
  // WebPortrait: {
  //   //WebPortrait,  // (min-width: 840px) and (orientation: portrait)
  //   name: "WebPortrait",
  //   minWidth: 0,
  //   maxWidth: 600
  // },
  // HandsetLandscape: {
  //   // HandsetLandscape, // (max-width: 959.98px) and (orientation: landscape)
  //   name: "HandsetLandscape",
  //   minWidth: 0,
  //   maxWidth: 600
  // },
  // TabletLandscape: {
  //   // TabletLandscape, // (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)
  //   name: "TabletLandscape",
  //   minWidth: 0,
  //   maxWidth: 600
  // },
  // WebLandscape: {
  //   // WebLandscape, // (min-width: 1280px) and (orientation: landscape)
  //   name: "WebLandscape",
  //   minWidth: 0,
  //   maxWidth: 600
  // }
};













