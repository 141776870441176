import { AsyncPipe, NgForOf } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { AutocompleteInputComponentLib } from "./component";

@NgModule({
	declarations: [
    AutocompleteInputComponentLib
	],
	exports: [
    AutocompleteInputComponentLib
	],
  imports: [
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatChipsModule,
    MatSelectModule,
    AsyncPipe,
    MatAutocompleteModule,
    NgForOf
  ]
})
export class PropeldAutocompleteInputModule {
}
