import { NgClass } from "@angular/common";
import { Component, computed, effect, inject, input, model, OnInit, signal } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from "@angular/router";
import { Store } from "@ngrx/store";
import { EventBus } from "../../../lib/core/event-bus/event-bus";
import { AppState } from "../../../lib/service/app-state/app-state";
import { GUEST_USER_ID } from "../../core/model/account/model";
import { AccountService } from "../../service/account-service/account-service";

/** Error when invalid control is dirty, touched, or submitted. */
@Component({
  standalone: true,
  selector: "website-layout",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
  imports: [NgClass, RouterLink, MatIcon, RouterOutlet, MatButton]
})
export class WebsiteLayoutComponent implements OnInit {

  isMenuOpened = signal<Boolean>(false);
  showFooter = model<Boolean>(true);
  private store = inject(Store);
  eventBus = inject(EventBus);
  router = inject(Router)
  activatedRoute = inject(ActivatedRoute)
  accountService = inject(AccountService);
  appState = inject(AppState);
  isMobile = computed( () => this.appState.state.isMobile());

  constructor() {
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe({
      next: (data: any) => {
        this.showFooter.set(data.showFooter ?? true);
      }
    });
  }

  logout($event: Event) {
    $event.preventDefault();
    this.accountService.logout();
  }

  toggleMenu() {
    this.isMenuOpened.set(!this.isMenuOpened());
    if(this.isMenuOpened()) {

    }
  }

  onContactBtn($event: MouseEvent) {
    $event.preventDefault();
    this.router.navigate(["https://commercieelnederland.nl/contact/"]);
  }

  protected readonly GUEST_USER_ID = GUEST_USER_ID;
}
