import { NgModule } from "@angular/core";

@NgModule({
	imports: [

	], providers: [

	]
})
export class PlaceholderCoreModule {

}
