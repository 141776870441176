import {
  BaseModel, DataModel, DataModelId, DataModelMap, NullableString
} from "../../../../lib/store/model/dataModel";
import { Account } from "../account/model";
import { User } from "../user/model";

export type CandidateContactStore = DataModelMap<CandidateContactModel>;

export const initialCandidateContactStore: CandidateContactStore = new Map<DataModelId, CandidateContactModel>();

export interface CandidateContactData extends DataModel {
  source: string;
  token?: string;
  timestamp: string;
  candidateId: number;
  userId: number;
  sharedByUserId?: number;
  additionalDataDisclosed: boolean;
  additionalDataDisclosedUserId?: number;
  name: string;
  phone: NullableString;
  email: NullableString;
  remark: NullableString;

  user: User | null;
  sharedBy: User | null;
}

export interface CandidateContactModel extends CandidateContactData, DataModel {
  clone(data?: any): CandidateContact;
  toJson(): object;
}

export class CandidateContact extends BaseModel implements CandidateContactModel {

  readonly source: string;
  readonly token?: string;
  readonly timestamp: string;
  readonly candidateId: number;
  readonly userId: number;
  readonly sharedByUserId?: number;
  readonly additionalDataDisclosed: boolean;
  readonly additionalDataDisclosedUserId?: number;
  readonly name: string;
  readonly phone: NullableString;
  readonly email: NullableString;
  readonly remark: NullableString;

  readonly user: User | null;
  readonly sharedBy: User | null;

  constructor(data: CandidateContactModel) {
    super(data.id, data.rev);
    this.source = data.source;
    this.token = data.token;
    this.timestamp = data.timestamp;
    this.candidateId = data.candidateId;
    this.userId = data.userId;
    this.sharedByUserId = data.sharedByUserId;
    this.additionalDataDisclosed = data.additionalDataDisclosed;
    this.additionalDataDisclosedUserId = data.additionalDataDisclosedUserId;
    this.name = data.name;
    this.phone = data.phone;
    this.email = data.email;
    this.remark = data.remark;

    this.user = data.user;
    this.sharedBy = data.user;
  }

  /**
   * Clones a city and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): CandidateContact {
    data = data ? data : {};

    return new CandidateContact({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      source: Object.hasOwn(data, "source") ? data.source : this.source, //
      token: Object.hasOwn(data, "token") ? data.token : this.token, //
      timestamp: Object.hasOwn(data, "timestamp") ? data.timestamp: this.timestamp, //
      candidateId: Object.hasOwn(data, "candidateId") ? data.candidateId : this.candidateId, //
      userId: Object.hasOwn(data, "userId") ? data.userId : this.userId, //
      sharedByUserId: Object.hasOwn(data, "sharedByUserId") ? data.sharedByUserId : this.sharedByUserId, //
      additionalDataDisclosed: Object.hasOwn(data, "additionalDataDisclosed") ? data.additionalDataDisclosed : this.additionalDataDisclosed, //
      additionalDataDisclosedUserId: Object.hasOwn(data, "additionalDataDisclosedUserId") ? data.additionalDataDisclosedUserId : this.additionalDataDisclosedUserId, //
      name: Object.hasOwn(data, "name") ? data.name : this.name, //
      phone: Object.hasOwn(data, "phone") ? data.phone : this.phone, //
      email: Object.hasOwn(data, "email") ? data.email : this.email, //
      remark: Object.hasOwn(data, "remark") ? data.remark : this.remark, //

      user: Object.hasOwn(data, "user") ? data.user : this.user, //
      sharedBy: Object.hasOwn(data, "sharedBy") ? data.sharedBy : this.sharedBy //
    } as CandidateContactModel);
  }

  get displayName(): string {
    return this.source + this.token;
  };
}
