import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import {
  MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable
} from "@angular/material/table";
import { RouterModule, Routes } from "@angular/router";
import { PropeldChipsInputModule } from "../../../lib/component/chips-input/module";
import { PropeldFormDisplayModule } from "../../../lib/component/form-display/module";
import { PropeldAutocompleteInputModule } from "../../../lib/component/form-select/module";
import { PropeldSearchInputModule } from "../../../lib/component/mat-search-input/module";
import { PropeldComponentModule } from "../../../lib/component/module";
import { PropeldFileUploadModule } from "../../../lib/component/upload/module";
import { PropeldMatTableSortModule } from "../../../lib/directive/mat-table-source/module";
import { PropeldAppStateModule } from "../../../lib/service/app-state/module";
import { SourceFilterModule } from "../../../lib/source-filter/module";
import { EllipsisPipe } from "../../../lib/util/ellipsis";
import { SafePipe } from "../../../lib/util/safe.pipe";
import { WrapCommaPipe } from "../../../lib/util/wrap-on-comma";
import { CandidateContactForm } from "../../component/candidate-contact-form/component";
import { CandidateComponentModule } from "../../component/candidate/module";
import { PublicCandidateDetailComponent } from "../../component/candidate/public-detail-component/component";
import { AutoCompleteComponent } from "../../component/filter/controls/autocomplete/component";
import { PublicCandidateDetailPageComponent } from "./public-detail-page/component";
import { PublicCandidateListPageComponent } from "./public-list-page/component";

const routes: Routes = [
  {
    path: "",
    component: PublicCandidateListPageComponent
  }, {
    path: ":uuid",
    component: PublicCandidateDetailPageComponent
    // ,
    // canDeactivate: [PendingChangesGuard]
  }
];

@NgModule({
	declarations: [
    PublicCandidateListPageComponent,
    PublicCandidateDetailPageComponent
	],

	exports: [

	],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,

    MatIconModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatInputModule,
    CandidateComponentModule,
    PropeldAppStateModule,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatSort,
    MatSortHeader,
    MatTable,
    PropeldComponentModule,
    PropeldMatTableSortModule,
    EllipsisPipe,
    MatProgressBar,
    PropeldFormDisplayModule,
    PropeldAutocompleteInputModule,
    CandidateContactForm,
    WrapCommaPipe,
    SafePipe,
    PropeldFileUploadModule,
    PublicCandidateDetailComponent,
    AutoCompleteComponent,
    PropeldChipsInputModule,
    SourceFilterModule,
    PropeldSearchInputModule
  ]
})
export class PublicCandidateModule {
}

