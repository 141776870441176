import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { initialProjectStore, Project, ProjectModel } from "./model";

export const PROJECT_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("ProjectReducer");

export function projectReducerProvider() {
	return reducerFactory<ProjectModel>("project", initialProjectStore, (data: ProjectModel) => {
		return new Project(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("project", PROJECT_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("project"),

	], providers: [
		{
			provide: PROJECT_REDUCER_TOKEN,
			useFactory: projectReducerProvider
		}
	]
})
export class ProjectCoreModule {

}
