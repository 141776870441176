import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { Action } from "../../../../lib/core/event-bus/action";
import { DataModelMap } from "../../../../lib/store/model/dataModel";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { Reducer } from "../../../../lib/store/reducer/reducer";
import { CandidateContact, CandidateContactModel, initialCandidateContactStore } from "./model";

export const CANDIDATECONTACT_REDUCER_TOKEN =
  new InjectionToken<ActionReducerMap<any>>("CandidateContactReducer");

export function candidateContactReducerProvider() {
	return new CandidateContactReducer("candidateContact", initialCandidateContactStore, (data: CandidateContactModel) => {
		return new CandidateContact(data);
	}).reducer;
}

//
// export function accountReducerProvider() {
//   return new AccountReducer("account", initialAccountStore, (data: AccountModel) => {
//     if(data == null) return Account.placeholder();
//     return new Account(data);
//   }).reducer;
// }

export class CandidateContactReducer extends Reducer<CandidateContact> {

  constructor(
    entity: string,
    initialValue: DataModelMap<CandidateContact>,
    creatorFn: (data: CandidateContact) => CandidateContact
  ) {
    super(entity, initialValue, creatorFn)
  }

  override checkAdditional(draft: DataModelMap<CandidateContact>, action: Action<CandidateContact>) {
    switch (action.type) {

      case 'candidateContact/additional-data-disclosed': {
        this.onReceived(draft, action as any);
        break;
      }
    }
  }
}

@NgModule({
	imports: [
		StoreModule.forFeature("candidateContact", CANDIDATECONTACT_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("candidateContact"),

	], providers: [
		{
			provide: CANDIDATECONTACT_REDUCER_TOKEN,
			useFactory: candidateContactReducerProvider
		}
	]
})
export class CandidateContactCoreModule {

}
