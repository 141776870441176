import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./component/layout/component";
import { LoginComponent } from "./component/login/component";
import { ResetPasswordComponent } from "./component/login/password-reset/component";
import { WebsiteLayoutComponent } from "./component/website-layout/component";
import { authorizationGuard } from "./guard/authorization-guard/authorization-guard";
import { loginGuard } from "./guard/authorization-guard/login-guard";
import { RegisterPage } from "./route/register/component";

const routes: Routes = [

  {
    path: "candidates",
    component: WebsiteLayoutComponent,
    canActivate: [authorizationGuard],
    loadChildren: () => import("./route/public/module").then(m => m.PublicCandidateModule)
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [authorizationGuard],
    children: [
      {
        path: "dashboard",
        canActivate: [authorizationGuard],
        loadChildren: () => import("./route/dashboard/module").then(m => m.DashboardModule)
      }, {
        path: "account",
        canActivate: [authorizationGuard],
        loadChildren: () => import("./route/account/module").then(m => m.AccountModule)
      }, {
        path: "user",
        canActivate: [authorizationGuard],
        loadChildren: () => import("./route/user/module").then(m => m.UserModule)
      }, {
        path: "candidate",
        canActivate: [authorizationGuard],
        loadChildren: () => import("./route/candidate/module").then(m => m.CandidateModule)
      }, {
        path: "company",
        canActivate: [authorizationGuard],
        loadChildren: () => import("./route/company/module").then(m => m.CompanyModule)
      }, {
        path: "education-level",
        canActivate: [authorizationGuard],
        loadChildren: () => import("./route/educationLevel/module").then(m => m.EducationLevelModule)
      }, {
        path: "job-title",
        canActivate: [authorizationGuard],
        loadChildren: () => import("./route/jobTitle/module").then(m => m.JobTitleModule)
      }
    ]
  },
  {
    path: "t",
    component: WebsiteLayoutComponent,
    data: { showFooter: false },
    loadChildren: () => import("../lib/token/module").then(mod => mod.PropeldTokenModule)
  },
  {
    path: "login",
    component: WebsiteLayoutComponent,
    canActivate: [loginGuard],
    children: [
      {
        path: "",
        component: LoginComponent
      }
    ]
  }, {
    path: "reset-password",
    component: WebsiteLayoutComponent,
    children: [
      {
        path: "",
        component: ResetPasswordComponent
      }
    ]
  }, {
    path: "register",
    component: WebsiteLayoutComponent,
    data: { showFooter: false },
    children: [
      {
        path: "",
        component: RegisterPage
      }
    ]
  }, {
    path: "**",
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
