import { AfterViewInit, Component, DestroyRef, inject, signal } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { first } from "rxjs/operators";
import { EventBus } from "../../../../lib/core/event-bus/event-bus";
import { Message } from "../../../../lib/core/event-bus/message/message";
import { Socket, SocketStates } from "../../../../lib/core/socket/socket";
import { SocketConnector } from "../../../../lib/core/socket/socket-connector";
import { SnackbarService } from "../../../../lib/service/snackbar/service/snackbar.service";
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "login",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ResetPasswordComponent  {

  private snackbar = inject(SnackbarService);

  isInvalidLogin = signal<Boolean>(false);

  emailFormControl = new FormControl("", [Validators.required, Validators.email]);

  form = new FormGroup({
    email: this.emailFormControl,
  });

  matcher = new MyErrorStateMatcher();

  private eventBus: EventBus = inject(EventBus);
  private socket: Socket = inject(Socket);
  private router = inject(Router);

  constructor() {
  }

  login() {
    if (!this.form.valid) {
      return;
    }

    this.snackbar.info("Er is een mail gestuurd om het wachtwoord te resetten.", 10)

    this.eventBus.requestAction("user/request-password-reset", this.form.value).subscribe({
      next: (message: Message<any>) => {
        this.router.navigateByUrl("/login").catch((error) => alert(error.message));
      },
      error: (error) => {
      }
    });

    if (this.form.invalid) {
      return;
    }
  }
}
