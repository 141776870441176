import {
  AfterContentInit,
  AfterViewInit,
  Component, computed,
  DestroyRef,
  effect,
  inject,
  OnDestroy,
  signal,
  ViewChild
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { AppActionsStore } from "../../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { SourceFilterState } from "../../../../lib/source-filter/source-filter-state";
import { SortDirection, Source, SourceLoadingStates } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { SourcePageStateStore } from "../../../../lib/source/state/source-page-state-store";
import { Candidate } from "../../../core/model/candidate/model";
import { Company } from "../../../core/model/company/model";
import {
  CandidateAvailabilityOptions,
  CandidateStatus,
  DiscStyleOptions,
  FunctionLevelOptions,
  getCandidateAvailabilityName,
  WorkLevelOptions
} from "../../../core/model/constants";
import { FunctionArea } from "../../../core/model/functionArea/model";
import { Industry } from "../../../core/model/industry/model";
import { JobTitle } from "../../../core/model/jobTitle/model";
import { Region } from "../../../core/model/region/model";
import { AccountService } from "../../../service/account-service/account-service";

/**
 * Zoeken
 * Functiegebeid (Sales / ...)
 * Functieniveau (management/ope)
 * Functie
 * DISC stijl
 * Opleidingsniveau
 * Ervaring
 * Branche
 * Regio
 * Beschikbaarheid
 */

@Component({
  selector: "public-candidate-list-page",
  templateUrl: "./component.html",
  styleUrls: [
    "./component.scss", "../../../../assets/styling/styles.scss"
  ]
})
export class PublicCandidateListPageComponent implements AfterContentInit, AfterViewInit, OnDestroy {

  cardId = "candidate/public-list";

  /**
   *  Housekeeping variables
   */
  accountService = inject(AccountService);
  appState = inject(AppState);
  appActions = inject(AppActionsStore);
  private destroyRef = inject(DestroyRef);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private sourceStateStore = inject(SourcePageStateStore);
  baseFileName = environment.httpEndpoint;

  /**
   *  Source related
   */
  private sourceBuilder = inject(SourceBuilder);
  source: Source<Candidate> = this.sourceBuilder
    .builder("candidate")
    .setName("publicCandidateListSource")
    .setInitialFilter(this.sourceStateStore.getState("publicCandidateListSource", {
      pageSize: 5,
      sort: [
        {
          field: "id",
          direction: SortDirection.DESC
        }
      ]
    }))
    .build();

  educationLevelSource: Source<Company> = this.sourceBuilder
    .builder("educationLevel")
    .setName("educationLevelSource")
    .build();
  jobTitleSource: Source<JobTitle> = this.sourceBuilder
    .builder("jobTitle")
    .setName("jobTitleSource")
    .setInitialFilter({
      name: "jobTitleSource",
      offset: 0,
      limit: 100,
      pageNo: 0,
      pageSize: 100,
      search: undefined,
      sort: [
        {
          field: "name",
          direction: SortDirection.ASC
        }
      ],
      filter: null,
      expandedRows: null
    })
    .build();
  regionSource: Source<Region> = this.sourceBuilder
    .builder("region")
    .setName("regionSource")
    .setInitialFilter({
      name: "regionSource",
      offset: 0,
      limit: 100,
      pageNo: 0,
      pageSize: 100,
      search: undefined,
      sort: [
        {
          field: "name",
          direction: SortDirection.ASC
        }
      ],
      filter: null,
      expandedRows: null
    })
    .build();
  functionAreaSource: Source<FunctionArea> = this.sourceBuilder
    .builder("functionArea")
    .setName("functionAreaSource")
    .build();
  industrySource: Source<Industry> = this.sourceBuilder
    .builder("industry")
    .setName("industrySource")
    .setInitialFilter({
      name: "industrySource",
      offset: 0,
      limit: 100,
      pageNo: 0,
      pageSize: 100,
      search: undefined,
      sort: [
        {
          field: "name",
          direction: SortDirection.ASC
        }
      ],
      filter: null,
      expandedRows: null
    })
    .build();
  availabilitySource: Source<any> = this.sourceBuilder
    .builder("*availability")
    .setName("availabilitySource")
    .setItems(CandidateAvailabilityOptions)
    .build();
  discStyleSource: Source<any> = this.sourceBuilder
    .builder("*discStyle")
    .setName("discStyleSource")
    .setItems(DiscStyleOptions)
    .build();
  functionLevelSource: Source<any> = this.sourceBuilder
    .builder("*functionLevel")
    .setName("functionLevel")
    .setItems(FunctionLevelOptions)
    .build();
  workLevelSource: Source<any> = this.sourceBuilder
    .builder("*workLevel")
    .setName("workLevelSource")
    .setItems(WorkLevelOptions)
    .build();

  form = new FormGroup({
    educationLevelFilter: new FormControl(0)
  });

  protected readonly SourceStates = SourceLoadingStates;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor() {
    const displayFilters = (this.source.getState() as SourceFilterState).displayFilters;
    this.displayFilters.set(displayFilters ?? false);
    this.source.setRequiredFilter({
        "$and": [
          {
            "status": {
              "$e": "A"
            }
          }
        ]
    });

    effect(() => {
      const x = this.source.loadingStatus$();
      if(x === SourceLoadingStates.IDLE) {
        const scrollY = (window as any)["scrollPos"];
        setTimeout(() => {
          if (scrollY !== undefined) {
            window.scrollTo({
              top: scrollY,
              behavior: 'smooth'
            });
          }
          this.searchReadonly.set(false);
          (window as any)["scrollPos"] = undefined;
        }, 300);
      }
    });
  }

  clickedUuid: string | undefined = undefined;
  searchVisible = signal<boolean>(true);
  searchReadonly = signal<boolean>(true);
  displayFilters = signal<boolean>(false);
  isMobile = computed( () => this.appState.state.isMobile());

  onClick(uuid: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.clickedUuid = uuid;

    this.router.navigate(["/candidates", uuid])
      .catch((error) => alert(error.message));
  }

  ngOnDestroy() {
    this.sourceStateStore.setState(this.source.name, this.source.getState());

    if (this.appState.searchSource$() != null) {
      this.appState.clearSearchSource();
    }

    window.scrollY;
    (window as any)["scrollPos"] = window.scrollY;
    this.sourceStateStore.setState(this.source.name, this.source.getState());
  }

  ngOnInit() {

  }

  onToggleFilters() {
    this.displayFilters.set(!this.displayFilters());
    if(!this.displayFilters()) {
      this.source.setFilter(null);
    }
  }

  ngAfterViewInit(): void {
    // const scrollY = (window as any)["scrollPos"];
    // if(scrollY !== undefined) {
    //   debugger;
    //   window.scrollTo({
    //     top: scrollY,
    //     behavior: 'smooth'
    //   });
    //   (window as any)["scrollPos"] = undefined;
    //   // this.searchReadonly.set(false)
    // }
  }

  ngAfterContentInit() {
    // const scrollY = (window as any)["scrollPos"];
    // if(scrollY !== undefined) {
    //   debugger;
    //   window.scrollTo({
    //     top: scrollY,
    //     behavior: 'smooth'
    //   });
    //   (window as any)["scrollPos"] = undefined;
    //   // this.searchReadonly.set(false)
    // }
  }

  protected readonly CandidateStatus = CandidateStatus;
  protected readonly getCandidateAvailabilityName = getCandidateAvailabilityName;
}
