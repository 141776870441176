import {
  BaseModel,
  DataModel,
  DataModelId,
  DataModelMap,
  DataModelRevision,
  ModelData,
  NullableNumber,
  NullableString
} from "../../../../lib/store/model/dataModel";
import { AuthRole } from "../authRole/model";
import { Company } from "../company/model";

export type AccountStore = DataModelMap<AccountModel>;

export const GUEST_USER_ID = 2;

export const initialAccountStore: AccountStore = new Map<DataModelId, AccountModel>();

export interface AccountData extends ModelData {
  firstName?: NullableString,
  infix?: NullableString,
  surname?: NullableString,
  email: string,
  companyId?: NullableNumber,
  companyName?: NullableString;
  gender: string,
  city?: NullableString,
  phone?: NullableString,
  authRoleId: number,
  locale: string,
  active: boolean,
  initials: string,
  ppId: number,
  lastLogin: string,
  wasAdmin: boolean,

  company?: Company | null;
  authRole?: AuthRole | null;
	permissions: string[];
}

export type AccountDataItems = { items: AccountData[] };

export interface AccountModel extends AccountData, DataModel {
  clone(data?: any): Account;
  hasPermission(name: string): boolean;
  hasAnyPermission(permissions: string[]): boolean;
  toJson(): object;
}

export class Account extends BaseModel implements AccountModel {

	// readonly id: DataModelId;
	// readonly rev: DataModelRevision;
	readonly email: string;
  readonly companyId?: NullableNumber;
  readonly companyName?: NullableString;
  readonly firstName?: NullableString;
	readonly infix?: NullableString;
	readonly surname?: NullableString;
	readonly initials: string;
	readonly gender: string;
	readonly phone?: NullableString;
	readonly locale: string;
	readonly authRoleId: number;
	readonly active: boolean;
	readonly wasAdmin: boolean;
	readonly ppId: number;
  readonly lastLogin: string;
	readonly permissions: string[];
  readonly company?: Company | null;
  readonly authRole?: AuthRole | null;

	constructor(data: AccountData) {
    super(data.id, data.rev);
		// this.id = data.id;
		// this.rev = data.rev;
		this.email = data.email;
    this.companyId = data.companyId;
    this.companyName = data.companyName;
		this.firstName = data.firstName;
		this.infix = data.infix;
		this.surname = data.surname;
		this.initials = data.initials;
		this.gender = data.gender;
		this.phone = data.phone;
		this.locale = data.locale;
		this.authRoleId = data.authRoleId;
		this.active = data.active;
		this.wasAdmin = data.wasAdmin;
		this.ppId = data.ppId;
    this.lastLogin = data.lastLogin;
		this.permissions = data.permissions;
    this.company = data.company;
    this.authRole = data.authRole;
	}

  static placeholder(): Account {
    return new Account({
      id: 0,
      rev: -1,
      email: "",
      companyId: null,
      companyName: null,
      firstName: null,
      infix: null,
      surname: null,
      initials: "",
      // companyId: null,
      gender: "U",
      // city: null,
      phone: null,
      locale: "NL",
      authRoleId: 10,
      active: false,
      wasAdmin: false,
      ppId: 0,
      lastLogin: "",
      permissions: [],
      company: null,
      authRole: null,
    });
  }

	/**
	 * Clones a project and applies given properties in the data parameter
	 *
	 * @param data
	 */
	clone(data?: any): Account {
    data = data ? data : {};
		return new Account({
			id: this.id, //
			rev: Object.hasOwn(data, "rev") ? data.rev as DataModelRevision : this.rev,  //
      email: Object.hasOwn(data, "email") ? data.email : this.email,
      companyId: Object.hasOwn(data, "companyId") ? data.companyId : this.companyId,
      companyName: Object.hasOwn(data, "companyName") ? data.companyName : this.companyName,
      firstName: Object.hasOwn(data, "firstName") ? data.firstName : this.firstName,
      infix: Object.hasOwn(data, "infix") ? data.infix : this.infix,
      surname: Object.hasOwn(data, "surname") ? data.surname : this.surname,
      initials: Object.hasOwn(data, "initials") ? data.initials : this.initials,
      gender: Object.hasOwn(data, "gender") ? data.gender : this.gender,
      phone: Object.hasOwn(data, "phone") ? data.phone : this.phone,
      locale: Object.hasOwn(data, "locale") ? data.locale : this.locale,
      authRoleId: Object.hasOwn(data, "authRoleId") ? data.authRoleId : this.authRoleId,
      active: Object.hasOwn(data, "active") ? data.active : this.active,
      wasAdmin: Object.hasOwn(data, "wasAdmin") ? data.wasAdmin : this.wasAdmin,
      ppId: Object.hasOwn(data, "ppId") ? data.ppId : this.ppId,
      lastLogin: Object.hasOwn(data, "lastLogin") ? data.lastLogin : this.lastLogin,
			permissions: Object.hasOwn(data, "permissions") ? data.permissions as string[] : this.permissions, //
      authRole: Object.hasOwn(data, "authRole") ? data.authRole : this.authRole,
      company: Object.hasOwn(data, "company") ? data.company : this.company
		});
	}

	override get displayName(): string {
		const v = [];
		if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
		if (this.infix != null && this.infix !== "") v.push(this.infix);
		if (this.surname != null && this.surname !== "") v.push(this.surname);
		return v.join(" ");
	};

  // get isLoggedIn(): boolean {
	// 	return this.authRoleId > GUEST_USER_ID;
	// }
  //
	hasPermission(permission: string): boolean {
		return this.permissions.includes(permission);
	}
  //
	// hasAllPermissions(permissions: string[]): boolean {
	// 	return this.containsAllPermissions(permissions, this.permissions);
	// }
  //
	hasAnyPermission(permissions: string[]): boolean {
    return permissions != null && permissions.some(permission => (this.permissions.includes(permission)));
	}
  //
	// private containsPermission(permission: string, list: string[]): boolean {
	// 	return list != null && list.indexOf(permission) !== -1;
	// }
  //
	// private containsAllPermissions(permissions: string[], list: string[]): boolean {
	// 	return list != null && permissions.every(permission => (list.includes(permission)));
	// }
  //
	// private containsAnyPermission(permissions: string[], list: string[]): boolean {
	//
	// }
}

