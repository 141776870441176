import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule, Routes } from "@angular/router";
import { PropeldAppStateModule } from "../../../lib/service/app-state/module";
import { LoginComponent } from "./component";
import { ResetPasswordComponent } from "./password-reset/component";

@NgModule({
  declarations: [
    LoginComponent, ResetPasswordComponent
  ],

  exports: [
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatIconModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatInputModule,
    PropeldAppStateModule
  ]
})
export class LoginModule {
}

