import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { Action } from "../../../../lib/core/event-bus/action";
import { DataModelMap, DataModelRevision } from "../../../../lib/store/model/dataModel";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { Reducer } from "../../../../lib/store/reducer/reducer";
import { Candidate, CandidateModel, initialCandidateStore } from "./model";

export const CANDIDATE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CandidateReducer");

export function candidateReducerProvider() {
  return new CandidateReducer("candidate", initialCandidateStore, (data: CandidateModel) => {
    if(data == null || data.id == null) {
      return Candidate.placeholder();
    }
    return new Candidate(data);
  }).reducer;
}

export class CandidateReducer extends Reducer<Candidate> {

  constructor(
    entity: string,
    initialValue: DataModelMap<Candidate>,
    creatorFn: (data: Candidate) => Candidate
  ) {
    super(entity, initialValue, creatorFn)
  }

  override checkAdditional(draft: DataModelMap<Candidate>, action: Action<Candidate>) {
    switch (action.type) {
      case "candidate/created-and-published":
      case "candidate/updated-and-published":
      case "candidate/updated-and-deactivated":
      case "candidate/published":
      case "candidate/deactivated": {
        const crudAction = this.requireCrudAction(action);
        const model = draft.get(crudAction.id);
        if (model != null && (crudAction.data as { rev: DataModelRevision }).rev > model.rev) {
          const newModel = (model as any).clone(action.data) as Candidate;
          draft.set(newModel.id, newModel);
        }
        break;
      }
    }
  }
}

@NgModule({
	imports: [
		StoreModule.forFeature("candidate", CANDIDATE_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("candidate"),

	], providers: [
		{
			provide: CANDIDATE_REDUCER_TOKEN,
			useFactory: candidateReducerProvider
		}
	]
})
export class CandidateCoreModule {

}
