import { Component, DestroyRef, inject, OnInit, signal } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import {
  MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle
} from "@angular/material/card";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatProgressBar } from "@angular/material/progress-bar";
import { ECharts, EChartsOption } from "echarts";

import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from "ngx-echarts";
import { PropeldFormDisplayModule } from "../../../../lib/component/form-display/module";
import { Action } from "../../../../lib/core/event-bus/action";
import { CrudAction } from "../../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../../lib/core/event-bus/event-bus";
import { Message } from "../../../../lib/core/event-bus/message/message";
import { AppState } from "../../../../lib/service/app-state/app-state";
import {
  fatalError, filterFailuresAsSnackbarMessage
} from "../../../../lib/service/snackbar/service/error-filter";
import { SnackbarService } from "../../../../lib/service/snackbar/service/snackbar.service";
import { STATS_GET_CANDADATES_PER_REGION } from "../../../app.actions";
import { AccountService } from "../../../service/account-service/account-service";
import { CardActionsModule } from "../../card-actions/module";

@Component({
  standalone: true,
  selector: "candidates-per-region-chart",
  templateUrl: "./component.html",
  imports: [
    CardActionsModule,
    FormsModule,
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatFormField,
    MatInput,
    MatLabel,
    MatProgressBar,
    PropeldFormDisplayModule,
    ReactiveFormsModule,
    NgxEchartsModule,
  ],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') }),
    },
  ],
  styleUrls: ["./component.scss"]
})
export class CandidatesPerRegionComponent implements OnInit {

  /**
   *  Housekeeping variables
   */
  accountService = inject(AccountService);
  appState = inject(AppState);
  destroyRef = inject(DestroyRef);
  private eventBus = inject(EventBus);
  private snackbar = inject(SnackbarService);
  displayMode = signal<string>("display");
  private chartInstance: ECharts | undefined;

  chartOptions = signal<EChartsOption>({
    xAxis: {
      type: 'category',
      data: [],
      axisLabel: {
        interval: 0,
        rotate: 45
      },
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [],
        type: 'bar'
      }
    ]
  });

  ngOnInit() {
    const action = new CrudAction(STATS_GET_CANDADATES_PER_REGION, 0, 0, {});
    this.eventBus.request(STATS_GET_CANDADATES_PER_REGION, action).asObservable
      .subscribe({
        next: (message: Message<Action<any>>) => {
          const reply = message.body;
          const items = (reply.data as any).items as [{
            regionId: number,
            count: number,
            name: string
          }];

          const categories = [];
          const values = [];
          for (var item of items) {
            categories.push(item.name);
            values.push(item.count);
          }
          const options = this.chartOptions() as any;
          (options.xAxis as any).data = categories;
          (options.series as any)[0].data = values;
          this.chartOptions.set(options);

          if(this.chartInstance != undefined) {
            this.chartInstance.setOption(options);
          }

        },
        error: (error: Error) => fatalError(this.snackbar, error)
      });
  }

  onChartInit($event: ECharts) {
    this.chartInstance = $event;
  }
}
