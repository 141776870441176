import {
  Component, DestroyRef, effect, EventEmitter, inject, Input, Output, signal, WritableSignal
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CrudAction } from "../../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../../lib/core/event-bus/event-bus";
import { AppActionsStore, AppLocation } from "../../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { fatalError } from "../../../../lib/service/snackbar/service/error-filter";
import { SnackbarService } from "../../../../lib/service/snackbar/service/snackbar.service";
import { SingleMainSourceImpl } from "../../../../lib/source/single-main-source-impl";
import { Source } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { DataModelId } from "../../../../lib/store/model/dataModel";
import { UPDATE_COMPANY } from "../../../app.actions";
import { Company, CompanyModel } from "../../../core/model/company/model";
import { CANDIDATE_UPDATE_PERMISSION, COMPANY_UPDATE_PERMISSION } from "../../../core/model/permissions";
import { AccountService } from "../../../service/account-service/account-service";

@Component({
  selector: "company-detail-card",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class CompanyDetailComponent {

  cardId = "company/detail-card";

  appState = inject(AppState);
  appActions = inject(AppActionsStore);
  accountService = inject(AccountService);
  private destroyRef = inject(DestroyRef);
  private eventBus = inject(EventBus);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private snackbar = inject(SnackbarService);

  displayMode = signal<string>("loading");

  model$ = signal<Company>(Company.placeholder());

  private sourceBuilder = inject(SourceBuilder);
  source: SingleMainSourceImpl<Company> = this.sourceBuilder
    .builder("company")
    .buildForSingle(0);

  @Input() model!: Company;
  @Output() modelChange = new EventEmitter<Company>();

  form = new FormGroup({
    id: new FormControl(0),
    rev: new FormControl(0),
    name: new FormControl(null, []), //required(), email()
    visitAddress: new FormControl(null, []) //
  });

  @Input() set modelId(id: DataModelId) {
    this.source.refresh({id: id});
  }

  constructor() {
    // this.model$ = this.source.singleModel$;

    this.source.dataSource$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (data: any) => {
        this.model$.set(data.items[0]);
      }
    });

    this.initEffects();
    this.initActions();
  }

  initActions() {
    this.appActions.setActions(this.cardId, [
      {
        id: "company/edit",
        icon: () => this.displayMode() === "edit" ? "visibility" : "edit",
        permission: UPDATE_COMPANY,
        location: [AppLocation.CARD_ACTION, AppLocation.APPBAR]
      }, {
        id: "cancel",
        icon: () => "close",
        location: [AppLocation.CARD_ACTION, AppLocation.APPBAR]
      }
    ]);
  }

  initEffects() {
    effect(() => {
      const model = this.model$();
      this.patchForm(model);

      if (model.isNew) {
        this.displayMode.set("edit");
      } else {
        this.displayMode.set("display");
      }

      this.modelChange.emit(model);
    }, {allowSignalWrites: true});

    effect(() => {
      const actionId = this.appState.action$();
      if (actionId == null) return;

      switch (actionId as any as string) {
        case "candidate/edit": {
          this.onEdit();
          break;
        }
        case "cancel": {
          this.onCancel();
          break;
        }
        default: {
        }
      }
      this.appState.setAction(null);
    }, {allowSignalWrites: true});
  }

  onOk() {
    if (!this.form.dirty) {
      this.onCancel();
      return;
    }

    let model: CompanyModel = this.model$();
    const changes = this.getDirtyValues(this.form);

    if (changes.visitAddress != null) {
      changes.v_street = changes.visitAddress.street;
      changes.v_houseNumber = changes.visitAddress.houseNumber;
      changes.v_zipCode = changes.visitAddress.zipCode;
      changes.v_city = changes.visitAddress.city;
    }
    if (changes.postAddress != null) {
      changes.p_street = changes.postAddress.street;
      changes.p_houseNumber = changes.postAddress.houseNumber;
      changes.p_zipCode = changes.postAddress.zipCode;
      changes.p_city = changes.postAddress.city;
    }

    let type = "company/update";
    if (model!.rev <= 0) {
      type = "company/create";
    }
    const action = new CrudAction(type, model!.id, model!.rev, changes);
    this.eventBus.request(action.type, action).subscribe({
      next: (v) => {
        this.snackbar.info("Wijziging is opgeslagen");
        this.form.markAsPristine();
        this.close();
      },
      error: (error: Error) => fatalError(this.snackbar, error)
    });
  }

  onEdit() {
    if (this.displayMode() === "display") {
      this.displayMode.set("edit");
    } else {
      if (this.form.dirty) {
        const changes = this.getDirtyValues(this.form);

        let model: Company = this.model$();
        Object.keys(changes).forEach(key => {
          (model as any)[key] = changes[key];
        });
        this.model$.set(model);
      }
      this.displayMode.set("display");
    }
  }

  onCancel() {
    this.router.navigate(["../"], {relativeTo: this.route})
      .catch((error) => alert(error.message));
  }

  close() {
    this.router.navigate(["../"], {relativeTo: this.route})
      .catch((error) => alert(error.message));
  }

  getDirtyValues(form: any) {
    let dirtyValues = {} as any;
    Object.keys(form.controls)
      .forEach(key => {
        let currentControl = form.controls[key];
        if (currentControl.dirty) {
          if (currentControl.controls) {
            dirtyValues[key] = this.getDirtyValues(currentControl);
          } else {
            dirtyValues[key] = currentControl.value;
          }
        }
      });

    return dirtyValues;
  }

  private patchForm(model: CompanyModel) {
    const modelData = Object.assign({}, model) as any;
    modelData.visitAddress = {
      street: model.v_street,
      houseNumber: model.v_houseNumber,
      zipCode: model.v_zipCode,
      city: model.v_city
    };
    delete modelData.v_street;
    delete modelData.v_houseNumber;
    delete modelData.v_zipCode;
    delete modelData.v_city;

    modelData.postAaddress = {
      street: model.p_street,
      houseNumber: model.p_houseNumber,
      zipCode: model.p_zipCode,
      city: model.p_city
    };
    delete modelData.p_street;
    delete modelData.p_houseNumber;
    delete modelData.p_zipCode;
    delete modelData.p_city;

    for (let key of Object.keys(modelData)) {
      const control = this.form.get(key) as FormControl;
      const value = modelData[key];
      if (value == null) {
        control?.setValue("");
      } else {
        control?.setValue(value);
      }
    }
    this.form.updateValueAndValidity();
    this.form.markAsPristine();
  }

  protected readonly Company = Company;
  protected readonly onclose = onclose;
  protected readonly CANDIDATE_EDIT_PERMISSION = CANDIDATE_UPDATE_PERMISSION;
  protected readonly COMPANY_UPDATE_PERMISSION = COMPANY_UPDATE_PERMISSION;
}
