
export class Action<T> {

	public readonly type: string;
	public readonly data: T;

	constructor(type: string, data: T) {
		this.type = type;
		this.data = data;
	}

	clone(): Action<T> {
		return new Action(this.type, this.data);
	}

	toJson(): any {
		return {
			type: this.type,
			data: this.data
		};
	}

	encode(): string {
		return JSON.stringify(this, null, 0);
	}

	encodePrettily(): string {
		return JSON.stringify(this, null, 4);
	}

  toString() : string {
    return this.encode();
  }
}
