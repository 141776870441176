@if (source.loadingStatus$() === SourceStates.LOADING) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
  <div class="progressbar-spacer"></div>
}

<mat-card>

  <ng-container
    sourceFilterRoot [filterId]="'user-list-filter'" [source]="source"
  >

    <div class="card-header">
        @if (!appState.isMobile$()) {
          <mat-card-header class="card-title-block">
            <mat-card-title>
              Bedrijven
            </mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
          </mat-card-header>

          <div class="card-actions-top-block">
              <div>
                <mat-search-input id="search" [source]="source"></mat-search-input>
              </div>
          </div>

          <div class="list-actions">
<!--            <button mat-icon-button (click)="onToggleFilters()">-->
<!--              <mat-icon>-->
<!--                @if (displayFilters()) {-->
<!--                  filter_list_off-->
<!--                } @else {-->
<!--                  filter_list-->
<!--                }-->
<!--              </mat-icon>-->
<!--            </button>-->
          </div>
        }
    </div>

    <mat-card-content>

      @if (displayFilters()) {
        <div class="list-filters">

        </div>
      }

      @if (!appState.isMobile$()) {
        <mat-table
        mat-table
        class="user-list"
        [source]="source"
        matSort
        matSortActive="name"
        matSortDirection="asc"
        matSortDisableClear
      >

        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let model">
            <span class="align-middle">{{ model.id }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let model">{{ model.name }}</td>
        </ng-container>

        <ng-container matColumnDef="v_street">
          <th mat-header-cell mat-sort-header  *matHeaderCellDef>Adres</th>
          <td mat-cell *matCellDef="let model">{{ model.v_street }}&nbsp;{{ model.houseNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="v_city">
          <th mat-header-cell mat-sort-header  *matHeaderCellDef>Plaats</th>
          <td mat-cell *matCellDef="let model">{{ model.v_city }}</td>
        </ng-container>


<!--        <ng-container matColumnDef="authRole.name">-->
<!--          <th mat-header-cell mat-sort-header *matHeaderCellDef>Rol</th>-->
<!--          <td mat-cell *matCellDef="let model">{{ model.authRole?.name }}</td>-->

<!--        </ng-container>-->

        <!--	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns;"
          [ngClass]="{ 'placeholder': row.rev < 0}"
          (click)="onClick(row.id)"
        ></tr>

      </mat-table>
      } @else {
        <mat-list>
          @for (model of (source.dataSourceAsArray$ | async); track model; let index = $index) {
            <mat-list-item (click)="onClick(model.id)">
              <span matListItemTitle>{{ model?.name }}</span>
              <span matListItemLine>{{ model?.v_city }}</span>
            </mat-list-item>

          }
        </mat-list>
      }

      <mat-paginator
        [dataSource]="source"
        [pageSizeOptions]="[5, 15, 10, 20, 50]"
        [length]="source.count$()"
        showFirstLastButtons
      ></mat-paginator>

    </mat-card-content>

    <mat-card-actions>

      <div class="left"></div>

      <div class="right">
        <button
          mat-flat-button color="primary" routerLink="./0"
        >Nieuw
        </button>
      </div>

    </mat-card-actions>
  </ng-container>
</mat-card>
