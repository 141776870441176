import { Component, HostListener, inject, OnInit, signal, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ComponentCanDeactivate } from "../../../../lib/component/pending-changes-guard/component";
import { DataModelId } from "../../../../lib/store/model/dataModel";
import { UserDetailComponent } from "../../../component/user/detail-component/component";
import { User } from "../../../core/model/user/model";

@Component({
  selector: "user-detail-page",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/styles.scss"]
})
export class UserDetailPageComponent implements OnInit, ComponentCanDeactivate {


  @ViewChild("userDetailCard") userDetailCard: UserDetailComponent | undefined;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if(this.userDetailCard == null) return true;
    return !this.userDetailCard.form.dirty;
  }

  modelId$ = new BehaviorSubject<DataModelId>(0);
  model$ = signal<User>(User.placeholder());
  required$ = new BehaviorSubject<any[]>([]);
  route: ActivatedRoute = inject(ActivatedRoute);

  ngOnInit() {
    const snapshot = this.route.snapshot;
    const params = snapshot.params;
    const queryParams = snapshot.queryParams;

    const id = Number(params["id"]);
    this.modelId$.next(id);

    const filter = queryParams["r"];
    if (filter != null) {
      this.required$.next(filter);
    }
  }

  onModelLoaded(model: User) {
    this.model$.set(model);
  }
}
