@if (displayMode() === 'loading') {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<mat-card class="mat-typography">

  <div class="card-header">
    <mat-card-header class="card-title-block">
      <mat-card-title>
        Gebruiker
        @if (displayMode() === 'loading') {
          ...
        } @else {
          {{ model$().displayName }}
        }

      </mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    @if(!appState.isMobile$()) {
      <div class="card-actions-top-block">
        <top-card-actions></top-card-actions>
      </div>
    }
  </div>

  <mat-card-content class="mat-body-1">

    <form id="form" [formGroup]="form" class="detail-form" (ngSubmit)="onOk()">

      <div style="display:none;">
        <input id="id" formControlName="id">
        <input id="rev" formControlName="rev">
      </div>

      <div class="form-row">
        <label class="label-column" for="firstName">
          <p class="body-1">Naam</p>
        </label>

        <!-- region name -->
        <div class="input-column">
          <form-display-mode [displayMode]="displayMode()">
            <p class="display">{{ model$().displayName }}</p>

            <div class="multi-input">
              <mat-form-field class="firstName-input">
                <mat-label>Voornaam</mat-label>
                <input id="firstName" matInput formControlName="firstName">
              </mat-form-field>

              <mat-form-field class="infix-input">
                <mat-label>Tussenv.</mat-label>
                <input matInput formControlName="infix">
              </mat-form-field>

              <mat-form-field class="surname-input">
                <mat-label>Achternaam</mat-label>
                <input matInput formControlName="surname">
              </mat-form-field>
            </div>
          </form-display-mode>
        </div>
      </div>
      <!-- endregion -->

      <!-- region educationLevelId -->
      <div class="form-row">
        <label class="label-column" for="educationLevelId">
          <p>Bedrijf</p>
        </label>

        <div class="input-column">
          @if(displayMode() == "display") {
            @if(model$().companyId == null && model$().companyName != null) {
              {{ model$().companyName }} (!)
            }
          }

          <form-display-mode [displayMode]="displayMode()">
            <autocomplete-input
              class="display"
              formControlName="companyId"
              [displayOnly]="true"
              [source]="companySource"
              [filterField]="'companyId'"
              [placeholder]="'Bedrijf'"
            ></autocomplete-input>

            <autocomplete-input
              id="educationLevelId"
              formControlName="companyId"
              [source]="companySource"
              [filterField]="'companyId'"
              [placeholder]="'Bedrijf'"
            ></autocomplete-input>
          </form-display-mode>
        </div>
      </div>
      <!-- endregion -->

      <div class="form-row">
        <label class="label-column" for="firstName">
          <p>Email</p>
        </label>

        <!-- name -->
        <div class="input-column">
          <form-display-mode [displayMode]="displayMode()">
            <p class="display">{{ model$().email }}</p>

            <mat-form-field class="email-input">
              <input id="email" matInput formControlName="email" [placeholder]="'Email'">
              @if(form.get("email")?.hasError("required")) {
                <mat-error>Dit veld is verplicht</mat-error>
              }
              @if(form.get("email")?.hasError("email")) {
                <mat-error>Geef een geldig email adres</mat-error>
              }
            </mat-form-field>

          </form-display-mode>
        </div>
      </div>

      <div class="form-row">
        <label class="label-column" for="authRoleId">
          <p>Rol</p>
        </label>

        <!-- region authRoleId -->
        <div class="input-column">
          <form-display-mode [displayMode]="displayMode()">

            <p class="display">{{ model$().authRole?.displayName }}</p>

            <mat-form-field id="authRoleId" class="authRoleId-input">
              <mat-select formControlName="authRoleId">
                <mat-option [value]="1">SYSTEM</mat-option>
                <mat-option [value]="10">GAST</mat-option>
                <mat-option [value]="20">GEBRUIKER</mat-option>
                <mat-option [value]="50">EXT RECRUITER</mat-option>
                <mat-option [value]="60">INT RECRUITER</mat-option>
                <mat-option [value]="100">ADMIN</mat-option>
              </mat-select>
            </mat-form-field>
          </form-display-mode>
        </div>
        <!-- endregion -->
      </div>

      <div class="form-row">
        <label class="label-column" for="active">
          <p>Actief</p>
        </label>

        <!-- region active -->
        <div class="input-column">
          <form-display-mode [displayMode]="displayMode()">

            <p class="display">
              <mat-slide-toggle
                class="active-input"
                color="primary"
                formControlName="active"
                [disabled]="true"
              />
            </p>

            <mat-slide-toggle
              id="active"
              class="active-input"
              color="primary"
              formControlName="active"
              [disabled]="displayMode() !== 'edit'"
            >
              @if (form.get('active')?.value !== true) {
                Deze gebruiker kan niet inloggen.
              } @else {
                Deze gebruiker kan inloggen.
                @if (displayMode() === 'edit' && model$().isNew && form.get('active')?.value) {
                  Er wordt een email bericht gestuurd met de activatie gegevens.
                }
              }
            </mat-slide-toggle>
          </form-display-mode>
        </div>
        <!-- endregion -->
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions>

    <div class="left">
<!--      <button mat-flat-button>Reset wachtwoord</button>-->
<!--      <button mat-flat-button>Verander email</button>-->
    </div>

    <div class="right">
      @if ((displayMode() == 'display' && form.dirty) || (displayMode() == 'edit' && form.dirty)) {
        <button mat-flat-button color="primary" type="submit" form="form" autofocus>Ok</button>
        <button mat-flat-button (click)="onCancel()">Annuleer</button>
      } @else {
        <button mat-flat-button color="primary" (click)="close()">Sluit</button>
      }
    </div>

  </mat-card-actions>
</mat-card>
