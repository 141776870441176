import {
  AfterViewInit,
  contentChildren,
  DestroyRef,
  Directive,
  effect,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  signal
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Subscription } from "rxjs";
import { Source } from "../source/source";
import { SourcePageState } from "../source/state/source-page-state-store";
import { AndOperator } from "./operator";
import { SourceFilterStateComponent } from "./source-filter-component";

export type SourceFilterComponentState = {
  name: string; value: any | null;
}

export type SourceFilterState = SourcePageState & {
  filterSettings: SourceFilterComponentState[];
  displayFilters: boolean;
}

@Directive({
  selector: "[sourceFilter]"
})
export class SourceFilterDirective implements OnInit, AfterViewInit, OnDestroy {

  // @Input({required: true}) source!: Source<any>;
  source = input<Source<any>>();
  displayFilters = input(false);
  @Input() filterRef?: string;

  private _sourceFilterComponents$ = contentChildren(SourceFilterStateComponent, {descendants: true});

  private destroyRef = inject(DestroyRef);
  private subscription?: Subscription | null;

  private filterState = signal<SourceFilterComponentState[]>([]);

  constructor() {

    // Subscribe to changes in any SourceFilter and apply the combined filter to the source
    effect(() => {
      if (this.subscription != null) {
        this.subscription.unsubscribe();
      }

      const components = this._sourceFilterComponents$();
      const observables = components.map(component => component.filterChanges);
      const operator = new AndOperator();
      operator.addObservables(observables);

      this.subscription = operator.filterChanges$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (filter) => {
          // if(this.filterState()?.length > 0 && filter == null) {
          //   const state = this.getFilterState();
          //   this.patchFilters(state);
          //   return;
          // }
          this.source()?.setFilter(filter);
        }
      });
    });

    // Apply saved changes
    const initialFilterEffect = effect(() => {
      const filtersVisible = this.displayFilters();
      const components = this._sourceFilterComponents$();
      const filterState = this.filterState();
      if(filterState?.length > 0 && components?.length > 0) {
        this.patchFilters(filterState);
        initialFilterEffect.destroy();
      }
    }, {allowSignalWrites: true});
  }

  ngOnInit(): void {

  }

  patchFilters(filterSettings: SourceFilterComponentState[]) {
    console.log("FILTERZ patchFilters"+JSON.stringify(filterSettings, null, 4));
    if (filterSettings != null && filterSettings.length > 0) {
      const components = this._sourceFilterComponents$();
      components.forEach(component => {
        const name = component.filterComponentId;
        const setting = filterSettings.find((item) => item.name == name);
        if (setting != null) {
          component.setSourceFilterComponentState(setting);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.filterState.set((this.source()?.getState() as SourceFilterState)?.filterSettings);
  }

  getFilterState(): SourceFilterComponentState[] {
    return this._sourceFilterComponents$()
      .map(component => component.getSourceFilterComponentState())
      .filter((state) => state.value != null)
      .reduce((acc, value) => {
        acc.push(value);
        return acc;
      }, [] as SourceFilterComponentState[]);
  }

  save() {
    const state = this.getFilterState();
    this.source()?.setState({
      filterSettings: state,
      displayFilters: this.displayFilters()
    } as SourceFilterState);

    console.log("FILTERZ SAVED "+JSON.stringify(this.source()?.getState(), null, 4));
  }

  ngOnDestroy(): void {
    this.save();
  }
}
