import { Component, DestroyRef, inject, Input, OnDestroy, signal, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { AppActionsStore, AppLocation } from "../../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { SortDirection, Source, SourceLoadingStates } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { SourcePageStateStore } from "../../../../lib/source/state/source-page-state-store";
import { DataModelId } from "../../../../lib/store/model/dataModel";
import { Company } from "../../../core/model/company/model";
import { AccountService } from "../../../service/account-service/account-service";

@Component({
  selector: "company-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss", "../../../../assets/styling/list-styles.scss"]
})
export class CompanyListComponent implements OnDestroy {

  readonly cardId = "company/list-card"
  /**
   *  Housekeeping variables
   */
  accountService = inject(AccountService);
  appState = inject(AppState);
  appActions = inject(AppActionsStore);
  destroyRef = inject(DestroyRef);
  private sourceStateStore = inject(SourcePageStateStore);

  /**
   *  Source related
   */
  private sourceBuilder = inject(SourceBuilder);
  source: Source<Company> = this.sourceBuilder
    .builder("company")
    .setName("companyListSource")
    .setInitialFilter(this.sourceStateStore.getState("companyListSource", {
      pageSize: 20,
      sort: [
        {
          field: "name",
          direction: SortDirection.ASC
        }
      ]
    }))
    .build();

  /**
   *  List related
   */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns = ["name", "v_street", "v_city"];
  displayFilters = signal<boolean>(false);

  form = new FormGroup({
    companyFilter: new FormControl(0)
  });

  @Input() set searchInHeaderBar(searchInHeaderBar: boolean) {
    this.appState.setSearchSource(this.source);
  }

  constructor(private route: ActivatedRoute, private router: Router) {
    this.initActions();
  }

  initActions() {
    this.appActions.setActions(this.cardId, [{
        id: "search",
        icon: () => "search",
        location: [AppLocation.APPBAR],
      }]
    );
  }

  ngOnDestroy() {
    this.sourceStateStore.setState(this.source.name, this.source.getState());

    if (this.appState.searchSource$() != null) {
      this.appState.clearSearchSource();
    }
  }

  onClick(id: DataModelId) {
    this.router.navigate(["./" + id], {relativeTo: this.route})
      .catch((error) => alert(error.message));
  }

  onToggleFilters() {
    this.displayFilters.set(!this.displayFilters());
  }

  protected readonly SourceStates = SourceLoadingStates;
}
