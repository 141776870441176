import { NgModule } from "@angular/core";
import { AppHeaderResetDirective, AppStateDirective } from "./directive";

@NgModule({
	declarations: [
    AppStateDirective, AppHeaderResetDirective
	],
	exports: [
    AppStateDirective, AppHeaderResetDirective
	],

	imports: [
	]
})
export class PropeldAppStateModule {
}
