import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { AuthRole, AuthRoleData, AuthRoleModel, initialAuthRoleStore } from "./model";

export const AUTHROLE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("AuthRoleReducer");
// export const AUTHROLE_STORE_MODULE_TOKEN = new InjectionToken<ActionReducerMap<any>>("AuthRoleStoreModuleToken");

export function authRoleReducerProvider() {
	return reducerFactory<AuthRoleModel>("authRole", initialAuthRoleStore, (data: AuthRoleData) => {
		return new AuthRole(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("authRole", AUTHROLE_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("authRole")
	], providers: [
		{
			provide: AUTHROLE_REDUCER_TOKEN,
			useFactory: authRoleReducerProvider
		}
	]
})
export class AuthRoleCoreModule {

}
