import { inject, Injectable } from "@angular/core";
import { Action } from "../../../core/event-bus/action";
import { EventBus } from "../../../core/event-bus/event-bus";
import { Message } from "../../../core/event-bus/message/message";
import { SnackbarData, SnackbarLevel } from "../action";
import { SnackbarMessageService } from "./message.service";

@Injectable({
	providedIn: 'root'
})
export class SnackbarService {

  private eventBus = inject(EventBus);
  private snackbarMessageService = inject(SnackbarMessageService);

	constructor() {
    this.eventBus.consumer("snackbar/deleted").subscribe({
      next: (value) => {

      }
    });

    this.eventBus.consumer("notification/created").subscribe({
      next: (message) => {
        const body = message.body as any;
        const summary = body.data.renderedMessages?.summary;
        if(summary != null) {
          this.info(summary.text);
        }
      }
    })
	}

	info(message: string, duration?: number) {
		this.send(SnackbarLevel.INFO, message, duration);
	}

	success(message: string, duration?: number) {
		this.send(SnackbarLevel.SUCCESS, message, duration);
	}

	warn(message: string, duration?: number) {
		this.send(SnackbarLevel.WARNING, message, duration);
	}

	error(message: string, duration?: number) {
		this.send(SnackbarLevel.ERROR, message, duration);
	}

	fatal(message: string, duration?: number) {
		this.send(SnackbarLevel.FATAL_ERROR, message, duration);
	}

  handleErrorResponse(message: Message<any>) {
    const errorMessage = message.body?.data?.message;
    if(errorMessage != null) {
      let action: Action<any> | null = null;
      try {
        action = JSON.parse(errorMessage) as any;
        const messageText = action?.data?.failure?.text;
        if(messageText != null) {
          this.error(messageText);
          return;
        }
      } catch (error) {

      }
      this.error(message.body?.encode);
    }
  }

	private send(level: SnackbarLevel, message: string, duration?: number ) {
		const action = new Action<SnackbarData>("snackbar/create", {
			message: message,
			level:  level,
			duration: duration ? (duration * 1000) : 3000
		});
		this.eventBus.send(action.type, action);
	}
}
