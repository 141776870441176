import { InjectionToken } from "@angular/core";

export const SOCKET_OPTIONS = new InjectionToken<SocketOptions>("SocketOptions");

export const SOCKET_OPTIONS_PROVIDER = {
	provide: SOCKET_OPTIONS,
	useValue: {
		url: "unknown",
		timeout: -1
	}
};

export interface SocketOptions {
	url: string;
	timeout?: number;
	pingInterval?: number;
	heartbeatInterval?: number;
}
