import { NgModule } from "@angular/core";
import { ResizeDirective } from "./directive";
import { Resizer } from "../../service/app-state/resizer";

@NgModule({
	declarations: [
    ResizeDirective
	],
	exports: [
    ResizeDirective
	],

	imports: [
	]
})
export class PropeldResizeDirectiveModule {
}
