import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, combineLatestWith, fromEvent, Observable, skipWhile, startWith, tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Account, AccountData } from "../../../app/core/model/account/model";
import { Action } from "../event-bus/action";
import { CrudAction } from "../event-bus/crud-action";
import { EventBus } from "../event-bus/event-bus";
import { MessageConsumer } from "../event-bus/message-consumer";
import { Message } from "../event-bus/message/message";
import { LoggerLocator } from "../logger/locator";
import { Socket, SocketStates } from "./socket";

@Injectable({
	providedIn: "root"
})
export class SocketConnector {

	private logger = LoggerLocator.getLogger("AuthenticationHandler")();
	private reconnectionCount = 0;
	// private userChannelSubscription: Subscription;
	// private inFlightLoginRequest: Message<Action>;

	private eventBus: EventBus = inject(EventBus);
	private socket: Socket = inject(Socket);
  private store: Store<any> = inject(Store);
  private reconnectOnClose = true;
  private userChannelSubscription: MessageConsumer<any> | null = null;

	constructor() {
    this.eventBus.consumer<Action<any>>("client/logout").observable
      .subscribe({
        next: (message1: Message<Action<any>>) => {

          this.userChannelSubscription?.unregister();
          this.userChannelSubscription = null;

          this.eventBus.request("user/logout", new Action("user/logout", {})).asObservable
            .subscribe({
              next: (message2: Message<Action<any>>) => {
                this.reconnectOnClose = false;
                this.socket.close();
                message1.reply(message2.body);
              },
              error: (error) => {
                console.log("ERROR "+error);
              }
            })
        },
        error: (error) => {
          console.log("ERROR "+error);
        }
      });

		combineLatest([
			this.socket.statusObservable.pipe(
				skipWhile(status => status !== SocketStates.OPEN)),
				this.listenToPageVisibilityChanges()
		])
			.pipe(
        tap( ([status, visibilityChange]) => {
          console.log("VISIBILITY: "+status+"/"+visibilityChange.documentIsHidden+" / "+
            this.reconnectOnClose+"/");
        }),
				filter(([status, visibilityChange]) =>
          (this.reconnectOnClose && status !== SocketStates.OPENING && !visibilityChange.documentIsHidden)
			  ))
			.subscribe(
        this.tryConnect.bind(this)
      );

		// Right after initialization we try to open a websocket connection.
		// But as this is generally going to fail we instantly reset the reconnection attempts as well
		// (only in this instance though).
		this.reconnect();
	}

  setReconnectOnClose(reconnectOnClose: boolean) {
    this.reconnectOnClose = reconnectOnClose;
  }

  tryConnect() {
    this.reconnectionCount = 0;
    this.reconnect();
  }

  reconnect() {

    if(this.socket.status === SocketStates.OPEN || this.socket.status == SocketStates.OPENING) {
      return;
    }

    console.log("RECONNECT: "+this.reconnectOnClose);
    this.userChannelSubscription?.unregister();
    this.userChannelSubscription = null;

		this.eventBus.request("user/login", new Action("user/login", {})).asObservable
			.pipe(
				combineLatestWith(this.socket.open()),
        filter(([message, socketState]) => {
          return socketState === SocketStates.OPEN;
        })
			)
			.subscribe({
				next: ([message, socketState]) => {

          const reply = message.body as CrudAction<Account>;
          const account = (reply.data as any).items[0] as AccountData;

          if(account.authRoleId > 1) {
            this.userChannelSubscription = this.eventBus.register(account.id as number);
            this.userChannelSubscription.subscribe({
              next: (message: Message<any>) => {
                const action = message.body;

                console.log("--------------------------------"+JSON.stringify(action,null,4))

                this.store.dispatch(message.body);
                this.eventBus.publish(action.type, action);
              },
              error: (message) => {
                debugger;
              }
            });
          }

          const action = message.body;
          this.eventBus.send(action.type, action);
				},
				error: (error) => {
          if(this.socket.status !== SocketStates.OPENING) {
            console.error("Something went wrong", error);
            return;
          }

          this.reconnectionCount += 1;
          if(this.reconnectionCount >= 5) {
            this.setReconnectOnClose(false);
            return;
          }
          //
          setTimeout( () => {
            this.reconnect();
          }, 2000);
				}
			});
	}

	private listenToPageVisibilityChanges(): Observable<{ documentIsHidden: boolean }> {
		// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#example
		let hidden: string;
		let visibilityChange: string;
		const proxyDocument: any = document;

		// Opera 12.10 and Firefox 18 and later support
		if (typeof proxyDocument?.hidden !== "undefined") {
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} else if (typeof proxyDocument?.msHidden !== "undefined") {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} else if (typeof proxyDocument?.webkitHidden !== "undefined") {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		} else {
			throw new Error("Unknown visibilityChange");
		}

		return fromEvent(proxyDocument, visibilityChange)
			.pipe(
				startWith({documentIsHidden: false}),
				map((documentIsHidden) => {
					return {documentIsHidden: false}
					// return {documentIsHidden: (document as any)[hidden] === true}
				}
			)
		);
	}
}
