<div
  appHeaderReset
  appState [title]="model$().name"
  [isSubPage]="true">

  <company-detail-card #companyDetailCard
    [modelId]="(modelId$ | async)!" (modelChange)="onModelLoaded($event)">
  </company-detail-card>

  @if(!model$().isNew) {
    <user-list-card [filterCompanyId]="model$().id">
    </user-list-card>
  }
</div>
