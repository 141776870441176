import { EncodedOperator, Operator } from "./operator";

/**
 * This operator becomes:
 * {
 *  "<<field>>: {$ne: <<value>>}
 * }
 */
export class NotEqualOperator implements Operator {
  constructor(public readonly  field: string, private value: any) {
  }

  definition(): EncodedOperator {
    return {
      o: "$ne", f: this.field, v: this.value
    }
  }
  build(): object {
    return {
      [this.field]: {"$ne": this.value}
    };
  }
}
