import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as Util from "../../util/util";
import { LoggerLocator } from "../logger/locator";
import { Action } from "./action";
import { DeliveryOptions } from "./delivery-options";
import { EventBusInterceptor } from "./event-bus-interceptor";
import { HandlerHolder, RegexReplyHolder } from "./handler-holder";
import { MESSAGE_CODEC, MessageCodec } from "./message-codec";
import { MessageConsumer } from "./message-consumer";
import { ErrorMessage, MyErrorMessage } from "./message/error-message";
import { Message, MESSAGE_REPLY_PREFIX, MessageType } from "./message/message";
import { RequestReplyHolder } from "./request-reply-holder";

export interface ReplyHolder {
  get address(): string,

  get asObservable(): Observable<Message<any>>,

  error(error: ErrorMessage | Error): void,

  send(message: Message<any>): void,

  register(isLocal: boolean): MessageConsumer<any>
}

@Injectable({
  providedIn: "root"
})
export class EventBus {

  private logger = LoggerLocator.getLogger("EventBus")();

  private handlerHolders = new Map<string, ReplyHolder>();
  private regexHandlerHolders: RegexReplyHolder[] = [] as RegexReplyHolder[];

  private interceptors = new Map<string, EventBusInterceptor>;

  private codecs: MessageCodec<any, any>[] = [];

  constructor(@Inject(MESSAGE_CODEC) codec: MessageCodec<any, any>) {
    this.codecs.push(codec);
    this.logger.info("Eventbus constructed");
  }

  public send<T>(address: string, body: T, options?: DeliveryOptions): void {
    const message = this.createMessage(address, MessageType.SEND, body, options);
    this.sendOrPublish(message);
  }

  public sendAction<T, D>(type: string, data: T, options?: DeliveryOptions) {
    const action = new Action(type, data as object);
    this.send(type, action, options);
  }

  public publish<T>(address: string, body: T, options?: DeliveryOptions) {
    const message = this.createMessage(address, MessageType.PUBLISH, body, options);
    this.sendOrPublish(message);
  }

  public request<T, D>(address: string, body: T, options?: DeliveryOptions): RequestReplyHolder {
    const message = this.createMessage(address, MessageType.SEND, body, options);
    const replyAddress = this.generateReplyAddress();
    message.setReplyAddress(replyAddress);

    const replyHolder = new RequestReplyHolder(this, message, options);
    this.handlerHolders.set(replyAddress, replyHolder);

    try {
      this.sendOrPublish(message);
    } catch (error: any) {
      if (error instanceof Error || error instanceof ErrorMessage) {
        replyHolder.error(error);
      } else {
        replyHolder.error(new Error("Unknown error handler"));
      }
    }
    return replyHolder;
  }

  public register(address: number): MessageConsumer<Message<any>> {
    // TODO: Check if consumer already exists for this address

    const channelAddress = `user/${address}`;
    const action = new Action(channelAddress, {channelId: address});
    const message = this.createMessage("user/register-channel", MessageType.REGISTER, action, {});

    const replyAddress = this.generateReplyAddress();
    message.setReplyAddress(replyAddress);

    const replyHolder = new RequestReplyHolder(this, message, {});
    this.handlerHolders.set(replyAddress, replyHolder);

    try {
      this.sendOrPublish(message);
    } catch (error: any) {
      if (error instanceof Error || error instanceof ErrorMessage) {
        replyHolder.error(error);
      } else {
        replyHolder.error(new Error("Unknown error handler"));
      }
    }

    // TODO: Register channel with address for unregistering feature
    const consumer = this.consumer<Message<any>>(channelAddress);

    replyHolder.subscribe({
      next: (message: Message<any>) => {
        if (message.body.type == "registered") {
          // Do nothing...
          return;
        }
      },
      error: (error) => {
        // Could not subscribe to user channel...
        debugger;
      }
    });
    return consumer;
  }

  public requestAction<T, D>(type: string, body: T, options?: DeliveryOptions): RequestReplyHolder {
    const action = new Action(type, body as object);
    return this.request(type, action, options);
  }

  public localConsumer<T>(address: string): MessageConsumer<T> {
    if (address.startsWith("__regex:")) {
      return this.registerRegexConsumer(address.substring(8));
    }
    return this.registerConsumer(address, true);
  }

  public consumer<T>(address: string): MessageConsumer<T> {
    if (address.startsWith("__regex:")) {
      return this.registerRegexConsumer(address.substring(8));
    }
    return this.registerConsumer(address);
  }

  public createMessage<T>(address: string,
    type: MessageType,
    body: T,
    options?: DeliveryOptions
  ): Message<T> {
    return new Message<T>(address, type, body, this, this.codecs[0], options);
  }

  public createErrorMessage<T>(address: string,
    type: MessageType,
    body: T,
    options?: DeliveryOptions
  ): Message<T> {
    return new MyErrorMessage<T>(address, type, body, this, this.codecs[0], options);
  }

  public sendReply<T>(reply: Message<T>) {
    this.sendOrPublish(reply);
  }

  private sendOrPublish<T>(message: Message<T>) {

    if (!this.handleInterceptors(message)) return;

    let handlerHolder = this.handlerHolders.get(message.address);
    if (!handlerHolder) {
      handlerHolder = this.findRegexHandlerHolders(message.address);
    }

    if ((message.body as any).type === "err") {
      // A failure is always a reply to a request
      // We don't want to break the rxjs chain though by sending an error through the pipeline
      if (!(handlerHolder instanceof RequestReplyHolder)) {
        throw Error("Can not fail to non RequestReplyHolder");
      }
      handlerHolder.error(message as Message<T>);
      return;
    }

    if (message.type === MessageType.SEND) {
      if (!handlerHolder) {
        // We don't know where to send the message. If it's not a reply, fail the message.
        // Otherwise, throw an error
        if (message.isReply()) {
          this.noHandlers(message);
        } else if (message.getReplyAddress() != null) {
          message.fail(500, `No handlers registered for address '${message.address}': ${message.encode()}`);
        } else {
          this.logger.warning(`No handler for type '${message.type}', address '${message.address}'`);
        }
        return;
      }
      handlerHolder.send(message);
      return;
    }

    if (message.type === MessageType.PUBLISH) {
      if (!(handlerHolder instanceof HandlerHolder)) {
        // Messages can only be published to consumers (which have a HandlerHolder).
        // If there are none, do nothing with the message
        return;
      }
      handlerHolder.publish(message);
    }

    if (message.type === MessageType.REGISTER) {
      if (!handlerHolder) {
        // We don't know where to send the message. If it's not a reply, fail the message.
        // Otherwise, throw an error
        if (message.isReply()) {
          this.noHandlers(message);
        } else if (message.getReplyAddress() != null) {
          message.fail(500, `No handlers registered for address '${message.address}'`);
        } else {
          this.logger.warning(`No handlers registered for address '${message.address}'`);
        }
        return;
      }
      handlerHolder.send(message);
      return;
    }
  }

  private noHandlers(message: Message<any>) {
    throw Error(`No handlers registered for address '${message.address}': ${message.encode()}`);
  }

  private registerConsumer<T>(address: string, isLocal: boolean = false): MessageConsumer<any> {
    let handlerHolder = this.handlerHolders.get(address);
    if (!handlerHolder) {
      handlerHolder = new HandlerHolder(this, address);
      this.handlerHolders.set(address, handlerHolder);
    }
    return handlerHolder.register(isLocal);
  }

  private registerRegexConsumer<T>(address: string, isLocal: boolean = false): MessageConsumer<any> {
    const handlerHolder = new RegexReplyHolder(this, address);
    this.regexHandlerHolders.push(handlerHolder);
    return handlerHolder.register(isLocal);
  }

  /**
   * Internal method only to be used by the handlerHolder
   *
   * @param handlerHolder
   */
  unregisterHolder(handlerHolder: ReplyHolder) {
    const deleted = this.handlerHolders.delete(handlerHolder.address);
    if (!deleted) {
      throw Error(`Could not delete holder witha ddress $ {handlerHolder.address}`);
    }
  }

  private handleInterceptors(message: Message<any>): boolean {
    for (let [name, iterator] of this.interceptors) {
      if (!iterator.handle(message)) return false;
    }
    return true;
  }

  private generateReplyAddress(): string {
    // todo how to resolve this, vertx reply + uuid is 50 chars and backend has max on 36
    return (MESSAGE_REPLY_PREFIX + Util.Util.generateUUID().replaceAll("-", "").slice(0, 22));
    // return "__vertx.reply." + Util.generateUUID();
  }

  public registerInterceptor(name: string, interceptor: EventBusInterceptor): EventBus {
    if (this.interceptors.get(name)) {
      throw Error(`Interceptor with name ${name} already registered`);
    }
    this.interceptors.set(name, interceptor);
    return this;
  }

  public unregisterInterceptor(name: string) {
    const interceptor = this.interceptors.get(name);
    if (interceptor) {
      this.interceptors.delete(name);
    }
  }

  private findRegexHandlerHolders(address: string): ReplyHolder | undefined {

    const item = this.regexHandlerHolders.find(item => {
      const match = item.matches(address);
      return match;
    });
    return item;
  }

  /**
   * Add an interceptor that gets called whenever a message is send
   * to the application
   */
  // public addInboundInterceptor(interceptor: any) {
  //
  // }

  // public removeOutboundInterceptor(interceptor: any) {
  //
  // }
}
