import {
  AfterViewInit, ContentChildren, DestroyRef, Directive, inject, Input, OnDestroy, OnInit, QueryList
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Source } from "../source/source";
import { SourceFilter, SourceFilterComponent } from "./source-filter";
import { SourceFilterState, SourceFilterStateFactory } from "./state-service";

@Directive({
  selector: "[sourceFilterRoot]"
})
export class SourceFilterRootDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input({required: true}) filterId!: string;
  @Input({required: true}) source!: Source<any>;
  @Input() filterRef?: string;

  private sourceFilterStateService = inject(SourceFilterStateFactory);
  private sourceFilterState!: SourceFilterState;

  @ContentChildren(SourceFilterComponent, {descendants: true}) components?: QueryList<SourceFilterComponent>;

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.sourceFilterState = this.sourceFilterStateService.create(this.source.name);
    this.sourceFilterState.source = this.source;
  }

  ngAfterViewInit(): void {
    const x = this.components?.changes.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value) => {
          const list = this.components?.map(component => component as SourceFilter);
          this.sourceFilterState.register(this.filterId, list ? list : []);
        },
        error: (error) => {
          console.error(error.message);
        }
      });
    const list = this.components?.map(component => component as SourceFilter);
    this.sourceFilterState.register(this.filterId, list ? list : []);
    this.sourceFilterState.patchSettings();
  }

  ngOnDestroy(): void {
    this.sourceFilterState.saveSettings();
    this.sourceFilterState.source = null;
  }
}
