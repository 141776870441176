import { inject, InjectionToken, ModuleWithProviders, NgModule, OnDestroy } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { StoreCrudHandler } from "./handler/store-crud-handler";

const STORE_HANDLER_ENTITY = new InjectionToken("StoreHandlerEntity");

export const STORE_HANDLER_ENTITY_PROVIDER = {
  provide: STORE_HANDLER_ENTITY,
  multi: true,
  useValue: ""
};


@NgModule({})
export class PropeldStoreFeatureModule implements OnDestroy {

  private handlers = new Map<string, StoreCrudHandler>();
  private entities: string[] = inject(STORE_HANDLER_ENTITY) as string[];

  constructor() {
    this.entities.forEach( entity => {
      const storeHandler = new StoreCrudHandler();
      this.handlers.set(entity, storeHandler);
      storeHandler.initDefaultHandlers(entity);
    });
  }

  ngOnDestroy() {
    this.handlers.forEach( handler => {
      handler.removeHandlers();
    });
  }
}

@NgModule({
  declarations: [
  ],
  imports: [
    StoreModule.forRoot([])
  ],
  providers: [STORE_HANDLER_ENTITY_PROVIDER]
})
export class PropeldStoreModule {
  static forFeature(entity: string): ModuleWithProviders<PropeldStoreFeatureModule> {
    return {
      ngModule: PropeldStoreFeatureModule,
      providers: [
        {
          provide: STORE_HANDLER_ENTITY,
          multi: true,
          useValue: entity
        }
      ]
    };
  }
}
