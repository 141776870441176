import { Component, Injector, Type } from "@angular/core";
import { enableMapSet } from "immer";
import { HttpEventBusBridge } from "../lib/core/event-bus-bridge/http/http-event-bus-bridge";
import { SocketEventBusBridge } from "../lib/core/event-bus-bridge/socket/socket-event-bus-bridge";
import { SocketConnector } from "../lib/core/socket/socket-connector";
import { StoreFilterHandler } from "../lib/store/handler/store-filter-handler";
import { StoreSyncHandler } from "../lib/store/handler/store-sync-handler";

enableMapSet();


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'talent-portal';
  servicesToBootstrap: Type<unknown>[] = [
    HttpEventBusBridge,
    SocketEventBusBridge,
    SocketConnector,
    StoreSyncHandler,
    StoreFilterHandler,
    // FilterHandler,
    // DeleteHandler,
    // SyncHandler,
    // UpdateHandler,
    // CreateHandler,
    // AuthenticationHandler,
    // SnackbarService,
    // BrowserAnimationsModule,
    // FileFilterHandler,
    // FileSyncHandler
  ];

  constructor(private injector: Injector) {
    this.servicesToBootstrap.forEach(service => {
      this.injector.get(service)
    });
  }
}
