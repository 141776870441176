import {
  BaseModel, DataModel, DataModelId, DataModelMap, NullableString
} from "../../../../lib/store/model/dataModel";
import { User } from "../user/model";

export type CandidateViewStore = DataModelMap<CandidateViewModel>;

export const initialCandidateViewStore: CandidateViewStore = new Map<DataModelId, CandidateViewModel>();

export interface CandidateViewData extends DataModel {
  token: string;
  timestampCreated: string;
  candidateId: number;
  userId: number;
  contact: NullableString;
  sharedByUserId: number;
  timestampViewed: string;
  ip: NullableString;
  userAgent: NullableString;

  user: User | null;
  sharedBy: User | null;
}

export interface CandidateViewModel extends CandidateViewData, DataModel {

}

export class CandidateView extends BaseModel implements CandidateViewModel {

  readonly token: string;
  readonly timestampCreated: string;
  readonly candidateId: number;
  readonly userId: number;
  readonly contact: NullableString;
  readonly sharedByUserId: number;
  readonly timestampViewed: string;
  readonly ip: NullableString;
  readonly userAgent: NullableString;

  readonly user: User | null;
  readonly sharedBy: User | null;

  constructor(data: CandidateViewModel) {
    super(data.id, data.rev);
    this.token = data.token;
    this.timestampCreated = data.timestampCreated;
    this.candidateId = data.candidateId;
    this.userId = data.userId;
    this.contact = data.contact;
    this.sharedByUserId = data.sharedByUserId;
    this.timestampViewed = data.timestampViewed;
    this.ip = data.ip;
    this.userAgent = data.userAgent;

    this.user = data.user;
    this.sharedBy = data.user;
  }

  /**
   * Clones a city and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): CandidateView {
    data = data ? data : {};

    return new CandidateView({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      token: Object.hasOwn(data, "token") ? data.token : this.token, //
      timestampCreated: Object.hasOwn(data, "timestampCreated") ? data.timestampCreated
        : this.timestampCreated, //
      candidateId: Object.hasOwn(data, "candidateId") ? data.candidateId : this.candidateId, //
      userId: Object.hasOwn(data, "userId") ? data.userId : this.userId, //
      contact: Object.hasOwn(data, "contact") ? data.contact : this.contact, //
      sharedByUserId: Object.hasOwn(data, "sharedByUserId") ? data.sharedByUserId : this.sharedByUserId, //
      timestampViewed: Object.hasOwn(data, "timestampViewed") ? data.timestampViewed : this.timestampViewed, //
      ip: Object.hasOwn(data, "ip") ? data.ip : this.ip, //
      userAgent: Object.hasOwn(data, "userAgent") ? data.userAgent : this.userAgent, //

      user: Object.hasOwn(data, "user") ? data.user : this.user, //
      sharedBy: Object.hasOwn(data, "sharedBy") ? data.sharedBy : this.sharedBy //
    } as CandidateViewModel);
  }

  get displayName(): string {
    return this.token;
  };
}
