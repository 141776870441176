import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { PropeldChipsInputModule } from "../../../lib/component/chips-input/module";
import { PropeldFormDisplayModule } from "../../../lib/component/form-display/module";
import { PropeldFormInputModule } from "../../../lib/component/form-input/module";
import { PropeldAutocompleteInputModule } from "../../../lib/component/form-select/module";
import { PropeldFormTextAreaInputModule } from "../../../lib/component/form-textarea-input/module";
import { PropeldSearchInputModule } from "../../../lib/component/mat-search-input/module";
import { PropeldComponentModule } from "../../../lib/component/module";
import { PropeldFileUploadModule } from "../../../lib/component/upload/module";
import { PropeldPermissionDirectiveModule } from "../../../lib/directive/account-permission/module";
import { PropeldMatTableSortModule } from "../../../lib/directive/mat-table-source/module";
import { PropeldResizeDirectiveModule } from "../../../lib/directive/resize-observable/module";
import { ScrollNearEndDirective } from "../../../lib/directive/scroll-near-end/directive";
import { SourceFilterModule } from "../../../lib/source-filter/module";
import { CardActionsModule } from "../card-actions/module";
import { AutoCompleteComponent } from "../filter/controls/autocomplete/component";
import { CandidateDetailComponent } from "./detail-component/component";
import { CandidateListComponent } from "./list-component/component";
import { PublicCandidateDetailComponent } from "./public-detail-component/component";
import { PublishCandidatePopupComponent } from "./publish-popup/component";

@NgModule({
	declarations: [
		CandidateListComponent,
    CandidateDetailComponent,
    PublishCandidatePopupComponent
	],

	exports: [
		CandidateListComponent,
    CandidateDetailComponent
	],

  // providers: [{
  //   provide: PublishCandidatePopupService,
  //   useClass: PublishCandidatePopupService
  // }],

  imports: [
    RouterModule,
    CommonModule,
    // BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,

    PropeldComponentModule,
    PropeldSearchInputModule,
    PropeldMatTableSortModule,
    PropeldFormDisplayModule,

    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    AutoCompleteComponent,
    SourceFilterModule,
    PropeldFormInputModule,
    PropeldChipsInputModule,
    PropeldAutocompleteInputModule,
    PropeldFormTextAreaInputModule,
    PropeldResizeDirectiveModule,
    PropeldPermissionDirectiveModule,
    CardActionsModule,
    ScrollNearEndDirective,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    PropeldFileUploadModule
  ]
})
export class CandidateComponentModule {
}
