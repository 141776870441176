import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { PropeldStoreModule } from "../../../../lib/store/module";
import { reducerFactory } from "../../../../lib/store/reducer/reducer";
import { initialJobTitleStore, JobTitle, JobTitleModel } from "./model";

export const JOBTITLE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("JobTitleReducer");

export function jobTitleReducerProvider() {
	return reducerFactory<JobTitleModel>("jobTitle", initialJobTitleStore, (data: JobTitleModel) => {
		return new JobTitle(data);
	});
}

@NgModule({
	imports: [
		StoreModule.forFeature("jobTitle", JOBTITLE_REDUCER_TOKEN),
		PropeldStoreModule.forFeature("jobTitle"),

	], providers: [
		{
			provide: JOBTITLE_REDUCER_TOKEN,
			useFactory: jobTitleReducerProvider
		}
	]
})
export class JobTitleCoreModule {

}
