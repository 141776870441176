import { inject, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, distinctUntilChanged, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Action } from "../../../lib/core/event-bus/action";
import { EventBus } from "../../../lib/core/event-bus/event-bus";
import { Message } from "../../../lib/core/event-bus/message/message";
import {
  AccountPermission,
  APP_ACTIONS_TOKEN, AppActionsStore
} from "../../../lib/service/app-actions/app-actions";
import { Account, AccountData } from "../../core/model/account/model";

@Injectable({
  providedIn: "root"
})
export class AccountService implements AccountPermission {

  private store: Store<any> = inject(Store);
  appActions = inject(AppActionsStore);
  eventBus = inject(EventBus);
  router = inject(Router);

  get = signal<Account>(Account.placeholder());
  private account$ = new BehaviorSubject<Account>(this.get());

  get asObservable(): Observable<Account> {
    return this.account$.asObservable();
  }

  hasPermission(permission: string): boolean {
    return this.get().hasPermission(permission);
  }

  hasPermission$(permission: string): Observable<boolean> {
      return this.account$.pipe(distinctUntilChanged(),
        map(account => account?.hasPermission(permission) ?? false)
      );
  }

  hasAnyPermission$(permissions: string[]): Observable<boolean> {
      return this.account$.pipe(distinctUntilChanged(),
        map(account => account?.hasAnyPermission(permissions) ?? false)
      );
  }

  constructor() {
    this.appActions.setAccount(this);

    this.store.select(state => state["account"])
      .pipe(takeUntilDestroyed(), distinctUntilChanged())
      .subscribe({
        next: (state: { size: number; values: () => [Account]; }) => {
          if (state.size == 0) {
            this.get.set(Account.placeholder());
            this.account$.next(this.get());
          } else {
            const [account] = state.values();
            this.get.set(account);
            this.account$.next(this.get());
          }
        },
        error: (error: { message: string; }) => {
          // TODO
        }
      });

    this.account$.pipe(takeUntilDestroyed()).subscribe({
      next: (account) => {

      },
      error: (error: { message: string; }) => {
        // TODO
      }
    });
  }

  logout() {
    this.eventBus.requestAction("client/logout", {}).subscribe({
      next: (reply: Message<Action<{ items: AccountData[]}>>) => {

        const account = reply.body.data.items[0];
        this.store.dispatch(new Action("account/received", {
          items: [account]
        }));

        this.router.navigate(["/login"]).catch( error => {
          console.log(error.message);
        });
      },
      error: (error) => {
        console.log(error.message);
      }
    });
  }
}
