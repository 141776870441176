import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Action } from "../../../core/event-bus/action";
import { Message } from "../../../core/event-bus/message/message";
import { SnackbarService } from "./snackbar.service";

export function filterFailuresAsSnackbarMessage(snackbar: SnackbarService, prefix?: string): (source$: Observable<Message<Action<any>>>) => Observable<Message<Action<any>>> {
  return source$ =>
    source$.pipe(
      filter((message: Message<Action<any>>) => {
          if (message.body.type === "err") {
            const action = message.body;
            if(prefix == null) {
              snackbar.error(`${action.data.message}`);
            } else {
              snackbar.error(`${prefix}: ${action.data.message}`);
            }
            return false;
          }
          return true;
        }
      ));
}

export function fatalError(snackbar: SnackbarService, error: Error) {
  snackbar.fatal("Fatal error, please reload: "+error.message);
}


export function handleError(snackbar: SnackbarService, error: Error) {
  snackbar.fatal("Fatal error, please reload: "+error.message);
}
