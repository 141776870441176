@if (source.loadingStatus$() === SourceStates.LOADING) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
  <div class="progressbar-spacer"></div>
}

<mat-card>

  <ng-container
    sourceFilterRoot [filterId]="'candidateView-list-filter'" [source]="source"
  >
    @if (!appState.isMobile$()) {
      <div class="card-header">
        <mat-card-header class="card-title-block">
          <mat-card-title>
            Reacties
          </mat-card-title>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <div class="card-actions-top-block">
          <div>
            <mat-search-input id="search" [source]="source"></mat-search-input>
          </div>
        </div>

        <div class="list-actions">
          <button mat-icon-button (click)="onToggleFilters()">
            <mat-icon>
              @if (displayFilters()) {
                filter_list_off
              } @else {
                filter_list
              }
            </mat-icon>
          </button>
        </div>
      </div>
    }

    <mat-card-content>

      @if (displayFilters()) {
        <div class="list-filters">

        </div>
      }

      @if (!appState.isMobile$()) {
        <mat-table
          mat-table
          class="candidateView-list"
          [source]="source"
          matSort
          matSortActive="id"
          matSortDirection="asc"
          matSortDisableClear
          multiTemplateDataRows
          expandedRows
        >

          <ng-container matColumnDef="id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let model">
              <span class="align-middle">{{ model.id }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="source">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Bron</th>
            <td mat-cell *matCellDef="let model">
              <span class="align-middle">
                @if (model?.source == "S") {
                  Gedeelde link
                } @else if (model?.source == "R") {
                  Gebruiker
                }
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="candidate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Kandidaat</th>
            <td mat-cell *matCellDef="let model">
              <div (click)="onOpenCandidate(model?.candidateId)">
                <div>{{ model.candidate?.fullName }}</div>
                <button mat-icon-button>
                  <mat-icon>open_in_new</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="timestamp">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Tijdstip</th>
            <td mat-cell *matCellDef="let model">{{ model.timestamp | date : 'd.M.yy HH:mm' }}</td>
          </ng-container>

          <ng-container matColumnDef="sharedByUserId">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Link van</th>
            <td mat-cell *matCellDef="let model">{{ model.sharedBy?.displayName }}</td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
            <td mat-cell *matCellDef="let model">{{ model.name }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let model">{{ model.email }}</td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Telefoon</th>
            <td mat-cell *matCellDef="let model">{{ model.phone }}</td>
          </ng-container>

          <ng-container matColumnDef="discloseAdditionalDataButton">
            <th mat-header-cell *matHeaderCellDef>Vrijgegeven</th>
            <td mat-cell *matCellDef="let model">
              @if (model?.remark != null) {
                <button mat-icon-button> <!--(click)="onToggleExpandRow(model?.id)"-->
                  <span class="material-icons-outlined">
                    @if (model.additionalDataDisclosed) {
                      check
                    } @else {
                      block
                    }
                  </span>
                </button>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef>Opmerking</th>
            <td mat-cell *matCellDef="let model">
              @if (model?.remark != null) {
                <button mat-icon-button (click)="onToggleExpandRow(model?.id)">
                  <mat-icon>
                    @if (expandedRows.isExpanded(model.id)) {
                      expand_less
                    } @else {
                      expand_more
                    }
                    expand
                  </mat-icon>
                </button>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="contextMenu">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let model">
              <button mat-icon-button [matMenuTriggerFor]="contextMenu" [matMenuTriggerData]="{ element: model }">
                <mat-icon class="material-symbols-outlined">more_vert</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td
              mat-cell
              *matCellDef="let model"
              [attr.colspan]="columns.length"
              [@detailExpand]="expandedRows.isExpanded(model.id) ? 'expanded' : 'collapsed'"
            >
              {{ model?.remark }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: columns;"
            [ngClass]="{ 'placeholder': row.rev < 0 }"
            [class.example-expanded-row]="expandedRows.isExpanded(row.id)"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{ 'collapsed': !expandedRows.isExpanded(row.id) }"
            class="example-detail-row"
          ></tr>

        </mat-table>
      } @else {
        <mat-list>
          @for (model of (source.dataSourceAsArray$ | async); track model; let index = $index) {
            <mat-list-item>
              <span matListItemTitle>{{ model?.token }}</span>
            </mat-list-item>
          }
        </mat-list>
      }

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50]"
        [length]="0"
        [pageSize]="10"
        [dataSource]="source"
        showFirstLastButtons
      ></mat-paginator>
    </mat-card-content>

    <mat-card-actions>

      <div class="left"></div>

      <div class="right"></div>

    </mat-card-actions>
  </ng-container>
</mat-card>

<mat-menu id="projectFolder-menu" #contextMenu="matMenu" [overlapTrigger]="false" xPosition="before">
  <ng-template matMenuContent let-element="element">

    <button mat-menu-item class="keyboardnav" (click)="onDiscloseAdditionalData(element)" dis
      [disabled]="true">
          <!-- [disabled]="element.additionalDataDisclosed === true"-->
      <mat-icon>check_circle</mat-icon>
      <span>Vrijgeven</span>
    </button>

  </ng-template>
</mat-menu>

