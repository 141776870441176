import { Action } from "./action";

export class CrudAction<T> extends Action<T> {

	public readonly id: number|string;
	public readonly rev: number;

	constructor(type: string, id: number|string, rev: number, data: T) {
		super(type, data);
		this.id = id;
		this.rev = rev;
	}

	override toJson(): any {
		return {
			type: this.type,
			id: this.id,
			rev: this.rev,
			data: this.data
		};
	}

	override clone(): CrudAction<T> {
		return new CrudAction(this.type, this.id, this.rev, this.data);
	}

}
