import {
  BaseModel, DataModel, DataModelId, DataModelMap, ModelData
} from "../../../../lib/store/model/dataModel";

export type JobTitleStore = DataModelMap<JobTitleModel>;

export const initialJobTitleStore: JobTitleStore = new Map<DataModelId, JobTitleModel>();

export interface JobTitleData extends ModelData {
  name: string;
}

export interface JobTitleModel extends JobTitleData, DataModel {
}

export class JobTitle extends BaseModel implements JobTitleModel {

  readonly name: string;

  constructor(data: JobTitleData) {
    super(data.id, data.rev);
    this.name = data.name;
  }

  static placeholder(): JobTitle {
    return new JobTitle({
      id: 0,
      rev: -1,
      name: ""
    });
  }


  /**
   * Clones a jobTitle and applies given properties in the data parameter
   *
   * @param data
   */
  clone(data?: any): JobTitle {
    data = data ? data : {};

    return new JobTitle({
      id: this.id, //
      rev: Object.hasOwn(data, "rev") ? data.rev : this.rev, //
      name: Object.hasOwn(data, "name") ? data.name : this.name, //
    } as JobTitleModel);
  }

  get displayName(): string {
    return this.name ? this.name : "";
  };
}
