import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, InjectionToken, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { environment } from "../environments/environment";
import { PropeldChipsInputModule } from "../lib/component/chips-input/module";
import { PropeldSearchInputModule } from "../lib/component/mat-search-input/module";
import { PropeldComponentModule } from "../lib/component/module";
import { ConfigService } from "../lib/core/config/service";
import {
  HTTP_EVENT_BUS_BRIDGE_ENDPOINT, HTTP_EVENT_BUS_BRIDGE_OPTIONS
} from "../lib/core/event-bus-bridge/http/http-event-bus-bridge";
import { SOCKET_EVENT_BUS_BRIDGE_OPTIONS } from "../lib/core/event-bus-bridge/socket/socket-event-bus-bridge";
import { ACTION_MESSAGE_CODEC_PROVIDER } from "../lib/core/event-bus/message-codec/action-codec";
import { PropeldLoggerModule } from "../lib/core/logger/module";
import { PropeldCoreModule } from "../lib/core/module";
import { SOCKET_OPTIONS, SocketOptions } from "../lib/core/socket/options";
import { PropeldMatTableSortModule } from "../lib/directive/mat-table-source/module";
import { Schema, SCHEMA_PROVIDER } from "../lib/schema/schema";
import { APP_ACTIONS_TOKEN, AppActionsStore } from "../lib/service/app-actions/app-actions";
import { PropeldAppStateModule } from "../lib/service/app-state/module";
import { PropeldSnackbarModule } from "../lib/service/snackbar/module";
import { SnackbarMessageService } from "../lib/service/snackbar/service/message.service";
import { SnackbarService } from "../lib/service/snackbar/service/snackbar.service";
import { SourceFilterState, SourceFilterStateFactory } from "../lib/source-filter/state-service";
import { SourceBuilder } from "../lib/source/source-builder";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AccountComponentModule } from "./component/account/module";
import { JobTitleComponentModule } from "./component/jobTitle/module";
import { LayoutModule } from "./component/layout/module";
import { LoginModule } from "./component/login/module";
import { AccountCoreModule } from "./core/model/account/module";
import { AuthRoleCoreModule } from "./core/model/authRole/module";
import { CandidateCoreModule } from "./core/model/candidate/module";
import { CandidateContactCoreModule } from "./core/model/candidateContact/module";
import { CandidateViewCoreModule } from "./core/model/candidateView/module";
import { CityCoreModule } from "./core/model/city/module";
import { CompanyCoreModule } from "./core/model/company/module";
import { EducationLevelCoreModule } from "./core/model/educationLevel/module";
import { FunctionAreaCoreModule } from "./core/model/functionArea/module";
import { IndustryCoreModule } from "./core/model/industry/module";
import { JobTitleCoreModule } from "./core/model/jobTitle/module";
import { PlaceholderCoreModule } from "./core/model/placeholder/module";
import { ProjectCoreModule } from "./core/model/project/module";
import { RegionCoreModule } from "./core/model/region/module";
import { UserCoreModule } from "./core/model/user/module";
import { schemaFactory } from "./core/schema/schema";
import { CompanyModule } from "./route/company/module";
import { CONFIRM_PASSWORD_RESET_PROVIDER } from "./route/confirm-password-reset/provider";
import { DashboardModule } from "./route/dashboard/module";
import { PublicCandidateModule } from "./route/public/module";
import { RegisterPagePageModule } from "./route/register/component";
import { UserModule } from "./route/user/module";
import { CONFIRM_EMAIL_PROVIDER } from "./route/verify-email/provider";
import { VIEW_SHARED_CANDIDATE_PROVIDER } from "./route/view-shared-candidate/provider";

export const VERSION = new InjectionToken<string>("Version");

export function initializeApplication(configService: ConfigService) {
  // printStartupMessage(version);

  return (): Promise<boolean> => {
    return configService.loadConfig().toPromise().then((config) => {
      return Promise.resolve(true);
    });
  };
}

export function initializeSocketOptions(configService: ConfigService): SocketOptions {
  return configService.get("socketOptions");
}

export function socketEventBusBridgeOptionsFactory(// schema: Schema,
  configService: ConfigService) {
  const crudMethods: string[] = [];
  // for (let entity of schema.getEntities()) {
  // 	const name = entity.getName();
  // 	crudMethods.push(`${name}/create`);
  // 	crudMethods.push(`${name}/update`);
  // 	crudMethods.push(`${name}/delete`);
  // 	crudMethods.push(`${name}/filter`);
  // 	crudMethods.push(`${name}/count`);
  // 	crudMethods.push(`${name}/get`);
  // }

  return {
    allowedInbound: configService.get([
      "socketEventBusBridgeOptions", "allowedInbound"
    ]),
    allowedOutbound: crudMethods.concat(configService.get([
      "socketEventBusBridgeOptions", "allowedOutbound"
    ]))
  };
}

export function httpEventBusBridgeOptionsFactory(configService: ConfigService) {
  return {
    allowedInbound: configService.get([
      "httpEventBusBridgeOptions", "allowedInbound"
    ]),
    allowedOutbound: configService.get([
      "httpEventBusBridgeOptions", "allowedOutbound"
    ])
  };
}

export function httpEventBusBridgeEndPointFactory(configService: ConfigService) {
  return configService.get("apiEndpoint");
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Material
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,

    // RxJs
    StoreModule.forRoot({}),

    // Library
    PropeldSearchInputModule,
    PropeldMatTableSortModule,
    PropeldSnackbarModule,
    PropeldChipsInputModule,

    PropeldAppStateModule,

    // Models
    AccountCoreModule,
    CityCoreModule,
    CompanyCoreModule,
    PlaceholderCoreModule,
    ProjectCoreModule,
    UserCoreModule,
    FunctionAreaCoreModule,
    EducationLevelCoreModule,
    CandidateCoreModule,
    CandidateViewCoreModule,
    JobTitleCoreModule,
    IndustryCoreModule,
    RegionCoreModule,
    CandidateContactCoreModule,
    RegisterPagePageModule,

    // Public
    PublicCandidateModule,

    // Application
    PropeldComponentModule,
    PropeldCoreModule,
    PropeldLoggerModule,

    AccountComponentModule,
    AuthRoleCoreModule,
    CompanyModule,
    DashboardModule,
    LayoutModule,
    LoginModule,
    UserModule,
    JobTitleComponentModule
  ],
  providers: [
    {
      provide: VERSION,
      useValue: environment.versionNumber
    }, {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      multi: true,
      deps: [
        ConfigService, VERSION
      ]
    }, {
      provide: SOCKET_OPTIONS,
      useFactory: initializeSocketOptions,
      deps: [ConfigService]
    }, {
      provide: SOCKET_EVENT_BUS_BRIDGE_OPTIONS,
      useFactory: socketEventBusBridgeOptionsFactory,
      deps: [
        // Schema,
        ConfigService
      ]
    }, {
      provide: HTTP_EVENT_BUS_BRIDGE_OPTIONS,
      useFactory: httpEventBusBridgeOptionsFactory,
      deps: [ConfigService]
    }, {
      provide: HTTP_EVENT_BUS_BRIDGE_ENDPOINT,
      useFactory: httpEventBusBridgeEndPointFactory,
      deps: [ConfigService]
    }, ACTION_MESSAGE_CODEC_PROVIDER, {
      provide: SCHEMA_PROVIDER,
      useFactory: schemaFactory
    }, {
      provide: SourceBuilder,
      deps: [Schema]
    }, {
      provide: SourceFilterState,
      useClass: SourceFilterStateFactory
    }, {
      provide: SnackbarService,
      useClass: SnackbarService
    }, {
      provide: SnackbarMessageService,
      useClass: SnackbarMessageService
    },
    // {
    //   provide: ACCOUNT_PERMISSION_TOKEN,
    //   useClass: AccountService
    // }
    // ,
    {
      provide: APP_ACTIONS_TOKEN,
      useValue: AppActionsStore
    },
    CONFIRM_PASSWORD_RESET_PROVIDER, VIEW_SHARED_CANDIDATE_PROVIDER, CONFIRM_EMAIL_PROVIDER

  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
