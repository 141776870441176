import { AfterViewInit, Directive, inject, Input, OnDestroy } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Sort, SortDirection, Source } from "../../source/source";

@Directive({
  selector: "mat-table[source]"
})
export class MatTableSourceDirective implements AfterViewInit, OnDestroy {

  destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  private table = inject(MatTable<any>, {host: true, self: true});
  private sort = inject(MatSort, {self: true, optional: true});

  @Input() sortState: Sort[] = [];
  @Input() source!: Source<any>;
  @Input() sortAliases: { [name: string]: Sort[] } = { };

  constructor() {

  }

  ngAfterViewInit(): void {
    this.table.dataSource = this.source;
    if(this.sort != null) {
      this.listenToSortChanges();
    }
  }

  listenToSortChanges() {
    if(this.sort != null) {
      this.sort.sortChange.pipe(
        takeUntil(this.destroyed)
      )
        .subscribe(sort => {
            let sortField = sort.active;
            if(this.sortAliases[sortField] != undefined) {
              sortField = this.sortAliases[sortField][0].field;
            }

            if (sort.direction === "") {
              this.sortState = [];
            } else {
              this.sortState = [
                {
                  field: sortField,
                  direction: sort.direction.toUpperCase() as any
                }
              ];
            }
            // this.logger.debug("listenToSortChanges() updating source", this.state);
            this.source.setSort(this.sortState);
          });
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
