import { AsyncPipe } from "@angular/common";
import { Component, DestroyRef, inject, Input, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { debounceTime, Observable, shareReplay, startWith } from "rxjs";
import { filter, map } from "rxjs/operators";
import { EqualOperator } from "../../../../../lib/source-filter/operator";
import {
  SourceFilterStateComponent, SourceFilterStateComponentProvider
} from "../../../../../lib/source-filter/source-filter-component";
import { SourceFilterComponentState } from "../../../../../lib/source-filter/source-filter-state";
import { Source } from "../../../../../lib/source/source";
import { DataModel } from "../../../../../lib/store/model/dataModel";

@Component({
  selector: "autocomplete",
  templateUrl: "component.html",
  standalone: true,
  styleUrl: "./component.scss",
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatIconModule,
    MatButtonModule
  ],
  providers: [SourceFilterStateComponentProvider(AutoCompleteComponent)]
})
export class AutoCompleteComponent implements SourceFilterStateComponent {

  control = new FormControl<DataModel|string|null>(null);
  model$ = signal<DataModel | null>(null);

  @Input() source: Source<any> | null = null;
  @Input("placeholder") placeholder: string = "";
  @Input({required: true}) filterField: string = "";
  @Input() className: string = "";
  @Input() displayOnly: boolean = false;
  @Input() displayFn: ((dataModel: DataModel|null) => string) = (dataModel: DataModel|null) => {
    return (dataModel as any)?.displayName ?? "";
  }

  @Input() set displayField(displayField: string) {
    const v = displayField;
      this.displayFn = (dataModel: DataModel|null) => {
        if(dataModel == null) return "";
        return (dataModel as any)[v];
      }
    }

  private destroyedRef = inject(DestroyRef);
  selectedIcon = signal<string|undefined>(undefined);
  selectedId = signal<string|undefined>(undefined);

  get filterComponentId(): string {
    return [this.source?.name ?? "autocomplete", this.filterField].join("/");
  }
  getSourceFilterComponentState(): SourceFilterComponentState {
    return {
      name: this.filterComponentId,
      value: this.control.value
    }
  }
  setSourceFilterComponentState(state: SourceFilterComponentState): void {
    this.control.setValue(state.value);

    const v = state.value as any;
    if(v?.icon != undefined) {
      this.selectedIcon.set(v.icon);
    } else {
      this.selectedIcon.set(undefined);
    }

    if(v?.class != undefined) {
      this.selectedId.set(v.id);
    } else {
      this.selectedId.set(undefined);
    }
  }

  constructor() {
    this.listenToKeyboard()
  }

  onClear() {
    this.control.setValue(null);
    this.model$.set(null);
    this.selectedIcon.set(undefined);
    this.selectedId.set(undefined);
  }

  private listenToKeyboard() {
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef), debounceTime(300)).subscribe({
      next: (value) => {
        if(value != null && typeof value === 'string' && value.trim() == '') {
          this.onClear();
          this.source?.setSearch(value as string);
          return;
        }
        this.source?.setSearch(value as string);
      },
      error: (error) => {
        console.error(error.message);
      }
    })
  }

  get filterChanges(): Observable<any> {
    return this.control.valueChanges.pipe(
      startWith(this.control.value),
      takeUntilDestroyed(this.destroyedRef),
      debounceTime(300),
      filter(value => {
        return typeof value !== "string";
      }),
      map(value => {
        if(value == null ) return null;

        const v = value as any;
        if(v?.icon != undefined) {
          this.selectedIcon.set(v.icon);
        } else {
          this.selectedIcon.set(undefined);
        }

        if(v?.class != undefined) {
          this.selectedId.set(v.id);
        } else {
          this.selectedId.set(undefined);
        }

        return new EqualOperator(this.filterField, (value as DataModel).id).build();
      }),
      shareReplay(1)
    );
  }

  displayFunction(dataModel: DataModel): string {
    return this.displayFn(dataModel);
  }
}
