import { Location } from "@angular/common";
import { Component, DestroyRef, effect, EventEmitter, inject, Input, Output, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { first, map } from "rxjs/operators";
import { CrudAction } from "../../../../lib/core/event-bus/crud-action";
import { EventBus } from "../../../../lib/core/event-bus/event-bus";
import { Message } from "../../../../lib/core/event-bus/message/message";
import { AppActionsStore, AppLocation } from "../../../../lib/service/app-actions/app-actions";
import { AppState } from "../../../../lib/service/app-state/app-state";
import { fatalError } from "../../../../lib/service/snackbar/service/error-filter";
import { SnackbarService } from "../../../../lib/service/snackbar/service/snackbar.service";
import { OperatorEncoder } from "../../../../lib/source-filter/operator/encoder";
import { SingleMainSourceImpl } from "../../../../lib/source/single-main-source-impl";
import { Source } from "../../../../lib/source/source";
import { SourceBuilder } from "../../../../lib/source/source-builder";
import { DataModel, DataModelId } from "../../../../lib/store/model/dataModel";
import { UPDATE_COMPANY } from "../../../app.actions";
import { Candidate } from "../../../core/model/candidate/model";
import { Company } from "../../../core/model/company/model";
import { CANDIDATE_UPDATE_PERMISSION, USER_UPDATE_PERMISSION } from "../../../core/model/permissions";
import { User, UserModel } from "../../../core/model/user/model";
import { AccountService } from "../../../service/account-service/account-service";

@Component({
  selector: "user-detail-card",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class UserDetailComponent {

  cardId = "user/detail-card";

  appState = inject(AppState);
  appActions = inject(AppActionsStore);
  accountService = inject(AccountService);
  private eventBus = inject(EventBus);
  private snackbar = inject(SnackbarService);
  displayMode = signal<string>("loading");
  private location = inject(Location);
  private destroyRef = inject(DestroyRef);

  model$ = signal<User>(User.placeholder());

  private sourceBuilder = inject(SourceBuilder);

  source: SingleMainSourceImpl<User> = this.sourceBuilder
    .builder("user")
    .setName("userSource")
    .link("company")
    .link("authRole")
    .setModelCreatorFn(this.newModel.bind(this))
    .buildForSingle(0);

  companySource: Source<Company> = this.sourceBuilder
    .builder("company")
    .setName("companySource")
    .build();

  @Input() model!: Candidate;
  @Output() modelChange = new EventEmitter<User>();

  private requiredFields$ = new BehaviorSubject("[]");
  private requiredModelFields$ = this.requiredFields$.pipe(
    takeUntilDestroyed(this.destroyRef),
    map((value: string) => OperatorEncoder.decode(value)),
    map((operators) => OperatorEncoder.asFields(operators))
  );

  form = new FormGroup({
    id: new FormControl(0),
    rev: new FormControl(0),
    email: new FormControl("", [Validators.required, Validators.email]), //
    // gender: new FormControl(null),
    firstName: new FormControl(null, []),
    infix: new FormControl(null, []),
    surname: new FormControl(null, []),//required()
    companyId: new FormControl(null, null),//required()
    // phone: new FormControl(null, []),
    // locale: new FormControl(null, []),
    authRoleId: new FormControl(10, []),//required()
    active: new FormControl({
      value: false,
      disabled: true
    }) // initials: new FormControl(null, []),
    // ppId: new FormControl(0),
    // lastLogin: new FormControl(null)
  });

  @Input() set modelId(id: DataModelId) {
    this.source.refresh({id: id});
  }

  @Input() set requiredFields(requiredFields: any) {
    this.requiredFields$.next(requiredFields);
  }

  constructor() {
    this.source.dataSource$.subscribe({
      next: (data: any) => {
        let user = data.items[0] as User;
        if(user.isNew && !user.active) {
          user = user.clone({active: true});
        }
        this.model$.set(user);
      }
    });

    this.initEffects();
    this.initActions();
  }

  initActions() {
    //@if (accountService.get().hasPermission(USER_UPDATE_PERMISSION)) {
    this.appActions.setActions(this.cardId, [
      {
        id: "user/edit",
        icon: () => this.displayMode() === "edit" ? "visibility" : "edit",
        permission: UPDATE_COMPANY,
        location: [AppLocation.CARD_ACTION, AppLocation.APPBAR]
      }, {
        id: "cancel",
        icon: () => "close",
        location: [AppLocation.CARD_ACTION, AppLocation.APPBAR]
      }
    ]);
  }

  initEffects() {
    effect(() => {
      const model = this.model$();
      this.patchForm(model);

      if (model.isNew) {
        this.displayMode.set("edit");
      } else {
        this.displayMode.set("display");
      }

      this.modelChange.emit(model);
    }, {allowSignalWrites: true});

    effect(() => {
      const actionId = this.appState.action$();
      if (actionId == null) return;

      switch (actionId as any as string) {
        case "user/edit": {
          this.onEdit();
          this.appState.setAction(null);
          break;
        }
        case "cancel": {
          this.onCancel();
          this.appState.setAction(null);
          break;
        }
        default: {
        }
      }
    }, {allowSignalWrites: true});
  }

  newModel(): DataModel {
    let fields = {};
    this.requiredModelFields$
      .pipe(first())
      .subscribe({
        next: (value) => fields = value
      });
    return Object.assign(User.placeholder(), fields);
  }

  onOk() {
    if (!this.form.dirty) {
      this.onCancel();
      return;
    }

    if(this.form.invalid) {
      // Find first invalid control
      for (let key in this.form.controls) {
        const control = this.form.get(key);
        if(!control?.valid) {
          this.snackbar.error(`Veld ${key} is ongeldig`);
          return;
        }
      }
      return;
    }

    let model: UserModel = this.model$();
    const changes = this.getDirtyValues(this.form);
    let type = "user/update";
    if (model!.rev <= 0) {
      type = "user/create";
    }
    const action = new CrudAction(type, model!.id, model!.rev, changes);
    this.eventBus.request(action.type, action).subscribe({
      next: (v) => {
        this.snackbar.info("Wijziging is opgeslagen");
        this.form.markAsPristine();
        this.close();
      },
      error: (error: Message<any>) => this.snackbar.handleErrorResponse(error)
    });
  }

  onEdit() {
    if (this.displayMode() === "display") {
      this.displayMode.set("edit");
    } else {
      if (this.form.dirty) {
        const changes = this.getDirtyValues(this.form);

        let model: User = this.model$();
        Object.keys(changes).forEach(key => {
          (model as any)[key] = changes[key];
        });
        this.model$.set(model);
      }
      this.displayMode.set("display");
    }
  }

  onCancel() {
    this.location.back();
  }

  close() {
    this.location.back();
  }

  getDirtyValues(form: any) {
    let dirtyValues = {} as any;
    Object.keys(form.controls)
      .forEach(key => {
        let currentControl = form.controls[key];
        if (currentControl.dirty) {
          if (currentControl.controls) {
            dirtyValues[key] = this.getDirtyValues(currentControl);
          } else {
            dirtyValues[key] = currentControl.value;
          }
        }
      });

    return dirtyValues;
  }

  private patchForm(model: UserModel) {
    for (let key of Object.keys(model)) {
      const control = this.form.get(key) as FormControl;
      const value = (model as any)[key];
      if (value == null) {
        control?.setValue("");
      } else {
        control?.setValue(value);
      }
    }
    this.form.updateValueAndValidity();
    this.form.markAsPristine();
  }

  applyRequiredFields(model: DataModel, data: any): DataModel {
    if (!model.isNew) return model;
    return Object.assign(model, data);
  }

  protected readonly User = User;
  protected readonly onclose = onclose;
  protected readonly CANDIDATE_EDIT_PERMISSION = CANDIDATE_UPDATE_PERMISSION;
  protected readonly USER_UPDATE_PERMISSION = USER_UPDATE_PERMISSION;
}
