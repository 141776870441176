import { Store } from "@ngrx/store";
import { combineLatestWith, Observable, shareReplay, tap } from "rxjs";
import { map } from "rxjs/operators";
import { CrudAction } from "../core/event-bus/crud-action";
import { EventBus } from "../core/event-bus/event-bus";
import { SchemaEntity } from "../schema/schema-entity";
import { SchemaRelation } from "../schema/schema-relation";
import { DataModel } from "../store/model/dataModel";
import { SourceSelector } from "./source-builder";

export class LinkedSelector implements SourceSelector {

  readonly selector: Observable<any>;

  readonly childEntity: string;
  readonly childField: string;
  readonly entityName: string;
  readonly entityField: string;
  readonly level: number;

  private _isLinked = false;

  readonly breadcrumb: string;

  constructor(
    readonly alias: string,
    readonly entity: SchemaEntity,
    readonly relation: SchemaRelation,

    store: Store<any>,
    eventBus: EventBus,
    readonly sourceSelector: SourceSelector
  ) {
    this.childEntity = this.relation.sourceField.entity.name;
    this.childField = this.relation.sourceField.name;
    this.entityName = this.entity.name;
    this.entityField = this.relation.targetField.name;

    if(!this.sourceSelector) throw new Error("SourceSelector is required");
    this.level = this.sourceSelector.level + 1;
    this.selector = this.buildSelector(store, eventBus, this.sourceSelector);

    this.breadcrumb = `${this.sourceSelector.breadcrumb}.${this.alias}`;
  }

  setLinked() {
    this._isLinked = true;
  }

  get isLinked(): boolean { return this._isLinked; }

  private buildSelector(store: Store<any>, eventBus: EventBus, sourceSelector: SourceSelector): Observable<any> {

    let clearCache = false;
    sourceSelector.setLinked();

    return sourceSelector.selector.pipe(
      combineLatestWith(
        store.select(state => (state as any)[this.entity.name])
          .pipe(
            tap(state => {
                console.log(`>> STATE ${this.entity.name} CHANGED`, state);
                clearCache = true;
              }
            )
          )
      ),
      map(([main, state]) => {
        const entityName = this.entityName;
        const childField = this.childField;
        if(!entityName || !childField) throw new Error();

        console.log(`>> START LinkedSelector ${this.entity.name}`);

        let models = main.related.get(entityName);
        if(!models) {
          models = new Map<string, DataModel>();
          main.related.set(entityName, models);
        }
        if(clearCache) {
          models.clear();
          clearCache = false;
        }

        let items;
        if(this.level == 1) {
          items = main.items;
        } else {
          items = main.related.get(this.childEntity).values();
        }

        const idsMissing = [];
        for(let item of items as DataModel[]) {
          // items.reduce((acc: any, item: any) => {
          const record = item as any;
          if (record.rev == -1) {
            // Skip the placeholder
          } else {
            const id = (item as any)[childField];
            if (id != null) {
              // Get the cloned model from the cache
              let model = models.get(id);
              if (!model) {
                // Cloned model not in cache, get it from the state
                const storeModel = state.get(id);
                if (storeModel == null) {
                  // It is not in the state, mark the id to be retrieved
                  if (idsMissing.indexOf(id) === -1) {
                    idsMissing.push(id);
                  }
                } else {
                  // Model from the state, save a cloned copy in the cache
                  model = storeModel.clone();
                  if (!model) throw new Error("Model can not be null or undefined");
                  models.set(id, model);
                }
              }
              if (model) {
                (item as any)[this.alias] = model;
              }
            }
          }
        }

        const index = main.progress.indexOf(this.breadcrumb);
        if(index > -1) {
          main.progress.splice(index, 1);
        }

        console.log(`>> ${idsMissing.length} MISSING ${this.entity.name} IDS `, idsMissing);
        if (idsMissing.length != 0) {
          console.log(`>> GET MISSING ${this.entity.name} IDS `, idsMissing);

          // Request missing models from the server. The /retrieved reply will trigger the store
          // and the code above is run again
          const action = new CrudAction(`${this.entity.name}/get`, 0, 0, {
            ids: idsMissing
          });
          eventBus.request(`${this.entity.name}/get`, action).subscribe({
            next: (message) => {
              // inFlight = false;
              console.log(`>> RECEIVED ${this.entity.name} IDS, DISPATCH `, message.body);
              store.dispatch(message.body);
            },
            error: (error) => {
              // inFlight = false;
              console.log(error.message);
            }
          });
        } else {
          // if(main.progress.indexOf(this.breadcrumb) === -1) {
            main.progress.push(this.breadcrumb);
          // }
        }

        return main;
      }),
      shareReplay(1)
    );
  }
}
