import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { switchMap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Action } from "../../core/event-bus/action";
import { CrudAction } from "../../core/event-bus/crud-action";
import { EventBus } from "../../core/event-bus/event-bus";
import { MessageConsumer } from "../../core/event-bus/message-consumer";
import { Message } from "../../core/event-bus/message/message";
import { LoggerLocator } from "../../core/logger/locator";
import { DataModel, DataModelId, DataModelRevision } from "../model/dataModel";

type DataModelIdRev = { id: DataModelId, rev: DataModelRevision }
type RequestedIds = { entity: string, ids: DataModelIdRev[] };

@Injectable({
	providedIn: "root"
})
export class StoreSyncHandler {
	address = "store/sync";
	private logger = LoggerLocator.getLogger("StoreSyncHandler")();

	private consumer: MessageConsumer<Action<any>> | null = null;

	private readonly eventBus: EventBus = inject(EventBus);
	private readonly store: Store<unknown> = inject(Store);

	constructor() {
		this.addSyncHandler();
	}

	private addSyncHandler() {
		this.consumer = this.eventBus.consumer("store/sync");

		this.consumer.observable
			.pipe(
				map((message: Message<Action<any>>) => {
					return {
						message: message,
						ids: this.splitRequestedIdsPerEntity(message.body)
					};
				})
			)
			.subscribe({
				next: (value) => {
					const message = value.message;
					const list : Promise<Action<any>>[] = [];
					value.ids.forEach((item) => {
						list.push(this.syncIds(item));
					});

					Promise.all<Action<any>>(list)
						.then(values => {
							message.reply(new CrudAction("store/synced", 0, 0, {}));
						})
						.catch(error => {
							message.fail(0, error.message);
						});
				}, error: (error) => {
					throw new Error(error.message);
				}
			});
	}

	private syncIds(requestedIds: RequestedIds): Promise<Action<any>> {
		let inFlight = false;
		return new Promise( (resolve, reject) => {
			// const selector: Observable<Map<DataModelId, DataModel>> = //
			this.store.select(state => (state as any)[requestedIds.entity])
				.pipe( //
					filter( state => !inFlight ),
					// Filter all cached models that are up-to-date by checking them in the store
					// and return models to be retrieved
					map((state: Map<DataModelId, DataModel>) => {
						inFlight = true;
						return requestedIds.ids
							.filter((dataModel: DataModelIdRev) => {
								const model = state.get(dataModel.id);
								return model == null || model.rev < dataModel.rev;
							})
							.map(dataModel => dataModel.id);
					}),
					// Only request ids when there are models needed
					filter((list: DataModelId[]) => {
						if(list.length === 0) {
							// No ids to retrieve. Resolve the promise but don't continue the pipeline
							resolve(
								new CrudAction(`${requestedIds.entity}/received`, 0, 0, {
									items: []
								}));
							return false;
						}
						return true;
					}),
					// Create the action to retrieve missing records from the server
					map((list: DataModelId[]) => new CrudAction(
						`${requestedIds.entity}/get`,
						0,
						0,
						{ids: list}
					)),
					// Send the get action
					switchMap((action: Action<any>) => this.eventBus.request(action.type, action).asObservable)
				)
				.subscribe({
					next: (message: Message<Action<any>>) => {
						// Send the received result to the store
						console.log("STORE/FILTERED DISPATCH", message.body);
						this.store.dispatch(message.body);
						resolve(message.body);
					}, error: (error) => {
						reject(error);
					}
				});
		});
	}

	private splitRequestedIdsPerEntity(action: Action<any>): RequestedIds[] {
		const entity = action.type.split("/")[0];
		return Object.keys(action.data).reduce((acc: any[], key: string) => {
			acc.push({
				entity: key === "items" ? entity : key, ids: (action.data as any)[key]
			});
			return acc;
		}, []);
	}

	public removeHandler() {
		if (this.consumer) {
			this.consumer.unregister();
			this.consumer = null;
		}
	}

	public removeHandlers() {
		this.removeHandler();
	}
}
